import React, {Component} from 'react';
import {API_ROOT, DOMAIN} from "../api-config";
import {withRouter} from 'react-router-dom'
import ReactPlaceholder from "react-placeholder";
import {RectShape} from "react-placeholder/lib/placeholders";
import Modal from "react-responsive-modal";

import Swal from "sweetalert2";
import $ from "jquery";
import axios from "axios";
import payform from 'payform';


class remainingpayment extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            ready: true,
            open: false,
            orderId: this.props.orderId,
            eventID: '',
            swalButton: "",
            total: "",
            paymentType: "",
            details: '',
            paymentOpen: false,
            link: "",
            userFullPayment: "N",
            cardDate: '',
            show: false,
            paymentSubtotal: 0,
            paymentNCA: 0,
            paymentTax: 0,
            paymentTotal: 0,
            paymentTotalTax: 0,
            redirect: false,
            notfound: false,
            gatewayType: "W",
            orderID: '',
            depositPrice: 0,
            productPrice: 0,
            notes: []


        };


    };

    componentWillMount() {
        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-green',
                    cancelButton: 'btn-green'
                },
                buttonsStyling: false,
            })
        });

    }

    componentWillUnmount() {
        // Remove the event listener when the component unmounts
        window.removeEventListener('message', this.handlePaymentResponse);
    }

    componentDidMount() {

        var gatewayType = localStorage.getItem("merchant_gateway_type");
        window.addEventListener('message', this.handlePaymentResponse);
        this.setState({paymentType: "B"});
        this.setState({userFullPayment: "Y"});

        this.fetchEventDetails();

        this.setState({gatewayType: gatewayType});
        var cardField = document.getElementById('paymentCardNumber');
        payform.cardNumberInput(cardField);


    }

    handlePaymentResponse = (event) => {
        // You should receive a message from the iframe indicating payment status
        const {data} = event;

        console.log("PAYMENT", data);
        if (data === 'payment_successful') {

            this.props.handleClosePayment("R");
            //  localStorage.setItem("waivers", "[]");
            //  this.setState({redirect: true});

        } else if (data === 'payment_declined') {

            this.state.swalButton.fire({
                title: "Payment Declined",
                allowOutsideClick: false
            });

            //  this.setState({paymentOpen: false});
        }
    };

    onChangePaymentType = (type) => {
        this.setState({paymentType: type});

    }

    handleExpiry = e => {


        var dat = this.normalizeInput($(e.target).val(), this.state.cardDate);
        console.log("T " + dat);
        this.setState({cardDate: dat});
    };

    normalizeInput = (value, previousValue) => {
        if (!value) return value;
        var currentValue = value.replace(/[^\d]/g, '');
        var cvLength = currentValue.length;


        if (!previousValue || value.length > previousValue.length) {


            console.log("CURRENNT " + cvLength);

            if (cvLength < 2) return currentValue;

            console.log(currentValue.slice(0, 2) + " " + currentValue.slice(2));

            if (cvLength < 4) return (currentValue.slice(0, 2) + "/" + currentValue.slice(2));
            return currentValue.slice(0, 2) + "/" + currentValue.slice(2);
        }
    };


    fetchEventDetails = () => {
        var url = API_ROOT + '/fetchorderdetails?order_id=' + this.state.orderId + "&merchant_id=" + localStorage.getItem("merchant_id");

        this.onOpenModal("Loading");
        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(events => {


                if (events.status == 1) {


                    var order = events.order[0];


                    var notes = [];
                    for (var i = 0; i < events.order.length; i++) {

                        if (events.order[i].payment_processed == "N") {

                            if (events.order[i].payment_note != "") {
                                notes.push(events.order[i].payment_note);
                            }
                        }

                    }



                    this.setState({notes: notes});


                    if (order.order_remaining_amount - order.total > 0) {


                        var orders = [];


                        for (var i = 0; i < events.order.length; i++) {


                            if (events.order[i].payment_total < 0 && events.order[i].payment_processed == "N") {
                                orders.push(events.order[i]);
                            }
                        }


                        var merchant = events.merchant[0];

                        var products = order.order_details;

                        this.setState({eventID: order.event_id});

                        var productPrice = 0;

                        if (order.order_addon_deposit_enabled == "N") {
                            for (var i = 0; i < products.length; i++) {

                                if (parseInt(products[i].product_quantity) > 0) {

                                    productPrice += parseInt(products[i].product_quantity) * parseFloat(products[i].product_price);
                                }
                            }
                        }
                        this.setState({productPrice: productPrice})

                        console.log("Product Price" + productPrice);

                        var extraGuest = 0;
                        var additionalGuestPrice = 0;


                        var subTotal = 0;


                        var basePrice = parseFloat(order.order_event_amount - (order.payment_amount - order.payment_product_amount));


                        subTotal = basePrice;

                        var productPriceForTax = 0;

                        if (order.order_addon_deposit_enabled == "N") {
                            for (var i = 0; i < products.length; i++) {

                                if (parseInt(products[i].product_quantity) > 0) {

                                    if (products[i].product_tax_enabled === "Y") {

                                        productPriceForTax += parseInt(products[i].product_quantity) * parseFloat(products[i].product_price);

                                        for (var j = 0; j < products[i].taxes.length; j++) {

                                            products[i].taxes[j].tax_amt = ((parseInt(products[i].product_quantity) * parseFloat(products[i].product_price)) * products[i].taxes[j].tax_value) / 100;
                                        }
                                    }
                                }
                            }
                        }

                        var subTotalForTax = subTotal;//+ productPriceForTax;
                        var taxes = order.event_taxes;

                        var tax = 0


                        for (var i = 0; i < orders.length; i++) {
                            if (orders[i].payment_tax_enabled == "Y" && orders[i].payment_processed == "N") {
                                subTotalForTax += Math.abs(orders[i].payment_amount);
                            }
                        }


                        for (i = 0; i < taxes.length; i++) {

                            taxes[i].tax_amt = (subTotalForTax * parseFloat(taxes[i].tax_value)) / 100;

                            tax += (subTotalForTax * parseFloat(taxes[i].tax_value)) / 100;
                            console.log("A " + ((subTotalForTax * parseFloat(taxes[i].tax_value)) / 100));

                        }

                        if (orders.order_addon_deposit_enabled == "N") {
                            for (var i = 0; i < products.length; i++) {

                                if (parseInt(products[i].product_quantity) > 0) {

                                    if (products[i].product_tax_enabled === "Y") {


                                        for (var j = 0; j < products[i].taxes.length; j++) {


                                            var flag = false;

                                            for (var k = 0; k < taxes.length; k++) {

                                                if (taxes[k].tax_id == products[i].taxes[j].tax_id) {

                                                    taxes[k].tax_amt = parseFloat(taxes[k].tax_amt) + parseFloat(((parseFloat(products[i].product_price) * parseInt(products[i].product_quantity) * parseFloat(products[i].taxes[j].tax_value)) / 100).toFixed(2));
                                                    flag = true;
                                                    break;

                                                }
                                            }
                                            if (!flag) {


                                                taxes.push({
                                                    tax_id: products[i].taxes[j].tax_id,
                                                    tax_name: products[i].taxes[j].tax_name,
                                                    tax_value: products[i].taxes[j].tax_value,
                                                    tax_amt: ((parseFloat(products[i].product_price) * parseInt(products[i].product_quantity) * parseFloat(products[i].taxes[j].tax_value)) / 100).toFixed(2)
                                                });
                                            }
                                        }

                                    }
                                }
                            }
                        }


                        tax = 0;
                        for (i = 0; i < taxes.length; i++) {


                            tax += parseFloat(taxes[i].tax_amt);


                        }


                        this.setState({productPrice: productPrice});
                        subTotal = subTotal + productPrice;


                        var nca = 0;


                        for (var i = 0; i < orders.length; i++) {
                            if (orders[i].payment_fee_enabled == "Y" && orders[i].payment_processed == "N") {
                                subTotal += Math.abs(orders[i].payment_amount);
                            }
                        }


                        if (order.merchant_nca_enabled === 'Y') {


                            if (order.merchant_nca_amount_enabled === 'Y') {

                                //grandTotal += parseFloat(success.data.merchant_data[0].merchant_nca);

                                nca = parseFloat(order.merchant_nca);


                            } else {


                                nca = ((subTotal + tax) * parseFloat(order.merchant_nca)) / 100;


                            }

                        }


                        for (var i = 0; i < orders.length; i++) {
                            if (orders[i].payment_tax_enabled == "N" && orders[i].payment_fee_enabled == "N" && orders[i].payment_processed == "N") {
                                subTotal += Math.abs(orders[i].payment_amount);
                            }
                        }


                    } else {


                        var orders = [];
                        for (var i = 0; i < events.order.length; i++) {
                            if (events.order[i].payment_total < 0 && events.order[i].payment_processed == "N") {
                                orders.push(events.order[i]);
                            }
                        }


                        var merchant = events.merchant[0];

                        var products = [];

                        this.setState({eventID: order.event_id});

                        var productPrice = 0;


                        this.setState({productPrice: productPrice})


                        var extraGuest = 0;
                        var additionalGuestPrice = 0;


                        var subTotal = 0;


                        var basePrice = 0;


                        subTotal = basePrice;

                        var productPriceForTax = 0;


                        var subTotalForTax = 0;//+ productPriceForTax;

                        for (var i = 0; i < orders.length; i++) {
                            if (orders[i].payment_tax_enabled == "Y" && orders[i].payment_processed == "N") {
                                subTotalForTax += Math.abs(orders[i].payment_amount);
                            }
                        }


                        var taxes = order.event_taxes;
                        var tax = 0

                        for (i = 0; i < taxes.length; i++) {

                            taxes[i].tax_amt = (subTotalForTax * parseFloat(taxes[i].tax_value)) / 100;

                            tax += (subTotalForTax * parseFloat(taxes[i].tax_value)) / 100;
                            console.log("A " + ((subTotalForTax * parseFloat(taxes[i].tax_value)) / 100));

                        }


                        tax = 0;
                        for (i = 0; i < taxes.length; i++) {


                            tax += parseFloat(taxes[i].tax_amt);


                        }


                        this.setState({productPrice: productPrice});
                        subTotal = subTotal + productPrice;


                        var nca = 0;


                        for (var i = 0; i < orders.length; i++) {
                            if (orders[i].payment_fee_enabled == "Y" && orders[i].payment_processed == "N") {
                                subTotal += Math.abs(orders[i].payment_amount);
                            }
                        }


                        if (order.merchant_nca_enabled === 'Y') {


                            if (order.merchant_nca_amount_enabled === 'Y') {

                                //grandTotal += parseFloat(success.data.merchant_data[0].merchant_nca);

                                nca = parseFloat(order.merchant_nca);


                            } else {


                                nca = ((subTotal + tax) * parseFloat(order.merchant_nca)) / 100;


                            }

                        }


                        for (var i = 0; i < orders.length; i++) {
                            if (orders[i].payment_tax_enabled == "N" && orders[i].payment_fee_enabled == "N" && orders[i].payment_processed == "N") {
                                subTotal += Math.abs(orders[i].payment_amount);
                            }
                        }


                    }


                    this.setState({paymentSubtotal: subTotal});
                    this.setState({paymentTax: taxes});
                    this.setState({paymentTotalTax: tax});
                    this.setState({paymentNCA: nca});
                    this.setState({paymentTotal: (subTotal + tax + nca)})


                    this.onCloseModal();


                } else {
                    this.setState({notfound: true});
                }

            });

    };

    payment = e => {


        var paymentCardNumber = "";
        var paymentCardName = "";
        var paymentExpiry = "";
        var paymentCVV = "";
        var paymentAddress = "";
        var paymentCity = "";
        var paymentState = "";
        var paymentZip = "";


        var n = -1;
        if (this.state.gatewayType == "W") {

            paymentCardNumber = $('#paymentCardNumber').val();
            paymentCardName = $('#paymentCardName').val();
            paymentExpiry = $('#paymentExpiry').val();
            paymentCVV = $('#paymentCVV').val();
            paymentAddress = $('#paymentAddress').val();

            paymentCity = $('#paymentCity').val();
            paymentState = $('#paymentState').val();
            paymentZip = $('#paymentZip').val();

            n = paymentExpiry.search(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/i);
        }

        var flag = true;


        if (this.state.paymentType === "") {
            this.state.swalButton.fire({
                title: 'Please select payment type.',
                allowOutsideClick: false
            });

            flag = false;
            return;
        } else if (this.state.gatewayType == "W" && paymentCardNumber.trim().length <= 0) {

            this.state.swalButton.fire({
                title: 'Please enter card number.',
                allowOutsideClick: false
            });
            flag = false;
            return;
        } else if (this.state.gatewayType == "W" && paymentExpiry.trim().length <= 0) {
            this.state.swalButton.fire({
                title: 'Please enter expiry.',
                allowOutsideClick: false
            });
            flag = false;
            return;
        } else if (this.state.gatewayType == "W" && n < 0) {
            this.state.swalButton.fire({
                title: 'Please enter expiry in MM/DD format.',
                allowOutsideClick: false
            });
            flag = false;
            return;

        } else if (this.state.gatewayType == "W" && paymentCVV.trim().length <= 0) {
            this.state.swalButton.fire({
                title: 'Please enter CVV.',
                allowOutsideClick: false
            });
            flag = false;
            return;
        } else if (this.state.gatewayType == "W" && paymentCardName.trim().length <= 0) {
            this.state.swalButton.fire({
                title: 'Please enter card name.',
                allowOutsideClick: false
            });
            flag = false;
            return;
        } else if (this.state.gatewayType == "W" && paymentAddress.trim().length <= 0) {
            this.state.swalButton.fire({
                title: 'Please enter address.',
                allowOutsideClick: false
            });
            flag = false;
            return;
        } else if (this.state.gatewayType == "W" && paymentCity.trim().length <= 0) {
            this.state.swalButton.fire({
                title: 'Please enter city.',
                allowOutsideClick: false
            });
            flag = false;
            return;
        } else if (this.state.gatewayType == "W" && paymentState.trim().length <= 0) {
            this.state.swalButton.fire({
                title: 'Please enter state.',
                allowOutsideClick: false
            });
            flag = false;
            return;
        } else if (this.state.gatewayType == "W" && paymentZip.trim().length <= 0) {
            this.state.swalButton.fire({
                title: 'Please enter zipcode.',
                allowOutsideClick: false
            });
            flag = false;
            return;
        }


        if (flag) {
            this.onOpenModal("Processing Payment");


            var cardDate = ["", ""];
            var cardType = "";
            if (this.state.gatewayType == "W") {
                cardDate = paymentExpiry.split('/');
                cardType = payform.parseCardType(paymentCardNumber.toUpperCase());
            }


            axios.post(API_ROOT + '/payremaining', {
                order_id: this.state.orderId,
                event_id: this.state.eventID,
                user_id: localStorage.getItem('user_id'),
                fullPayment: "Y",
                eventPaymentType: "B",
                payment_product_amount: this.state.productPrice,
                payment_notes: this.state.notes.join(","),
                order_pay_balance_new: 'Y',
                amount: this.state.paymentSubtotal.toFixed(2),
                nca: this.state.paymentNCA.toFixed(2),
                tax: this.state.paymentTax,
                totalTaxList: [],
                total: this.state.paymentTotal.toFixed(2),
                totalTax: this.state.paymentTotalTax.toFixed(2),
                payment_type: 'C',
                merchant_id: localStorage.getItem("merchant_id"),
                cardNo: paymentCardNumber,
                cardName: paymentCardName,
                cardMonth: cardDate[0],
                cardYear: cardDate[1],
                cardCvc: paymentCVV,
                cardStreet: paymentAddress,
                cardCity: paymentCity,
                cardState: paymentState,
                cardZip: paymentZip,
                cardType: cardType,
                routingNo: '',
                accountNo: '',
                paymentType: '',
                paymentFull: 'Y',
                bankName: '',
                remaining: 'Y'


            })
                .then(response => {

                    this.onCloseModal();

                    if (response.data.status == 1) {

                        if (this.state.gatewayType == "I") {
                            /**/
                            var specificURL = response.data.link;

                            // specificURL='http://localhost:16000/orders/success?responseCode=200&responseMessage=Success&transactionType=1&transactionReferenceId=84534059860236403745&amount=530&customFee=0&transactionNumber=000004&batchNumber=185&cardType=VISA&cardLast4Digit=5655&tips=0&localTax=&stateTax=&responseApprovalCode=TAS239&RRN=334712500685&totalAmount=530&transactionId=35141908467872507920231213173032';

                            this.setState({link: specificURL});
                            this.setState({paymentOpen: true});
                        } else {


                            console.log(response.data);
                            //  sessionStorage.setItem("userFullPayment", this.state.userFullPayment);

                            this.props.handleClosePayment("R");
                        }


                    } else {
                        this.state.swalButton.fire({
                            title: response.data.message,
                            allowOutsideClick: false
                        });

                    }
                }).catch(error => {
                this.onCloseModal();
                console.log(error);
            });

        }

    }


    onOpenModal = (details) => {

        this.setState({details: details});
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({open: false});
    };


    render() {


        const {open} = this.state;


        const rightPlaceholder = (
            <div className='my-awesome-placeholder'>
                <RectShape color='whitesmoke' className="img-fluid" style={{width: "100%", height: "642px"}}/>

            </div>
        );

        return (<ReactPlaceholder ready={this.state.ready}
                                  customPlaceholder={rightPlaceholder}>

            <div className="title_div mb-30">
                <h1>Payment Information</h1>
            </div>
            {!this.state.paymentOpen ?
                <div className="addons_block_main">
                    <div className="guest_block  clearfix w-100" id="style-1">

                        <p className="roboto-regular text-center font-16">Pay the Remaining Balance to Complete your
                            Booking</p>
                        <form>


                            <div className="radiobtn radiobtn_confirm">
                                <input type="radio" checked={true} id="amount"
                                       name="drone" value="amount"/>
                                <label htmlFor="amount">Pay Remaining Balance <span
                                    className="amount">${(this.state.paymentTotal).toFixed(2)}</span></label>
                            </div>


                            {this.state.gatewayType == "W" ? <div>
                                <div className="blue-title bg-blue border" style={{marginTop: "20px"}}>
                                    Credit / Debit Card
                                </div>

                                <div className="form-group col-md-6 p-0" style={{display: "inline-block"}}>
                                    <div className="col-md-12">
                                        <label>Card Number</label>
                                        <input type="text" maxLength="19" id="paymentCardNumber"
                                               className="form-control"
                                               placeholder="- - - -   - - - -   - - - -   - - - -"/>
                                    </div>
                                </div>
                                <div className="form-group col-md-3 p-0" style={{display: "inline-block"}}>
                                    <div className="col-md-12">
                                        <label>Exp. Date</label>
                                        <input type="text" maxLength="5" id="paymentExpiry" onChange={this.handleExpiry}
                                               value={this.state.cardDate || ''} className="form-control text-center"
                                               placeholder="MM/DD"/>
                                    </div>
                                </div>
                                <div className="form-group col-md-3 p-0" style={{display: "inline-block"}}>
                                    <div className="col-md-12">
                                        <label>CVV</label>
                                        <input type="number" id="paymentCVV" className="form-control text-center"
                                               max="4"
                                               name="" placeholder="- - -"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label>Name on Card</label>
                                        <input type="text" placeholder="Type Name on Card" className="form-control"
                                               id="paymentCardName"/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <label>Billing Street Address</label>
                                        <input type="text" className="form-control font-robotolightitalic"
                                               id="paymentAddress"
                                               placeholder="Type Street Address"/>
                                    </div>
                                </div>
                                <div className="form-group col-md-4 p-0" style={{display: "inline-block"}}>
                                    <div className="col-md-12">
                                        <label>City</label>
                                        <input type="text" className="form-control font-robotolightitalic"
                                               placeholder="Type City" id="paymentCity"/>
                                    </div>
                                </div>
                                <div className="form-group col-md-4 p-0 " style={{display: "inline-block"}}>
                                    <div className="col-md-12">
                                        <label>State</label>
                                        <input type="text" className="form-control font-robotolightitalic"
                                               placeholder="Type State" id="paymentState"/>
                                    </div>
                                </div>
                                <div className="form-group col-md-4 p-0" style={{display: "inline-block"}}>
                                    <div className="col-md-12 ">
                                        <label>Zip Code</label>
                                        <input type="text" className="form-control font-robotolightitalic"
                                               placeholder="Type Zipcode" id="paymentZip"/>
                                    </div>
                                </div>
                            </div> : ""}

                        </form>


                    </div>
                </div> : <div className="addons_block_main">
                    <div className="guest_block time_block clearfix w-80" id="style-1" style={{overflow: "visible"}}>

                        <iframe
                            src={this.state.link}
                            title="Payment Gateway"
                            width="650px"
                            height="600px"
                        ></iframe>
                    </div>
                </div>}

            {!this.state.paymentOpen ? <div className="clearfix">

                <a onClick={this.payment}
                   className="btn-green w-100 mt-1 font-nunito-semibold float-right">Pay {this.state.paymentTotal > 0 ? "$" + this.state.paymentTotal.toFixed(2) : ''} </a>
            </div> : ""}


            <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                   onClose={this.onCloseModal} center>
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;{this.state.details}...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                <p style={{textAlign: 'center'}}>
                    <img width={'40'} height={'40'} alt="Loading..." src={'/img/loading1.gif'}/>
                </p>
            </Modal>
        </ReactPlaceholder>)

    }
}

export default withRouter(remainingpayment);
