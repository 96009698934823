import React, {Component} from 'react';
import {API_ROOT, DOMAIN} from "../api-config";
import {Redirect, withRouter} from "react-router-dom";
import $ from "jquery";
import axios from 'axios';
import Topbar from './topbar';
import Footer from './footer';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import moment from "moment-timezone";
import Swal from 'sweetalert2';

class signup extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            redirect: false,
            redirect404: false,
            load: false,
            open: false,
            phone: "",
            swalButton: ''
        };

    };

    componentWillMount() {
        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-green',
                    cancelButton: 'btn-green'
                },
                buttonsStyling: false,
            })
        });
        if(localStorage.getItem('user_id')){
            this.setState({redirect:true});
        }

    }


    componentDidMount() {
        sessionStorage.clear();
        if (!localStorage.getItem('merchant_id')) {
            this.fetchMerchant();
        } else {
            this.setState({load: true});
            document.title = "Login - " + localStorage.getItem("merchant_name");
            setTimeout(function () {

                $(".custom-select").each(function () {
                    $(this).wrap("<span class='select-wrapper'></span>");
                    $(this).after("<span class='holder'></span>");
                });
                $(".custom-select").change(function () {
                    var selectedOption = $(this).find(":selected").text();
                    $(this).next(".holder").text(selectedOption);
                }).trigger('change');


            }, 100);
        }


    }

    handleChange = e => {

        this.setState({phone: this.normalizeInput($(e.target).val(), this.state.phone)});
    };

    onOpenModal = () => {
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({open: false});
    };


    signup = e => {


        var user_firstname = $('#user_firstname').val();
        var user_type = $('#user_type').val();
        var user_lastname = $('#user_lastname').val();
        var user_email = $('#user_email').val();
        var user_confirm_email = $('#user_confirm_email').val();
        var user_phone = $('#user_phone').val();
        var user_day = $('#user_day').val();
        var user_month = $('#user_month').val();
        var user_year = $('#user_year').val();
        var user_password = $('#user_password').val();
        var user_confirm_password = $('#user_confirm_password').val();
        var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;

        if (user_type === "") {
            this.state.swalButton.fire({
                title: 'Please select customer type.',
                allowOutsideClick: false
            });

            return;
        } else if (user_firstname === "") {
            this.state.swalButton.fire({
                title: 'Please enter first name.',
                allowOutsideClick: false
            });

            return;
        } else if (user_email === "") {
            this.state.swalButton.fire({
                title: 'Please enter email.',
                allowOutsideClick: false
            });

            return;
        } else if (!pattern.test(user_email)) {

            this.state.swalButton.fire({title: 'Please provide a valid email.', allowOutsideClick: false});
            return;

        } else if (user_email !== user_confirm_email) {

            this.state.swalButton.fire({
                title: 'Email and Confirm Email must be matched.',
                allowOutsideClick: false
            });
            return;

        } else if (user_password === "") {
            this.state.swalButton.fire({
                title: 'Please enter password.',
                allowOutsideClick: false
            });

            return;
        } else if (user_password !== user_confirm_password) {

            this.state.swalButton.fire({
                title: 'Password and Confirm Password must be matched.',
                allowOutsideClick: false
            });
            return;

        }


        var dob = "";

        if (moment(user_year + "-" + user_month + "-" + user_day, 'YYYY-MM-DD', true).isValid()) {
            dob = user_year + "-" + user_month + "-" + user_day;
        }


        this.onOpenModal();
        axios.post(API_ROOT + '/signup', {
            customer_firstname: user_firstname,
            customer_lastname: user_lastname,
            customer_type: user_type,
            customer_phone: user_phone,
            customer_dob: dob,
            customer_email: user_email,
            customer_password: user_password,
            merchant_id: localStorage.getItem("merchant_id"),

        })
            .then(response => {

                this.onCloseModal();

                if (response.data.status == 1) {


                    this.state.swalButton.fire({
                        title: "Your account has been created successfully.",
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {

                            localStorage.setItem('user_id', response.data.user_data[0].user_id);
                            localStorage.setItem('user_name', response.data.user_data[0].user_firstname + " " + response.data.user_data[0].user_lastname);
                            localStorage.setItem('user_email', response.data.user_data[0].user_email);

                            this.setState({redirect: true});
                        }
                    });


                } else {
                    this.state.swalButton.fire({
                        title: response.data.message,
                        allowOutsideClick: false
                    });

                }
            }).catch(error => {
            this.onCloseModal();
            console.log(error);
        });


    }

    normalizeInput = (value, previousValue) => {
        if (!value) return value;
        var currentValue = value.replace(/[^\d]/g, '');
        var cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };

    fetchMerchant = () => {

        var url = API_ROOT + '/fetchmerchant';

        this.setState({loading: true});
        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(merchantData => {

                if (merchantData.status == 1 && merchantData.merchant_data.length > 0) {

                    this.setState({load: true});
                    document.title = "Signup - " + merchantData.merchant_data[0].merchant_name;

                    localStorage.setItem('merchant_gateway_type', merchantData.merchant_data[0].merchant_gateway_type);

                    localStorage.setItem('merchant_id', merchantData.merchant_data[0].merchant_id);
                    localStorage.setItem('merchant_image', merchantData.merchant_data[0].merchant_image);
                    localStorage.setItem('merchant_phone', merchantData.merchant_data[0].merchant_phone);
                    localStorage.setItem('merchant_name', merchantData.merchant_data[0].merchant_name);
                    localStorage.setItem('merchant_default_page', merchantData.merchant_data[0].merchant_default_page);
                    localStorage.setItem('merchant_liability_waiver_enabled', merchantData.merchant_data[0].merchant_liability_waiver_enabled);
                    localStorage.setItem('merchant_liability_waiver_text', merchantData.merchant_data[0].merchant_liability_waiver_text);

                    $(".custom-select").each(function () {
                        $(this).wrap("<span class='select-wrapper'></span>");
                        $(this).after("<span class='holder'></span>");
                    });
                    $(".custom-select").change(function () {
                        var selectedOption = $(this).find(":selected").text();
                        $(this).next(".holder").text(selectedOption);
                    }).trigger('change');


                } else {

                    this.setState({redirect: false});
                    this.setState({redirect404: true});
                }
            });
    };


    render() {

        const {open} = this.state;
        if (this.state.redirect) {

            var redirectMyAccount=localStorage.getItem("redirectMyAccount");

            if(redirectMyAccount){
                return <Redirect to="/myaccount"/>;

            }else {

                return <Redirect to="/"/>;
            }
        }
        if (this.state.redirect404) {
            return <Redirect to="/404"/>;
        }
        if (this.state.load) {
            return (<div>
                    <Topbar/>
                    <div class="clearfix"></div>
                    <div class="page">
                        <div class="container eventContainer bg-grey">

                            <div className="login_main register_main">
                                <div className="login-block bg-white">
                                    <div className="login-inner clearfix">
                                        <form className="RegisterForm" name="RegisterForm" method="post" action="#"
                                              encType="multipart/form-data">
                                            <div className="blue-title bg-blue border">
                                                Create Account Here
                                            </div>
                                            <div className="form-group mt-3">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label>Customer Type :</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <select id="user_type" className="form-control custom-select">
                                                            <option value="">Select Type</option>
                                                            <option value="I">Individual</option>
                                                            <option value="C">Company</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label>First Name:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <input type="texr" className="form-control" id="user_firstname"
                                                               name="fname"
                                                               placeholder="Type First Name Here"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label>Last Name:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <input type="text" className="form-control" id="user_lastname"
                                                               placeholder="Type Last Name Here"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label>Birthday :</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="b_month">

                                                            <select id="user_month"
                                                                    className="form-control custom-select">
                                                                <option>Month</option>
                                                                <option value="01">January</option>
                                                                <option value="02">Febuary</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>
                                                            </select>
                                                        </div>
                                                        <div className="b_day">
                                                            <select id="user_day"
                                                                    className="form-control custom-select">
                                                                <option>Day</option>
                                                                <option value="01">1</option>
                                                                <option value="02">2</option>
                                                                <option value="03">3</option>
                                                                <option value="04">4</option>
                                                                <option value="05">5</option>
                                                                <option value="06">6</option>
                                                                <option value="07">7</option>
                                                                <option value="08">8</option>
                                                                <option value="09">9</option>
                                                                <option value="10">10</option>
                                                                <option value="11">11</option>
                                                                <option value="12">12</option>
                                                                <option value="13">13</option>
                                                                <option value="14">14</option>
                                                                <option value="15">15</option>
                                                                <option value="16">16</option>
                                                                <option value="17">17</option>
                                                                <option value="18">18</option>
                                                                <option value="19">19</option>
                                                                <option value="20">20</option>
                                                                <option value="21">21</option>
                                                                <option value="22">22</option>
                                                                <option value="23">23</option>
                                                                <option value="24">24</option>
                                                                <option value="25">25</option>
                                                                <option value="26">26</option>
                                                                <option value="27">27</option>
                                                                <option value="28">28</option>
                                                                <option value="29">29</option>
                                                                <option value="30">30</option>
                                                                <option value="31">31</option>
                                                            </select>
                                                        </div>
                                                        <div className="b_year">
                                                            <select id="user_year"
                                                                    className="form-control custom-select">
                                                                <option>Year</option>
                                                                <option value="2018">2018</option>
                                                                <option value="2017">2017</option>
                                                                <option value="2016">2016</option>
                                                                <option value="2015">2015</option>
                                                                <option value="2014">2014</option>
                                                                <option value="2013">2013</option>
                                                                <option value="2012">2012</option>
                                                                <option value="2011">2011</option>
                                                                <option value="2010">2010</option>
                                                                <option value="2009">2009</option>
                                                                <option value="2008">2008</option>
                                                                <option value="2007">2007</option>
                                                                <option value="2006">2006</option>
                                                                <option value="2005">2005</option>
                                                                <option value="2004">2004</option>
                                                                <option value="2003">2003</option>
                                                                <option value="2002">2002</option>
                                                                <option value="2001">2001</option>
                                                                <option value="2000">2000</option>
                                                                <option value="1999">1999</option>
                                                                <option value="1998">1998</option>
                                                                <option value="1997">1997</option>
                                                                <option value="1996">1996</option>
                                                                <option value="1995">1995</option>
                                                                <option value="1994">1994</option>
                                                                <option value="1993">1993</option>
                                                                <option value="1992">1992</option>
                                                                <option value="1991">1991</option>
                                                                <option value="1990">1990</option>
                                                                <option value="1989">1989</option>
                                                                <option value="1988">1988</option>
                                                                <option value="1987">1987</option>
                                                                <option value="1986">1986</option>
                                                                <option value="1985">1985</option>
                                                                <option value="1984">1984</option>
                                                                <option value="1983">1983</option>
                                                                <option value="1982">1982</option>
                                                                <option value="1981">1981</option>
                                                                <option value="1980">1980</option>
                                                                <option value="1979">1979</option>
                                                                <option value="1978">1978</option>
                                                                <option value="1977">1977</option>
                                                                <option value="1976">1976</option>
                                                                <option value="1975">1975</option>
                                                                <option value="1974">1974</option>
                                                                <option value="1973">1973</option>
                                                                <option value="1972">1972</option>
                                                                <option value="1971">1971</option>
                                                                <option value="1970">1970</option>
                                                                <option value="1969">1969</option>
                                                                <option value="1968">1968</option>
                                                                <option value="1967">1967</option>
                                                                <option value="1966">1966</option>
                                                                <option value="1965">1965</option>
                                                                <option value="1964">1964</option>
                                                                <option value="1963">1963</option>
                                                                <option value="1962">1962</option>
                                                                <option value="1961">1961</option>
                                                                <option value="1960">1960</option>
                                                                <option value="1959">1959</option>
                                                                <option value="1958">1958</option>
                                                                <option value="1957">1957</option>
                                                                <option value="1956">1956</option>
                                                                <option value="1955">1955</option>
                                                                <option value="1954">1954</option>
                                                                <option value="1953">1953</option>
                                                                <option value="1952">1952</option>
                                                                <option value="1951">1951</option>
                                                                <option value="1950">1950</option>
                                                                <option value="1949">1949</option>
                                                                <option value="1948">1948</option>
                                                                <option value="1947">1947</option>
                                                                <option value="1946">1946</option>
                                                                <option value="1945">1945</option>
                                                                <option value="1944">1944</option>
                                                                <option value="1943">1943</option>
                                                                <option value="1942">1942</option>
                                                                <option value="1941">1941</option>
                                                                <option value="1940">1940</option>
                                                                <option value="1939">1939</option>
                                                                <option value="1938">1938</option>
                                                                <option value="1937">1937</option>
                                                                <option value="1936">1936</option>
                                                                <option value="1935">1935</option>
                                                                <option value="1934">1934</option>
                                                                <option value="1933">1933</option>
                                                                <option value="1932">1932</option>
                                                                <option value="1931">1931</option>
                                                                <option value="1930">1930</option>
                                                                <option value="1929">1929</option>
                                                                <option value="1928">1928</option>
                                                                <option value="1927">1927</option>
                                                                <option value="1926">1926</option>
                                                                <option value="1925">1925</option>
                                                                <option value="1924">1924</option>
                                                                <option value="1923">1923</option>
                                                                <option value="1922">1922</option>
                                                                <option value="1921">1921</option>
                                                                <option value="1920">1920</option>
                                                                <option value="1919">1919</option>
                                                                <option value="1918">1918</option>
                                                                <option value="1917">1917</option>
                                                                <option value="1916">1916</option>
                                                                <option value="1915">1915</option>
                                                                <option value="1914">1914</option>
                                                                <option value="1913">1913</option>
                                                                <option value="1912">1912</option>
                                                                <option value="1911">1911</option>
                                                                <option value="1910">1910</option>
                                                                <option value="1909">1909</option>
                                                                <option value="1908">1908</option>
                                                                <option value="1907">1907</option>
                                                                <option value="1906">1906</option>
                                                                <option value="1905">1905</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label>Phone Number :</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <input value={this.state.phone || ''}
                                                               onChange={this.handleChange} id="user_phone" type="phone"
                                                               className="form-control"
                                                               placeholder="(XXX) XXX-XXXX"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label>Email Address :</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <input type="email" id="user_email" className="form-control"
                                                               placeholder="Type Email Address Here"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label>Confirm Email :</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <input type="email" id="user_confirm_email"
                                                               className="form-control"
                                                               placeholder="Type Email Address Again"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label>Password :</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <input type="password" id="user_password"
                                                               className="form-control"
                                                               placeholder="Type Password Here"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label>Confirm Password:</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <input type="password" id="user_confirm_password"
                                                               className="form-control"
                                                               placeholder="Type Password Again"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-30 mt-1">
                                                <input type="button" id="btnSignup" className="btn-green float-right"
                                                       value="Create Account" onClick={this.signup}/>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                           onClose={this.onCloseModal} center>
                        <h2>&nbsp;&nbsp;&nbsp;&nbsp;Signing up...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                        <p style={{textAlign: 'center'}}>
                            <img width={'40'} height={'40'} alt="Loading..." src={'/img/loading1.gif'}/>
                        </p>
                    </Modal>
                    <Footer/>
                </div>
            );
        } else {
            return null;
        }

    }
}

export default withRouter(signup);
