import React, {Component} from 'react';
import {API_ROOT, DOMAIN, IMAGE_ROOT} from "../api-config";
import {Link, Redirect, withRouter} from "react-router-dom";
import $ from "jquery";
import Topbar from './topbar';
import Footer from './footer';
import Calendar from 'react-calendar';
import moment from 'moment-timezone';
import base64 from "base-64";


class home extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {

            eventList: [],
            searchList: [],
            displayList: [],
            eventType: [],
            eventDate: "",
            loading: false,
            redirect: false,
            eventredirect: false,
            redirect404: false,
            calflag: false,
            load: false,
            searchDate:false,


        };

    };


    componentDidMount() {

        sessionStorage.clear();

        if (!localStorage.getItem('merchant_id')) {
            this.fetchMerchant();
        } else {
            this.setState({load: true});

            document.title = "Book Party - " + localStorage.getItem("merchant_name");
            $('head').append('<meta name="description" content="' + localStorage.getItem("merchant_name") + " Phone:" + localStorage.getItem("merchant_phone") + '">');
            this.fetchEventList();

            var dis = this;
            setTimeout(function () {
                dis.loadComponents();
            }, 100);

        }


        $(document).click(function (e) {

            if ($(e.target).closest('.when-party').length == 0) {
                //do stuff


                $(".search-container").hide();

            }

        });

        this.handleScroll();
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    loadComponents() {


        global.today = new Date();
        global.currentMonth = global.today.getMonth();
        global.currentYear = global.today.getFullYear();
        global.selectYear = $("#year");
        global.selectMonth = $("#month");
        global.monthHeader = $("#monthHeader");
        global.yearHeader = $("#yearHeader");
        global.nextBtn = $("#next");
        global.previousBtn = $("#previous");
        global.datePicked = $("#date-picked");
        global.months = "";
        global.days = "";
        global.monthsArr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        global.daysArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        global.months = global.monthsArr;
        global.days = global.daysArr;

        var tableHeaderMonth = $("#thead-month");
        var dataHead = "<tr>";
        var startDay = "";

        for (var dhead in global.days) {
            global.days[dhead] === "Sun" ? startDay = "red-text" : startDay = "";
            dataHead += "<th data-days='" + global.days[dhead] + "' className='" + startDay + "'>" + global.days[dhead] + "</th>";
        }

        dataHead += "</tr>";
        tableHeaderMonth.html(dataHead);

        var test = this;
        global.nextBtn.click(function () {

            test.next();
        });
        global.previousBtn.click(function () {

            test.previous();
        });
        //  nextBtn.addEventListener("click", next, false);
        //   previousBtn.addEventListener("click", previous, false);

        //  this.showCalendar(global.currentMonth, global.currentYear);

        global.createYear = this.yearRange(1970, 2050);
        global.selectYear.html(global.createYear);

        $(".search-container").hide();


        $("#select-party-when").click(function (e) {
            e.stopPropagation();
            $("#whenparty").slideToggle();
            $("#partytype").hide();
        });
        $("#select-party-type").click(function (e) {
            e.stopPropagation();
            $("#partytype").slideToggle();
            $("#whenparty").hide();
        });
    }

    searchEventSelected = e => {

        console.log($(e.target).val());

        console.log(this.state.eventList[$(e.target).val()].event_id);
        this.state.eventList[$(e.target).val()].event_selected = (this.state.eventList[$(e.target).val()].event_selected == "Y") ? "N" : "Y";

    }

    loadEvent = (position) => {


        sessionStorage.setItem("eventID", this.state.displayList[position].event_id);
        sessionStorage.setItem("eventName", this.state.displayList[position].event_title);
        sessionStorage.setItem("eventType", this.state.displayList[position].event_type);


        this.setState({eventredirect: true});


    }

    fetchEventList = e => {
        this.setState({loading: true});
        this.state.displayList = [];

        $(".search-container").hide();
        var eventids = [];

        for (var i = 0; i < this.state.eventList.length; i++) {
            if (this.state.eventList[i].event_selected == "Y") {
                eventids.push(this.state.eventList[i].event_id);
            }
        }


        if(this.state.eventDate!==""){
            this.setState({searchDate:true});
        }
        var merchantid = localStorage.getItem("merchant_id");
        var url = API_ROOT + '/fetcheventlist?type=E&merchant_id=' + merchantid + "&event_date=" + this.state.eventDate + "&event_id=" + eventids;

        var dis = this;
        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(events => {


                if (events.status == 1) {

                    if (this.state.eventList.length == 0) {
                        this.setState({eventList: events.event_data});
                    }
                    this.setState({displayList: events.event_data});


                }
                this.setState({loading: false});
            }).catch(function (error) {
            console.log(error);
            dis.setState({loading: false});

        });

    }


    fetchMerchant = () => {

        var url = API_ROOT + '/fetchmerchant';

        this.setState({loading: true});
        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(merchantData => {

                if (merchantData.status == 1 && merchantData.merchant_data.length > 0) {

                    this.setState({load: true});
                    document.title = "Book Party - " + merchantData.merchant_data[0].merchant_name;
                    $('head').append('<meta name="description" content="' + merchantData.merchant_data[0].merchant_name + " Phone:" + merchantData.merchant_data[0].merchant_phone + '">');

                    localStorage.setItem('merchant_gateway_type', merchantData.merchant_data[0].merchant_gateway_type);
                    localStorage.setItem('merchant_id', merchantData.merchant_data[0].merchant_id);
                    localStorage.setItem('merchant_name', merchantData.merchant_data[0].merchant_name);
                    localStorage.setItem('merchant_image', merchantData.merchant_data[0].merchant_image);
                    localStorage.setItem('merchant_phone', merchantData.merchant_data[0].merchant_phone);
                    localStorage.setItem('merchant_default_page', merchantData.merchant_data[0].merchant_default_page);
                    localStorage.setItem('merchant_liability_waiver_enabled', merchantData.merchant_data[0].merchant_liability_waiver_enabled);
                    localStorage.setItem('merchant_liability_waiver_text', merchantData.merchant_data[0].merchant_liability_waiver_text);

                    this.fetchEventList();
                    var dis = this;
                    setTimeout(function () {
                        dis.loadComponents();
                    }, 100);
                } else {

                    this.setState({redirect: false});
                    this.setState({redirect404: true});
                }
            });
    };

    yearRange(start, end) {
        var years = "";

        for (var year = start; year <= end; year++) {
            years += "<option value='" + year + "'>" + year + "</option>";
        }

        return years;
    }


    next() {
        global.currentYear = global.currentMonth === 11 ? global.currentYear + 1 : global.currentYear;
        global.currentMonth = (global.currentMonth + 1) % 12;
        this.showCalendar(global.currentMonth, global.currentYear);
    }

    previous() {
        global.currentYear = global.currentMonth === 0 ? global.currentYear - 1 : global.currentYear;
        global.currentMonth = global.currentMonth === 0 ? 11 : global.currentMonth - 1;
        this.showCalendar(global.currentMonth, global.currentYear);
    }

    jump() {
        global.currentYear = parseInt(global.selectYear.value);
        global.currentMonth = parseInt(global.selectMonth.value);
        this.showCalendar(global.currentMonth, global.currentYear);
    }

    showCalendar(month, year) {
        var firstDay = new Date(year, month).getDay();
        var monthString = global.monthsArr[month];

        var table = $("#calendar-body");
        $("#calendar-body").html('');
        table.innerHTML = "";
        global.monthHeader.html(monthString.substring(0, 9));
        global.yearHeader.html(year);
        global.selectYear.value = year;
        global.selectMonth.value = month;

        var dis = this;
        var date = 1;

        for (var i = 0; i < 6; i++) {
            var row = document.createElement("tr");

            for (var j = 0; j < 7; j++) {
                if (i === 0 && j < firstDay) {
                    var cell = document.createElement("td");
                    var cellText = document.createTextNode("");
                    cell.append(cellText);
                    row.append(cell);
                } else if (date > this.daysInMonth(month, year)) {
                    break;
                } else {
                    cell = document.createElement("td");
                    cell.setAttribute("data-date", date);
                    cell.setAttribute("data-month", month + 1);
                    cell.setAttribute("data-year", year);
                    cell.setAttribute("data-month-name", global.months[month]);
                    cell.className = "date-picker";
                    cell.innerHTML = "<span>" + date + "</span>";
                    cell.onclick = function (event) {
                        var dates = document.querySelectorAll(".date-picker");
                        var currentTarget = event.currentTarget;
                        var date = currentTarget.dataset.date;
                        var month = currentTarget.dataset.month - 1;
                        var year = currentTarget.dataset.year;

                        dis.setState({eventDate: moment(year + "-" + (month + 1) + "-" + date, "YYYY-M-D").format("YYYY-MM-DD")});


                        for (var i = 0; i < dates.length; i++) {
                            dates[i].classList.remove("selected");
                        }

                        currentTarget.classList.add("selected");
                        global.datePicked.html(date + " " + global.monthsArr[month] + " " + year);
                    }

                    /*  if (date === global.today.getDate() && year === global.today.getFullYear() && month === global.today.getMonth()) {
                          cell.className = "date-picker selected";
                      }*/

                    row.append(cell);
                    date++;
                }
            }

            table.append(row);
        }
    }

    daysInMonth(month, year) {
        return 32 - new Date(year, month, 32).getDate();
    }

    onDateSelected = (value, event) => {

        this.setState({calflag: true});
        this.setState({selectedDate: moment(value).toDate()});
        this.setState({eventDate: moment(value).format("YYYY-MM-DD")});

    }

    clearCalendar = e => {
        this.setState({selectedDate: ""});
        this.setState({eventDate: ""});
        this.setState({calflag: false});
        this.setState({searchDate:false});
        var dis = this;
        setTimeout(function () {
            dis.fetchEventList();
        }, 100);




    }

    scrollToTop = e => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    handleScroll = e => {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            $('#scrollToTopButton').fadeIn(300);
        } else {
            $('#scrollToTopButton').fadeOut(300);
        }
    }

    render() {
        {this.handleScroll()}
        if (this.state.redirect404) {
            return <Redirect to="/404"/>;
        }
        if (this.state.eventredirect) {
            return <Redirect to="/event"/>;
        }
        if (this.state.load) {

            return (<div>

                    <Topbar/>
                    <div className="clearfix"></div>
                    <div className="page">
                        <div className="container eventContainer bg-grey">

                            <div className="page-top clearfix">
                                <div className="col-md-10 m-auto col-sm-12 col-xs-12 m-p-0">
                                    <h1 className="m-display text-center">Book Party</h1>
                                    <div className="when-party float-left position-relative">

                                        <div className="select select-bg" id="select-party-when">
                                            <span>When is your party?</span>
                                            <i className="fa fa-chevron-left"></i>
                                        </div>
                                        <input type="hidden" name="whenparty" className="whenparty"/>
                                        <div className="search-container clearfix" id="whenparty">
                                            <form>
                                                <div className="container-calendar mb-2">
                                                    <Calendar
                                                        onClickDay={this.onDateSelected}
                                                        defaultValue={this.state.selectedDate}
                                                        showNeighboringMonth={false}
                                                        value={!this.state.calflag ? "" : this.state.selectedDate}
                                                        minDate={moment().toDate()}
                                                        tileDisabled={(this.state.dataNotYetFetched) ? () => false : this.disableDates}
                                                    />
                                                </div>

                                                <input type="button" onClick={this.clearCalendar}
                                                       className="btn-green btn-grey  radius-20 float-left"
                                                       value="Reset"/>
                                                <input type="button" onClick={this.fetchEventList}
                                                       className="btn-green radius-20 float-right"
                                                       value="Search"/>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="when-party what-type float-right position-relative">
                                        <div className="select select-bg" id="select-party-type">
                                            <span>What Type Of Party ?</span>
                                            <i className="fa fa-chevron-left"></i>
                                        </div>
                                        <input type="hidden" name="whattypeparty" className="whattypeparty"/>
                                        {(this.state.eventList.length > 0) ?
                                            <div className="search-container" id="partytype">
                                                <form>


                                                    {this.state.eventList.map((item, index) => {

                                                        var checked = (item.event_selected == 'Y') ? true : false;
                                                        return (<div className="radiobtn">
                                                            <input onChange={this.searchEventSelected}
                                                                   defaultChecked={checked} type="checkbox"
                                                                   id={item.event_id}
                                                                   name="" value={index}/>
                                                            <label htmlFor={item.event_id}>{item.event_title}</label>
                                                        </div>);
                                                    })}


                                                    <input type="button" onClick={this.fetchEventList}
                                                           className="btn-green radius-20 float-right"
                                                           value="Search"/>
                                                </form>
                                            </div> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="page-middle clearfix">
                                <div className="events-block-main events-block-main-box box-m-height col-md-12">
                                    <div className="page-title">
                                        <h2><span>Available Events</span></h2>
                                    </div>

                                    <div className="date_info">



                                        {(this.state.searchDate) ?

                                            <div className="dates"><h3 >
                                                {moment(this.state.eventDate).format("MMMM DD, YYYY")}</h3><br/></div>:''}



                                    </div>


                                    {(this.state.displayList.length > 0) ?

                                        <div className="row">{
                                            this.state.displayList.map((item, index) => {
                                                return (<div className="col-md-4 col-sm-6 col-xs-6">
                                                    <div className="events-block h-auto">
                                                        {item.event_image !== "" ?<div className="event-img">
                                                            <figure>

                                                                    <div className="imageContainer"><img
                                                                        src={IMAGE_ROOT + "/images/product/" + item.event_image}
                                                                        alt={item.event_title}
                                                                        className="img-fluid"/></div>
                                                                <figcaption
                                                                    className="text-left min-height-0 eventTitle">{item.event_title}</figcaption>
                                                            </figure>
                                                        </div>:<div className="event-img">
                                                            <figure>

                                                                <figcaption
                                                                    className="text-left min-height-0 eventTitle">{item.event_title}</figcaption>
                                                            </figure>
                                                        </div>}
                                                        {item.event_image !== "" ?"":<div className="event-img">
                                                            <figure>

                                                                <div className="imageContainer"><img
                                                                    src="/img/blank.png"
                                                                    alt={item.event_title}
                                                                    className="img-fluid"/></div>

                                                            </figure>
                                                        </div>}
                                                        <div className="event-body">
                                                            <p className="mb-4 eventInfo"> {item.event_info}</p>
                                                            <p className="font-weight-bold mb-10">Up
                                                                to {item.event_guest_max} people</p>

                                                            {(item.event_payment_type == "D") ? (item.event_deposit_amount_enabled == "Y") ?
                                                                <p className="font-weight-medium mb-15">Deposit Amount:
                                                                    ${item.event_deposit}</p>:<p className="font-weight-medium mb-15">Deposit Amount:
                                                                    ${((item.event_base_price * item.event_deposit)/100).toFixed(2)}</p>
                                                                : <p className="font-weight-medium mb-15">Base Price:
                                                                    ${item.event_base_price} {/*<span
                                                                        className="nodeposit">- No
                                                                        deposit required.</span>*/}</p>}
                                                        </div>

                                                        <div className="event-book-now">
                                                            <Link
                                                                to={"/event/" + base64.encode(item.event_id + "&&" + item.event_title + "&&" + item.event_type)}>Book
                                                                Now </Link>
                                                        </div>
                                                    </div>
                                                </div>)
                                            })}</div>
                                        : this.state.loading ? <div className="row">
                                                <div className="homeLoadingContainer">

                                                    <img alt="Loading..." src="/img/loading1.gif"/>
                                                </div>
                                            </div> :
                                            <div className="center-block  text-center"><h4>No events available !</h4>
                                            </div>}


                                </div>
                            </div>


                            <div className="scrollButtonContainer">
                                <div className="scrollToTopButton" onClick={this.scrollToTop}
                                     id="scrollToTopButton">
                                    <img src="img/arrow-up.svg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>


            );


        } else {
            return null;
        }

    }
}

export default withRouter(home);
