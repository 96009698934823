import React, {Component} from 'react';
import {API_ROOT, DOMAIN, IMAGE_ROOT} from "../api-config";
import {Redirect, withRouter} from "react-router-dom";
import Topbar from './topbar';
import Footer from './footer';
import 'react-responsive-modal/styles.css';
import Swal from "sweetalert2";
import Modal from "react-responsive-modal";
import moment from "moment-timezone";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import $ from "jquery";
import base64 from 'base-64';
import Notifications, {notify} from 'react-notify-toast';


import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    HatenaIcon,
    HatenaShareButton,
    InstapaperIcon,
    InstapaperShareButton,
    LineIcon,
    LineShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    LivejournalIcon,
    LivejournalShareButton,
    MailruIcon,
    MailruShareButton,
    OKIcon,
    OKShareButton,
    PinterestIcon,
    PinterestShareButton,
    PocketIcon,
    PocketShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TumblrShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    VKIcon,
    VKShareButton,
    WeiboIcon,
    WeiboShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    WorkplaceIcon,
    WorkplaceShareButton,
} from 'react-share';
import BModal from 'react-bootstrap/Modal';


class confirm extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            redirect: false,
            redirect404: false,
            reference: this.props.match.params.reference,
            load: false,
            merchantload: false,

            swalButton: "",
            open: false,
            msg: '',
            show: false,
            includes: [],
            paymentSubtotal: 0,
            paymentNCA: 0,
            exampleImage: '',
            addonAmount:0,
            paymentTax: 0,
            paymenTotal: 0,
            paymentTime:"",
            specialElementHandlers: "",
            paymentTotalTax: 0,
            doc: '',
            shareUrl: '',
            title: '',
            data: '',


        };

    };

    componentWillMount() {
        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-green',
                    cancelButton: 'btn-green'
                },
                buttonsStyling: false,
            })
        });

        sessionStorage.removeItem("currentComponent");
        sessionStorage.removeItem("currentComponentName");


    }

    handleShow = () => {
        this.setState({show: true});

    };
    handleClose = () => {
        this.setState({show: false});
    };

    componentDidMount() {

        console.log('in did mount');

        window.html2canvas = html2canvas;

        if (!localStorage.getItem('merchant_id')) {
            this.fetchMerchant();
        } else {

            this.setState({merchantload: true});
            this.fetchBookingData();


        }


    }


    fetchBookingData = () => {


        var url = API_ROOT + '/fetchbooking?reference=' + this.state.reference;

        this.onOpenModal("Fetching Booking Information");

        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(data => {

                if (data.status == 1) {


                    this.setState({data: data.event});
                    this.setState({load: true});

                    document.title = data.event[0].event_title + " - " + localStorage.getItem("merchant_name");

                    $('head').append('<meta property="og:url" content="'+window.location.href+'"/>');
                    $('head').append('<meta property="og:type" content="article"/>');
                    $('head').append('<meta property="og:title" content="'+ data.event[0].event_title + " - " + localStorage.getItem("merchant_name")+'"/>');
                    $('head').append('<meta property="og:description" content="'+ data.event[0].event_info+'"/>');
                    $('head').append('<meta property="og:image" content="'+IMAGE_ROOT + "/images/product/" + data.event[0].event_image+'"/>');

                    var eventID = data.event[0].event_id;
                    var eventName = data.event[0].event_title;
                    var eventType = data.event[0].event_type;
                    var userEventDate = moment(this.state.data[0].order_booking_at, "MM/DD/YYYY").format("YYYY-MM-DD");
                    var userEventTime = moment(this.state.data[0].order_booking_at + " " + this.state.data[0].order_duration_from, "MM/DD/YYYY HH:mm:ss").format("hh:mm A") + " - " + moment(this.state.data[0].order_booking_at + " " + this.state.data[0].order_duration_to, "MM/DD/YYYY HH:mm:ss").format("hh:mm A");

                    this.setState({paymentTime:userEventTime});



                    var addon=0;

                    for(var i=0;i<data.event[0].order_details.length;i++){

                        addon+=(parseFloat(data.event[0].order_details[i].product_price)*parseInt(data.event[0].order_details[i].product_quantity));
                    }

                    this.setState({addonAmount:addon.toFixed(2)});

                    var url = eventID + "&&" + eventName + "&&" + eventType + "&&" + userEventDate + "&&" + userEventTime;

                    url = base64.encode(url);


                    this.setState({shareUrl: window.location.origin + "/event/" + url});
                    this.setState({exampleImage: data.event[0].event_image});
                    if (data.event[0].includes.length > 0) {

                        var includes = [];

                        for (var i = 0; i < data.event[0].includes.length; i++) {
                            includes.push(data.event[0].includes[i].event_text);
                        }

                        this.setState({includes: includes});
                    } else {
                        if (data.event[0].event_includes != "") {

                            /* data.event[0].event_includes = data.event[0].event_includes.replaceAll("\n", "");
                             var test = data.event[0].event_includes.split(".");

                             var includes = [];
                             for (var i = 0; i < test.length; i++) {
                                 console.log("A" + test[i] + "B");
                                 if (test[i] !== "") {
                                     includes.push(test[i]);
                                 }
                             }*/
                            var includes = [];
                            includes.push(data.event[0].event_includes);

                            if (includes.length > 0) {

                                this.setState({includes: includes});
                            } else {

                                this.setState({includes: []});
                            }

                        } else {

                            this.setState({includes: []});
                        }

                    }

                    this.onCloseModal();


                } else {

                    this.state.swalButton.fire({
                        title: 'Booking not found.',
                        allowOutsideClick: false
                    });

                    this.state.swalButton.fire({
                        title: 'Booking not found.',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {

                            this.setState({redirect: true});
                        }
                    });

                }
            });


    }


    fetchMerchant = () => {

        var url = API_ROOT + '/fetchmerchant';


        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(merchantData => {

                if (merchantData.status == 1 && merchantData.merchant_data.length > 0) {

                    this.setState({merchantload: true});
                    document.title = "Login - " + merchantData.merchant_data[0].merchant_name;

                    localStorage.setItem('merchant_gateway_type', merchantData.merchant_data[0].merchant_gateway_type);
                    localStorage.setItem('merchant_id', merchantData.merchant_data[0].merchant_id);
                    localStorage.setItem('merchant_image', merchantData.merchant_data[0].merchant_image);
                    localStorage.setItem('merchant_phone', merchantData.merchant_data[0].merchant_phone);
                    localStorage.setItem('merchant_name', merchantData.merchant_data[0].merchant_name);
                    localStorage.setItem('merchant_default_page', merchantData.merchant_data[0].merchant_default_page);
                    localStorage.setItem('merchant_liability_waiver_enabled', merchantData.merchant_data[0].merchant_liability_waiver_enabled);
                    localStorage.setItem('merchant_liability_waiver_text', merchantData.merchant_data[0].merchant_liability_waiver_text);

                    this.fetchBookingData();
                } else {

                    this.setState({redirect: false});
                    this.setState({redirect404: true});
                }
            });
    };
    copy = e => {
        let myColor = {background: '#0E1717', text: "#FFFFFF"};
        notify.show("Link copied to clipboard.", "custom", 2000, myColor);
        $('#bookingURL').select();
        navigator.clipboard.writeText(this.state.shareUrl);
        document.execCommand("copy")
    }
    print = (e) => {

        const input = document.getElementById('receipt');
        console.log(input.innerHTML);
        $('.event-img').css('display', 'none');
        $('#removeCount').css('display', 'none');
        $('#buttons').css('display', 'none');
        html2canvas(input)
            .then((canvas) => {
                $('.event-img').css('display', 'block');
                $('#removeCount').css('display', 'block');
                $('#buttons').css('display', 'block');


                const imgData = canvas.toDataURL('img/png');

                var myWindow = window.open("", "Image");
                myWindow.document.write("<center><img style='height:90vh' src='" + imgData + "''></center>");

                setTimeout(function () {
                    myWindow.print();
                }, 1000);

                this.onCloseModal();
            });
    }
    downloadPDF = (e) => {


        const input = document.getElementById('receipt');


        this.onOpenModal("Generating PDF");

        $('.event-img').css('display', 'none');
        $('#removeCount').css('display', 'none');
        $('#buttons').css('display', 'none');
        html2canvas(input)
            .then((canvas) => {
                $('.event-img').css('display', 'block');
                $('#removeCount').css('display', 'block');
                $('#buttons').css('display', 'block');

               /* let imgWidth = 80;
                let imgHeight = canvas.height * imgWidth / canvas.width;
                const imgData = canvas.toDataURL('img/png');*/
                const pdf = new jsPDF('p', 'mm', 'a4');

                let imgh=canvas.height;
                let imgw=canvas.width;

                let res=imgw/imgh;

                const pageWidth = pdf.internal.pageSize.getWidth();
                const pageHeight = pdf.internal.pageSize.getHeight();


                let imgHeight = pageHeight -50;
                let imgWidth=imgHeight*res;

                const imgData = canvas.toDataURL('img/png',.50);

                pdf.addImage(imgData, 'PNG', (pageWidth / 2) - (imgWidth / 2), 10, imgWidth, imgHeight, undefined,'FAST');
              //  pdf.addImage(imgData, 'PNG', (pageWidth / 2) - (imgWidth / 2), 10, imgWidth, imgHeight);
                // pdf.output('dataurlnewwindow');
                pdf.save("Booking Confirmation.pdf");
                this.onCloseModal();
            })
        ;
    }


    onOpenModal = (msg) => {
        this.setState({msg: msg});
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({msg: ''});
        this.setState({open: false});
    };


    share = e => {
        this.handleShow();
    }

    render() {

        const {open} = this.state;
        const {msg} = this.state;
        const {shareUrl} = this.state;
        const {title} = this.state;
        const {exampleImage} = this.state;
        if (this.state.redirect) {
            return <Redirect to="/"/>;
        }
        if (this.state.redirect404) {
            return <Redirect to="/404"/>;
        }

        if (this.state.merchantload) {
            return (<div>
                    <Topbar/>

                    <div className="clearfix"></div>
                    <div className="page">
                        <div className="container bg-grey">
                            <div className="page-middle clearfix">
                                {this.state.load ?
                                    <div className="events-block-main confirmed_detail_block border-0 p-0 col-md-12">
                                        <div className="row">
                                            <div className="booked_confirmed_box m-auto">
                                                <div id="receipt"
                                                     className="events-block block-section mt-25 position-relative min-height-auto">
                                                    <div className="event-body confirmed_content_body mb-0">
                                                        <div className="booked_confirmed_div_main clearfix">
                                                            <div className="booked_confirmed_div mb-4 clearfix">
                                                                <div className="c_left">
                                                                    <img src="/img/check-green-circle-icon.png"
                                                                         width="25"
                                                                         height="25" alt="Confirmed"/>
                                                                </div>
                                                                <div className="c_right">
                                                                    <h3>Booking Confirmed</h3>
                                                                    <p>Confirmation email sent
                                                                        to {this.state.data[0].user_email}<br/>
                                                                        Reference Number
                                                                        is <strong>{this.state.reference}</strong></p>
                                                                </div>
                                                            </div>
                                                            <div id="buttons" className="btn-confirmed-div"
                                                                 style={{textAlign: "center"}}>

                                                                {(this.state.data[0].event_sub_type != "P") ?
                                                                    <a onClick={this.share} className="btn-blue"><span
                                                                        className="btn-width"> <span
                                                                    >Share <img
                                                                        src="/img/ic_share_24px.png"
                                                                        alt="Share"/></span></span> </a> : ''}
                                                                <a href="#" className="btn-blue ml-2"><span
                                                                    className="btn-width"><span
                                                                    onClick={this.print}>Print <img
                                                                    src="/img/ic_print_24px.png"
                                                                    alt="Print"/></span> </span> </a>
                                                                <a href="#" className="btn-blue ml-2"><span
                                                                    className="btn-width"><span
                                                                    onClick={this.downloadPDF}>Download <img
                                                                    src="/img/ic_file_download_24px.png"
                                                                    alt="Download"/></span></span>
                                                                </a>

                                                            </div>
                                                        </div>
                                                        <hr/>

                                                        {(this.state.data[0].event_type === "E") ?
                                                            <div>
                                                                <div className="event-img position-relative">
                                                                    <figure>
                                                                        {this.state.data[0].event_image !== "" ?
                                                                            <img crossOrigin={true} id="removeImage"
                                                                                 src={IMAGE_ROOT + "/images/product/" + this.state.data[0].event_image}
                                                                                 alt={this.state.data[0].event_title}
                                                                                 className="img-fluid"/> : ''}


                                                                    </figure>
                                                                    {/*<div id="removeCount"
                                                                         className="count_people position-absolute">
                                                                        <p>up
                                                                            to <span>{this.state.data[0].event_guest_max}</span>people
                                                                        </p>
                                                                    </div>*/}
                                                                </div>

                                                                <div className="event-content">
                                                                    <p className="roboto-bold mt-2 mb-2 font-22">{this.state.data[0].event_title}</p>
                                                                    <p>{this.state.data[0].event_info}</p>
                                                                </div>
                                                                <p className="font-weight-medium mb-4 font-14">Up
                                                                    to {this.state.data[0].event_guest_default}

                                                                    people</p>
                                                                <div className="line"></div>
                                                                {(this.state.includes.length > 0) ?
                                                                    <p className="font-weight-medium mb-3 mt-3 font-14 text-uppercase">What
                                                                        is
                                                                        Included ?</p> : ''}
                                                                <ul>

                                                                    {this.state.includes.map((item, index) => {

                                                                        return (<li>{item}</li>);
                                                                    })}
                                                                </ul>
                                                                <div className="event-book-now p-15 mt-3 clearfix">
                                                                    {(this.state.data[0].event_payment_type === "D") ? (this.state.data[0].event_deposit_amount_enabled === "Y") ?
                                                                        <p className="roboto-bold mb-2 float-left">Deposit
                                                                            Amount
                                                                            : <span
                                                                                className="float-right"> ${this.state.data[0].event_deposit}</span>
                                                                        </p>: <p className="roboto-bold mb-2 float-left">Deposit
                                                                            Amount
                                                                            : <span
                                                                                className="float-right"> ${((parseFloat(this.state.data[0].event_deposit)*parseFloat(this.state.data[0].event_base_price))/100).toFixed(2)}</span>
                                                                        </p> : ''}
                                                                    {//(this.state.data[0].order_payment_type !== "D") ?
                                                                        <p>Base Price : <span
                                                                            className="float-right">${this.state.data[0].event_base_price}</span>
                                                                        </p> /*: ''*/}
                                                                </div>
                                                            </div> :

                                                            <div>
                                                                <div className="events-block"
                                                                     style={{minHeight: "0px"}}>

                                                                    <div className="event-img">
                                                                        <figure>

                                                                            {this.state.data[0].event_image !== "" ?
                                                                                <img crossOrigin id="removeImage"
                                                                                     src={IMAGE_ROOT + "/images/product/" + this.state.data[0].event_image}
                                                                                     alt={this.state.data[0].event_title}
                                                                                     className="img-fluid"/> : ''}

                                                                        </figure>


                                                                    </div>
                                                                    <div className="event-body">

                                                                        <div className="event-content">

                                                                            <div className="time_info clearfix mb-3">
                                                                                <p className="roboto-bold mt-2 mb-2 font-22">{this.state.data[0].event_title}</p>
                                                                                <p className="roboto-bold">{moment(this.state.data[0].order_booking_at, "MM/DD/YYYY").format("dddd, MMMM, DD,YYYY")}</p>
                                                                                <br/>
                                                                                <p className="roboto-bold">{this.state.paymentTime}</p>

                                                                            </div>
                                                                            <p className="mb-4"> {this.state.data[0].event_info}</p>

                                                                        </div>
                                                                    </div>
                                                                    <div className="event-book-now" style={{
                                                                        display: "flex",
                                                                        paddingLeft: "10px",
                                                                        paddingRight: "10px",
                                                                        fontWeight: "bold"
                                                                    }}>
                                                                        <p className="roboto-regular"><i
                                                                            className="icon-ticket"></i> {this.state.data[0].order_adults} Tickets
                                                                            :</p>
                                                                        <p className="roboto-regular"
                                                                           style={{textAlign: "right"}}> ${(this.state.data[0].payment_amount-this.state.addonAmount).toFixed(2)}</p>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        }
                                                    </div>


                                                    <div className="PartyQuestionaire">
                                                        <div className="title_div mb-2">
                                                            <h1 className="font_26">Your {(this.state.data[0].event_type === "E") ? "Party" : "Admission"} Details</h1>
                                                        </div>
                                                        <div className="addons_block_main party-info-height">
                                                            <div className="guest_block addons_block clearfix w-84">
                                                                <form method="post" action="#"
                                                                      encType="multipart/form-data">
                                                                    {(this.state.data[0].event_type === "E") ?
                                                                        <div className="form-group">
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           name="" placeholder=""
                                                                                           value={moment(this.state.data[0].order_booking_at, "MM/DD/YYYY").format("dddd, MMMM, DD,YYYY")}
                                                                                           disabled/>
                                                                                </div>
                                                                            </div>
                                                                        </div> : ''}

                                                                    {(this.state.data[0].event_type === "E") ?
                                                                        <div className="form-group">
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           name="" placeholder=""
                                                                                           value={moment(this.state.data[0].order_booking_at + " " + this.state.data[0].order_duration_from, "MM/DD/YYYY HH:mm:ss").format("h:mm A") + " - " + moment(this.state.data[0].order_booking_at + " " + this.state.data[0].order_duration_to, "MM/DD/YYYY HH:mm:ss").format("h:mm A")}
                                                                                           disabled/>
                                                                                </div>
                                                                            </div>
                                                                        </div> : ''}
                                                                    {(this.state.data[0].event_type === "E") ?
                                                                        <div className="form-group">
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           name="" placeholder=""
                                                                                           value={this.state.data[0].order_adults + " Guests"}
                                                                                           disabled/>
                                                                                </div>
                                                                            </div>
                                                                        </div> : ''}


                                                                    {this.state.data[0].answers.map((item, index) => {


                                                                        return (<div className="form-group">
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <label
                                                                                        className="font-16">{item.question_name}</label>
                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           name="" placeholder=""
                                                                                           value={item.order_answer}
                                                                                           disabled/>
                                                                                </div>
                                                                            </div>
                                                                        </div>)

                                                                    })}

                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="confirmed_booked_detail mb-50">

                                                        <div className="addons_block_main party-info-height">
                                                            <div className="guest_block addons_block clearfix w-84">
                                                                <div className="confirmed_detail_block clearfix mb-15">
                                                                    <div className="confirmed_title">
                                                                        <div className="d_left">
                                                                            <h3>Items</h3>
                                                                        </div>
                                                                        <div className="d_right">
                                                                            <h3>Price</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="confirmed_content_main">
                                                                        <div
                                                                            className="confirmed_content mb-3 clearfix position-relative">
                                                                            {(this.state.data[0].event_type === "E") ?
                                                                                <div className="d_left">
                                                                                    <p className="font-weight-medium"> {this.state.data[0].event_title}</p>
                                                                                </div>
                                                                                : <div className="d_left">
                                                                                    <p>
                                                                                        <span>{this.state.data[0].event_title}</span>

                                                                                        {(parseInt(this.state.data[0].order_adults) > 1) ?
                                                                                            <span
                                                                                                className="roboto-bold ml-50">({this.state.data[0].order_adults}x)</span> : ''}
                                                                                    </p>
                                                                                </div>}
                                                                            {(this.state.data[0].event_type === "E") ?
                                                                                <div className="d_right">
                                                                                    <p className="font-16 roboto-bold text-right">${(parseFloat(this.state.data[0].order_event_amount) > 0) ? parseFloat(this.state.data[0].order_event_amount).toFixed(2) : parseFloat(this.state.data[0].event_base_price).toFixed(2)}</p>
                                                                                </div> : <div className="d_right">
                                                                                    <p className="font-16 roboto-bold text-right">${(this.state.data[0].payment_amount-this.state.addonAmount).toFixed(2)}</p>
                                                                                </div>}
                                                                        </div>

                                                                        {this.state.includes.map((item, index) => {
                                                                            return (
                                                                                <div
                                                                                    className="confirmed_content clearfix">
                                                                                    <div className="d_left">
                                                                                        <p><span className="img"><img
                                                                                            src="/img/check-icon.png"
                                                                                            className="mr-1" width="16"
                                                                                            height="14"
                                                                                            alt="Confirmed"/></span>
                                                                                            <span>{item}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="d_right">
                                                                                        <p className="font-14 text-right">Included</p>
                                                                                    </div>
                                                                                </div>)

                                                                        })}


                                                                        <div
                                                                            className="confirmed_content mb-3 clearfix position-relative">
                                                                            <div className="d_left">
                                                                                <p>
                                                                                    <span>{moment(this.state.data[0].order_booking_at, "MM/DD/YYYY").format("MMMM, DD,YYYY")}</span>

                                                                                </p>
                                                                            </div>
                                                                            <div className="d_right">
                                                                                <p className="font-16 roboto-bold text-right"></p>
                                                                            </div>
                                                                        </div>

                                                                        <div
                                                                            className="confirmed_content mb-3 clearfix position-relative">
                                                                            <div className="d_left">
                                                                                <p>
                                                                                    <span>{moment(this.state.data[0].order_booking_at + ' ' + this.state.data[0].order_duration_from, 'MM/DD/YYYY HH:mm:ss').format("h:mm A") + " - " + moment(this.state.data[0].order_booking_at + ' ' + this.state.data[0].order_duration_to, 'MM/DD/YYYY HH:mm:ss').format("h:mm A")}</span>

                                                                                </p>
                                                                            </div>
                                                                            <div className="d_right">
                                                                                <p className="font-16 roboto-bold text-right"></p>
                                                                            </div>
                                                                        </div>
                                                                        {(this.state.data[0].event_type === "E") ?
                                                                            <div
                                                                                className="confirmed_content mb-3 clearfix position-relative">
                                                                                <div className="d_left">
                                                                                    <p>
                                                                                        <span>                           {parseInt(this.state.data[0].order_adults) + parseInt(this.state.data[0].order_children)} Guests</span>

                                                                                    </p>
                                                                                </div>
                                                                                <div className="d_right">
                                                                                    <p className="font-16 roboto-bold text-right"></p>
                                                                                </div>
                                                                            </div> : ''}

                                                                        {(parseInt(this.state.data[0].order_aditional_guest)) > 0 ?

                                                                            <div
                                                                                className="confirmed_content mb-3 clearfix position-relative">
                                                                                <div className="d_left">
                                                                                    <p>
                                                                                        <span>Additional Guests</span>

                                                                                    </p>
                                                                                </div>
                                                                                <div className="d_right">
                                                                                    <p className="font-16 roboto-bold text-right">${parseInt(this.state.data[0].order_aditional_guest) * parseFloat(this.state.data[0].order_aditional_amount).toFixed(2)}</p>
                                                                                </div>
                                                                            </div>
                                                                            : ''}


                                                                        {this.state.data[0].order_details.map((item, index) => {

                                                                            if (parseInt(item.product_quantity) > 0) {
                                                                                return (
                                                                                    <div
                                                                                        className="confirmed_content mb-3 clearfix position-relative">
                                                                                        <div className="d_left">
                                                                                            <p>
                                                                                                <span>{item.product_name}</span>

                                                                                                {(parseInt(item.product_quantity) > 1) ?
                                                                                                    <span
                                                                                                        className="roboto-bold ml-50">({item.product_quantity}x)</span> : ''}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="d_right">
                                                                                            <p className="font-16 roboto-bold text-right">${(parseInt(item.product_quantity) * parseFloat(item.product_price)).toFixed(2)}</p>
                                                                                        </div>
                                                                                    </div>)
                                                                            }
                                                                        })}


                                                                    </div>
                                                                    <div
                                                                        className="confirmed_title subtotal_title mb-2">
                                                                        <div className="d_left">
                                                                            <h3>Subtotal</h3>
                                                                        </div>
                                                                        <div className="d_right text-right">
                                                                            <h3>${this.state.data[0].payment_amount.toFixed(2)}</h3>
                                                                        </div>
                                                                    </div>

                                                                    {this.state.data[0].taxes.map((item, index) => {
                                                                        return (<div
                                                                            className="confirmed_content mb-0 clearfix p-2">
                                                                            <div className="d_left">
                                                                                <p className="font-14">{item.tax_name} ({item.tax_percentage}%)</p>
                                                                            </div>
                                                                            <div className="d_right">
                                                                                <p className="font-16 text-right">${parseFloat(item.tax_value).toFixed(2)}</p>
                                                                            </div>
                                                                        </div>)
                                                                    })}
                                                                    {(parseFloat(this.state.data[0].payment_nca) > 0) ?
                                                                        <div
                                                                            className="confirmed_content mb-0 clearfix p-2">
                                                                            <div className="d_left">
                                                                                <p className="font-14">{this.state.data[0].merchant_nca_name}</p>
                                                                            </div>
                                                                            <div className="d_right">
                                                                                <p className="font-16 text-right">${this.state.data[0].payment_nca.toFixed(2)}</p>
                                                                            </div>
                                                                        </div> : ''}
                                                                    <div
                                                                        className="confirmed_title subtotal_title bg-yellow">
                                                                        <div className="d_left">
                                                                            <h3>Total</h3>
                                                                        </div>
                                                                        <div className="d_right text-right">
                                                                            <h3>${this.state.data[0].payment_total.toFixed(2)}</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="btn-blue roboto-light cursor-default font-22">Paid
                                                                    ${this.state.data[0].payment_total.toFixed(2)} </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''}
                            </div>
                        </div>
                    </div>
                    <BModal show={this.state.show} onHide={this.handleClose}>

                        <BModal.Body>
                            <div className="login-inner clearfix">
                                <form className="loginForm" name="loginForm" method="post" action="#"
                                      encType="multipart/form-data">
                                    <div className="blue-title bg-blue border">
                                        Share
                                    </div>
                                    <div className="input-group mb-4">
                                        <div className="Demo__some-network">
                                            <FacebookShareButton
                                                url={shareUrl}
                                                quote={title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <FacebookIcon size={32} round/>
                                            </FacebookShareButton>


                                        </div>

                                        <div className="Demo__some-network">
                                            <FacebookMessengerShareButton
                                                url={shareUrl}
                                                appId="521270401588372"
                                                className="Demo__some-network__share-button"
                                            >
                                                <FacebookMessengerIcon size={32} round/>
                                            </FacebookMessengerShareButton>
                                        </div>

                                        <div className="Demo__some-network">
                                            <TwitterShareButton
                                                url={shareUrl}
                                                title={title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <TwitterIcon size={32} round/>
                                            </TwitterShareButton>


                                        </div>

                                        <div className="Demo__some-network">
                                            <TelegramShareButton
                                                url={shareUrl}
                                                title={title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <TelegramIcon size={32} round/>
                                            </TelegramShareButton>


                                        </div>

                                        <div className="Demo__some-network">
                                            <WhatsappShareButton
                                                url={shareUrl}
                                                title={title}
                                                separator=":: "
                                                className="Demo__some-network__share-button"
                                            >
                                                <WhatsappIcon size={32} round/>
                                            </WhatsappShareButton>


                                        </div>

                                        <div className="Demo__some-network">
                                            <LinkedinShareButton url={shareUrl}
                                                                 className="Demo__some-network__share-button">
                                                <LinkedinIcon size={32} round/>
                                            </LinkedinShareButton>
                                        </div>

                                        <div className="Demo__some-network">
                                            <PinterestShareButton
                                                url={String(window.location)}
                                                media={`${String(window.location)}/${exampleImage}`}
                                                className="Demo__some-network__share-button"
                                            >
                                                <PinterestIcon size={32} round/>
                                            </PinterestShareButton>


                                        </div>

                                        <div className="Demo__some-network">
                                            <VKShareButton
                                                url={shareUrl}
                                                image={`${String(window.location)}/${exampleImage}`}
                                                className="Demo__some-network__share-button"
                                            >
                                                <VKIcon size={32} round/>
                                            </VKShareButton>


                                        </div>

                                        <div className="Demo__some-network">
                                            <OKShareButton
                                                url={shareUrl}
                                                image={`${String(window.location)}/${exampleImage}`}
                                                className="Demo__some-network__share-button"
                                            >
                                                <OKIcon size={32} round/>
                                            </OKShareButton>


                                        </div>

                                        <div className="Demo__some-network">
                                            <RedditShareButton
                                                url={shareUrl}
                                                title={title}
                                                windowWidth={660}
                                                windowHeight={460}
                                                className="Demo__some-network__share-button"
                                            >
                                                <RedditIcon size={32} round/>
                                            </RedditShareButton>


                                        </div>

                                        <div className="Demo__some-network">
                                            <TumblrShareButton
                                                url={shareUrl}
                                                title={title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <TumblrIcon size={32} round/>
                                            </TumblrShareButton>


                                        </div>

                                        <div className="Demo__some-network">
                                            <LivejournalShareButton
                                                url={shareUrl}
                                                title={title}
                                                description={shareUrl}
                                                className="Demo__some-network__share-button"
                                            >
                                                <LivejournalIcon size={32} round/>
                                            </LivejournalShareButton>
                                        </div>

                                        <div className="Demo__some-network">
                                            <MailruShareButton
                                                url={shareUrl}
                                                title={title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <MailruIcon size={32} round/>
                                            </MailruShareButton>
                                        </div>

                                        <div className="Demo__some-network">
                                            <EmailShareButton
                                                url={shareUrl}
                                                subject={title}
                                                body="body"
                                                className="Demo__some-network__share-button"
                                            >
                                                <EmailIcon size={32} round/>
                                            </EmailShareButton>
                                        </div>
                                        <div className="Demo__some-network">
                                            <ViberShareButton
                                                url={shareUrl}
                                                title={title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <ViberIcon size={32} round/>
                                            </ViberShareButton>
                                        </div>

                                        <div className="Demo__some-network">
                                            <WorkplaceShareButton
                                                url={shareUrl}
                                                quote={title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <WorkplaceIcon size={32} round/>
                                            </WorkplaceShareButton>
                                        </div>

                                        <div className="Demo__some-network">
                                            <LineShareButton
                                                url={shareUrl}
                                                title={title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <LineIcon size={32} round/>
                                            </LineShareButton>
                                        </div>

                                        <div className="Demo__some-network">
                                            <WeiboShareButton
                                                url={shareUrl}
                                                title={title}
                                                image={`${String(window.location)}/${exampleImage}`}
                                                className="Demo__some-network__share-button"
                                            >
                                                <WeiboIcon size={32} round/>
                                            </WeiboShareButton>
                                        </div>

                                        <div className="Demo__some-network">
                                            <PocketShareButton
                                                url={shareUrl}
                                                title={title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <PocketIcon size={32} round/>
                                            </PocketShareButton>
                                        </div>

                                        <div className="Demo__some-network">
                                            <InstapaperShareButton
                                                url={shareUrl}
                                                title={title}
                                                className="Demo__some-network__share-button"
                                            >
                                                <InstapaperIcon size={32} round/>
                                            </InstapaperShareButton>
                                        </div>

                                        <div className="Demo__some-network">
                                            <HatenaShareButton
                                                url={shareUrl}
                                                title={title}
                                                windowWidth={660}
                                                windowHeight={460}
                                                className="Demo__some-network__share-button"
                                            >
                                                <HatenaIcon size={32} round/>
                                            </HatenaShareButton>


                                        </div>
                                    </div>

                                    <div className="w-100">
                                        <label style={{width: "100%"}}>Booking URL</label>
                                        <input id="bookingURL" style={{display: "inline-block", width: "80%"}} className="form-control"
                                               value={this.state.shareUrl}/>

                                        &nbsp; <a className="btn-link" style={{
                                        textDecoration: "none",
                                        display: "inline-block",
                                        fontSize: "30px"
                                    }} href={this.state.shareUrl} target="_blank"><i className="icon-external-link"></i>
                                    </a>
                                        &nbsp; <a className="btn-link" style={{
                                        textDecoration: "none",
                                        display: "inline-block",
                                        fontSize: "30px"
                                    }} href="#" onClick={this.copy}><i className="icon-copy"></i> </a>
                                    </div>

                                </form>
                            </div>
                        </BModal.Body>

                    </BModal>
                    <Notifications options={{zIndex: 9999, width: '100%', top: '50px'}}/>
                    <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                           onClose={this.onCloseModal} center>
                        <h2>&nbsp;&nbsp;&nbsp;&nbsp;{msg}...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                        <p style={{textAlign: 'center'}}>
                            <img width={'40'} height={'40'} alt="Loading..." src={'/img/loading1.gif'}/>
                        </p>
                    </Modal>
                    <Footer/>
                </div>
            );
        } else {
            return null;
        }


    }
}

export default withRouter(confirm);
