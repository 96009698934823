import React, {Component} from 'react';
import {API_ROOT, DOMAIN} from "../api-config";
import {Redirect, withRouter} from 'react-router-dom'
import ReactPlaceholder from "react-placeholder";
import {RectShape} from "react-placeholder/lib/placeholders";
import Modal from "react-responsive-modal";
import Steps from './steps';
import SubSteps from './substeps';
import Swal from "sweetalert2";
import $ from "jquery";
import axios from "axios";
import payform from 'payform';


class eventpayment extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            ready: true,
            open: false,
            swalButton: "",
            gatewayType: "W",
            total: "",
            paymentType: "",
            userFullPayment: "N",
            cardDate: '',
            show: false,
            paymentSubtotal: 0,
            paymentNCA: 0,
            paymentTax: 0,
            paymentTotal: 0,
            paymentTotalTax: 0,
            redirect: false,
            orderID: '',
            basePrice: 0,
            depositPrice: 0,
            totalTaxList: [],
            paymentOpen: false,
            link: ""


        };


    };

    componentWillUnmount() {
        // Remove the event listener when the component unmounts
        window.removeEventListener('message', this.handlePaymentResponse);
    }

    componentWillMount() {
        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-green',
                    cancelButton: 'btn-green'
                },
                buttonsStyling: false,
            })
        });

    }


    componentDidMount() {

        window.addEventListener('message', this.handlePaymentResponse);
        var etype = sessionStorage.getItem("eventType");
        var gatewayType = localStorage.getItem("merchant_gateway_type");
        if (etype !== "E") {
            this.setState({paymentType: "B"});
            this.setState({userFullPayment: "Y"});

            this.calculateBasePrice(true);

        } else {

            this.calculateBasePrice(false);
            this.calculateDeposit(false);
        }

        this.setState({gatewayType: gatewayType});
        var cardField = document.getElementById('paymentCardNumber');
        payform.cardNumberInput(cardField);


    }

    handlePaymentResponse = (event) => {
        // You should receive a message from the iframe indicating payment status
        const {data} = event;

        console.log("PAYMENT", data);
        if (data === 'payment_successful') {


            localStorage.setItem("waivers", "[]");
            this.setState({redirect: true});

        } else if (data === 'payment_declined') {

            this.state.swalButton.fire({
                title: "Payment Declined",
                allowOutsideClick: false
            });

            this.setState({paymentOpen: false});
        }
    };
    onChangePaymentType = (type) => {
        this.setState({paymentType: type});
        if (type === "B") {

            if (sessionStorage.getItem("eventPaymentType") === "D") {
                this.setState({userFullPayment: "Y"});
            }
            this.calculateBasePrice(true);
        } else {
            this.calculateDeposit(true);
        }
    }

    handleExpiry = e => {


        var dat = this.normalizeInput($(e.target).val(), this.state.cardDate);
        console.log("T " + dat);
        this.setState({cardDate: dat});
    };

    normalizeInput = (value, previousValue) => {
        if (!value) return value;
        var currentValue = value.replace(/[^\d]/g, '');
        var cvLength = currentValue.length;


        if (!previousValue || value.length > previousValue.length) {


            console.log("CURRENNT " + cvLength);

            if (cvLength < 2) return currentValue;

            console.log(currentValue.slice(0, 2) + " " + currentValue.slice(2));

            if (cvLength < 4) return (currentValue.slice(0, 2) + "/" + currentValue.slice(2));
            return currentValue.slice(0, 2) + "/" + currentValue.slice(2);
        }
    };

    calculateBasePrice = (flag1) => {


        var products = JSON.parse(sessionStorage.getItem("userAddons"));

        var productPrice = 0;
        for (var i = 0; i < products.length; i++) {

            if (parseInt(products[i].product_quantity) > 0) {

                productPrice += parseInt(products[i].product_quantity) * parseFloat(products[i].product_price);
            }
        }
        this.setState({productPrice: productPrice})

        console.log("Product Price" + productPrice);

        var extraGuest = parseInt(sessionStorage.getItem("userGuestCount")) - parseInt(sessionStorage.getItem("guestDefault"));
        var additionalGuestPrice = 0;

        if (extraGuest > 0) {

            additionalGuestPrice = extraGuest * parseFloat(sessionStorage.getItem("eventAdditionalGuestPrice"));
        }


        var subTotal = 0;

        var basePrice = 0;


        if (sessionStorage.getItem("eventType") !== "E") {
            basePrice = parseFloat(sessionStorage.getItem('eventBasePrice')) * parseInt(sessionStorage.getItem("userGuestCount"));
        } else {
            basePrice = parseFloat(sessionStorage.getItem('eventBasePrice')) + additionalGuestPrice;
        }


        subTotal = basePrice;

        var productPriceForTax = 0;

        for (var i = 0; i < products.length; i++) {

            if (parseInt(products[i].product_quantity) > 0) {

                if (products[i].product_tax_enabled === "Y") {

                    productPriceForTax += parseInt(products[i].product_quantity) * parseFloat(products[i].product_price);

                    for (var j = 0; j < products[i].taxes.length; j++) {

                        products[i].taxes[j].tax_amt = ((parseInt(products[i].product_quantity) * parseFloat(products[i].product_price)) * products[i].taxes[j].tax_value) / 100;
                    }
                }
            }
        }

        var subTotalForTax = subTotal;//+ productPriceForTax;
        var taxes = JSON.parse(sessionStorage.getItem("eventTaxes"));

        var tax = 0

        for (i = 0; i < taxes.length; i++) {

            taxes[i].tax_amt = (subTotalForTax * parseFloat(taxes[i].tax_value)) / 100;

            tax += (subTotalForTax * parseFloat(taxes[i].tax_value)) / 100;
            console.log("A " + ((subTotalForTax * parseFloat(taxes[i].tax_value)) / 100));

        }

        for (var i = 0; i < products.length; i++) {

            if (parseInt(products[i].product_quantity) > 0) {

                if (products[i].product_tax_enabled === "Y") {


                    for (var j = 0; j < products[i].taxes.length; j++) {


                        var flag = false;

                        for (var k = 0; k < taxes.length; k++) {

                            if (taxes[k].tax_id == products[i].taxes[j].tax_id) {

                                taxes[k].tax_amt = parseFloat(taxes[k].tax_amt) + parseFloat(((parseFloat(products[i].product_price) * parseInt(products[i].product_quantity) * parseFloat(products[i].taxes[j].tax_value)) / 100).toFixed(2));
                                flag = true;
                                break;

                            }
                        }
                        if (!flag) {


                            taxes.push({
                                tax_id: products[i].taxes[j].tax_id,
                                tax_name: products[i].taxes[j].tax_name,
                                tax_value: products[i].taxes[j].tax_value,
                                tax_amt: ((parseFloat(products[i].product_price) * parseInt(products[i].product_quantity) * parseFloat(products[i].taxes[j].tax_value)) / 100).toFixed(2)
                            });
                        }
                    }

                }
            }
        }


        this.setState({totalTaxList: taxes});


        tax = 0;
        for (i = 0; i < taxes.length; i++) {


            tax += parseFloat(taxes[i].tax_amt);


        }

        subTotal = subTotal + productPrice;


        var nca = 0;


        if (sessionStorage.getItem("merchantNCAEnabled") === 'Y') {


            if (sessionStorage.getItem("merchantNCAAmountEnabled") === 'Y') {

                //grandTotal += parseFloat(success.data.merchant_data[0].merchant_nca);

                nca = parseFloat(sessionStorage.getItem("merchantNCA"));


            } else {


                nca = ((subTotal + tax) * parseFloat(sessionStorage.getItem("merchantNCA"))) / 100;


            }

        }


        if (flag1) {
            this.setState({productPrice: productPrice});
            this.setState({paymentSubtotal: subTotal});
            this.setState({paymentTax: taxes});
            this.setState({paymentTotalTax: tax});
            this.setState({paymentNCA: nca});
            this.setState({paymentTotal: (subTotal + tax + nca)})


        }

        this.setState({basePrice: subTotal + tax + nca});


    }

    calculateDeposit = (flag1) => {
        var products = JSON.parse(sessionStorage.getItem("userAddons"));

        var productPrice = 0;
        for (var i = 0; i < products.length; i++) {

            if (parseInt(products[i].product_quantity) > 0) {

                productPrice += parseInt(products[i].product_quantity) * parseFloat(products[i].product_price);
            }
        }


        var extraGuest = parseInt(sessionStorage.getItem("userGuestCount")) - parseInt(sessionStorage.getItem("guestDefault"));
        var additionalGuestPrice = 0;

        if (extraGuest > 0) {

            additionalGuestPrice = extraGuest * parseFloat(sessionStorage.getItem("eventAdditionalGuestPrice"));
        }


        var addOnCharge = sessionStorage.getItem("eventAddonChargeEnabled");
        var eventDepositAmountEnabled = sessionStorage.getItem("eventDepositAmountEnabled");

        var subTotal = 0;
        var deposit = 0;

        if (addOnCharge == "Y") {

            if (eventDepositAmountEnabled === "Y") {
                deposit = parseFloat(sessionStorage.getItem('eventDeposit'));
            } else {
                deposit = ((parseFloat(sessionStorage.getItem("eventBasePrice")) * parseFloat(sessionStorage.getItem('eventDeposit'))) / 100);
            }

        } else {

            if (eventDepositAmountEnabled === "Y") {
                deposit = parseFloat(sessionStorage.getItem('eventDeposit'));
            } else {
                deposit = ((parseFloat(sessionStorage.getItem("eventBasePrice")) * parseFloat(sessionStorage.getItem('eventDeposit'))) / 100);
            }


        }

        subTotal = deposit;
        var productPriceForTax = 0;

        for (var i = 0; i < products.length; i++) {

            if (parseInt(products[i].product_quantity) > 0) {

                if (products[i].product_tax_enabled === "Y") {

                    productPriceForTax += parseInt(products[i].product_quantity) * parseFloat(products[i].product_price);

                    for (var j = 0; j < products[i].taxes.length; j++) {

                        products[i].taxes[j].tax_amt = ((parseInt(products[i].product_quantity) * parseFloat(products[i].product_price)) * products[i].taxes[j].tax_value) / 100;
                    }
                }
            }
        }

        var subTotalForTax = subTotal;//+ productPriceForTax;
        var taxes = JSON.parse(sessionStorage.getItem("eventTaxes"));

        var tax = 0

        for (i = 0; i < taxes.length; i++) {

            taxes[i].tax_amt = (subTotalForTax * parseFloat(taxes[i].tax_value)) / 100;

            tax += (subTotalForTax * parseFloat(taxes[i].tax_value)) / 100;
            console.log("A " + ((subTotalForTax * parseFloat(taxes[i].tax_value)) / 100));

        }


        if (addOnCharge === "Y") {

            for (var i = 0; i < products.length; i++) {

                if (parseInt(products[i].product_quantity) > 0) {

                    if (products[i].product_tax_enabled === "Y") {


                        for (var j = 0; j < products[i].taxes.length; j++) {


                            var flag = false;

                            for (var k = 0; k < taxes.length; k++) {

                                if (taxes[k].tax_id == products[i].taxes[j].tax_id) {

                                    taxes[k].tax_amt = parseFloat(taxes[k].tax_amt) + parseFloat(((parseFloat(products[i].product_price) * parseInt(products[i].product_quantity) * parseFloat(products[i].taxes[j].tax_value)) / 100).toFixed(2));
                                    flag = true;
                                    break;

                                }
                            }
                            if (!flag) {


                                taxes.push({
                                    tax_id: products[i].taxes[j].tax_id,
                                    tax_name: products[i].taxes[j].tax_name,
                                    tax_value: products[i].taxes[j].tax_value,
                                    tax_amt: ((parseFloat(products[i].product_price) * parseInt(products[i].product_quantity) * parseFloat(products[i].taxes[j].tax_value)) / 100).toFixed(2)
                                });
                            }
                        }

                    }
                }
            }
//            subTotal = subTotal + productPrice;
        }

        tax = 0;
        for (i = 0; i < taxes.length; i++) {


            tax += parseFloat(taxes[i].tax_amt);


        }


        if (addOnCharge === "Y") {
            this.setState({productPrice: productPrice});
            subTotal = subTotal + productPrice;
        } else {
            this.setState({productPrice: 0});
        }


        var nca = 0;


        if (sessionStorage.getItem("merchantNCAEnabled") === 'Y') {


            if (sessionStorage.getItem("merchantNCAAmountEnabled") === 'Y') {

                //grandTotal += parseFloat(success.data.merchant_data[0].merchant_nca);

                nca = parseFloat(sessionStorage.getItem("merchantNCA"));


            } else {


                nca = ((subTotal + tax) * parseFloat(sessionStorage.getItem("merchantNCA"))) / 100;


            }

        }


        if (flag1) {

            this.setState({paymentSubtotal: subTotal});
            this.setState({paymentTax: taxes});
            this.setState({paymentTotalTax: tax});
            this.setState({paymentNCA: nca});
            this.setState({paymentTotal: (subTotal + tax + nca)})
        }

        this.setState({depositPrice: subTotal + tax + nca});

    }


    payment = e => {

        var paymentCardNumber = "";
        var paymentCardName = "";
        var paymentExpiry = "";
        var paymentCVV = "";
        var paymentAddress = "";
        var paymentCity = "";
        var paymentState = "";
        var paymentZip = "";


        var n = -1;
        if (this.state.gatewayType == "W") {

            paymentCardNumber = $('#paymentCardNumber').val();
            paymentCardName = $('#paymentCardName').val();
            paymentExpiry = $('#paymentExpiry').val();
            paymentCVV = $('#paymentCVV').val();
            paymentAddress = $('#paymentAddress').val();

            paymentCity = $('#paymentCity').val();
            paymentState = $('#paymentState').val();
            paymentZip = $('#paymentZip').val();

            n = paymentExpiry.search(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/i);
        }

        var flag = true;


        if (this.state.paymentType === "") {
            this.state.swalButton.fire({
                title: 'Please select payment type.',
                allowOutsideClick: false
            });

            flag = false;
            return;
        } else if (this.state.gatewayType == "W" && paymentCardNumber.trim().length <= 0) {

            this.state.swalButton.fire({
                title: 'Please enter card number.',
                allowOutsideClick: false
            });
            flag = false;
            return;
        } else if (this.state.gatewayType == "W" && paymentExpiry.trim().length <= 0) {
            this.state.swalButton.fire({
                title: 'Please enter expiry.',
                allowOutsideClick: false
            });
            flag = false;
            return;
        } else if (this.state.gatewayType == "W" && n < 0) {
            this.state.swalButton.fire({
                title: 'Please enter expiry in MM/DD format.',
                allowOutsideClick: false
            });
            flag = false;
            return;

        } else if (this.state.gatewayType == "W" && paymentCVV.trim().length <= 0) {
            this.state.swalButton.fire({
                title: 'Please enter CVV.',
                allowOutsideClick: false
            });
            flag = false;
            return;
        } else if (this.state.gatewayType == "W" && paymentCardName.trim().length <= 0) {
            this.state.swalButton.fire({
                title: 'Please enter card name.',
                allowOutsideClick: false
            });
            flag = false;
            return;
        } else if (this.state.gatewayType == "W" && paymentAddress.trim().length <= 0) {
            this.state.swalButton.fire({
                title: 'Please enter address.',
                allowOutsideClick: false
            });
            flag = false;
            return;
        } else if (this.state.gatewayType == "W" && paymentCity.trim().length <= 0) {
            this.state.swalButton.fire({
                title: 'Please enter city.',
                allowOutsideClick: false
            });
            flag = false;
            return;
        } else if (this.state.gatewayType == "W" && paymentState.trim().length <= 0) {
            this.state.swalButton.fire({
                title: 'Please enter state.',
                allowOutsideClick: false
            });
            flag = false;
            return;
        } else if (this.state.gatewayType == "W" && paymentZip.trim().length <= 0) {
            this.state.swalButton.fire({
                title: 'Please enter zipcode.',
                allowOutsideClick: false
            });
            flag = false;
            return;
        }


        if (flag) {
            this.onOpenModal();

            var cardDate = ["", ""];
            var cardType = "";
            if (this.state.gatewayType == "W") {
                cardDate = paymentExpiry.split('/');
                cardType = payform.parseCardType(paymentCardNumber.toUpperCase());
            }

            var questions = [];
            if (sessionStorage.getItem("userQuestions")) {
                questions = JSON.parse(sessionStorage.getItem("userQuestions"));

                for (var i = 0; i < questions.length; i++) {
                    var answer = "";

                    if (questions[i].question_type === "R") {

                        for (var j = 0; j < questions[i].question_options.length; j++) {

                            if (questions[i].question_options[j].option_selected === "Y") {

                                questions[i].question_answer = questions[i].question_options[j].option_name;
                                break;
                            }
                        }


                    } else if (questions[i].question_type === "C") {

                        for (var j = 0; j < questions[i].question_options.length; j++) {

                            if (questions[i].question_options[j].option_selected === "Y") {

                                if (j == 0) {
                                    questions[i].question_answer += questions[i].question_options[j].option_name;
                                } else {
                                    questions[i].question_answer += "," + questions[i].question_options[j].option_name;
                                }

                            }
                        }


                    }
                }
            }


            axios.post(API_ROOT + '/createorder', {


                event_id: sessionStorage.getItem('eventID'),
                user_id: localStorage.getItem('user_id'),
                adults: sessionStorage.getItem('userGuestCount'),
                children: 0,
                comment: "",
                booking_at: sessionStorage.getItem('userEventDate'),
                booking_from: sessionStorage.getItem('userEventTime').split('-')[0].trim(),
                booking_to: sessionStorage.getItem('userEventTime').split('-')[1].trim(),
                products: JSON.parse(sessionStorage.getItem('userAddons')),
                fullPayment: this.state.userFullPayment,
                payment_product_amount: this.state.productPrice,
                order_pay_balance_new: 'Y',
                eventPaymentType: this.state.paymentType,
                eventQuestions: questions,
                amount: this.state.paymentSubtotal.toFixed(2),
                eventType: sessionStorage.getItem("eventType"),
                nca: this.state.paymentNCA.toFixed(2),
                tax: this.state.paymentTax,
                total: this.state.paymentTotal.toFixed(2),
                totalTax: this.state.paymentTotalTax.toFixed(2),
                payment_type: 'C',
                merchant_id: localStorage.getItem("merchant_id"),
                totalTaxList: this.state.totalTaxList,
                cardNo: paymentCardNumber,
                cardName: paymentCardName,
                cardMonth: cardDate[0],
                cardYear: cardDate[1],
                cardCvc: paymentCVV,
                cardStreet: paymentAddress,
                cardCity: paymentCity,
                cardState: paymentState,
                cardZip: paymentZip,
                cardType: cardType,
                routingNo: '',
                accountNo: '',
                paymentType: sessionStorage.getItem('eventPaymentType'),
                paymentFull: this.state.userFullPayment,
                bankName: '',
                remainingAmount: parseFloat(this.state.basePrice),
                orderEventAmount: sessionStorage.getItem('eventBasePrice'),
                orderAditionalAmount: parseFloat(sessionStorage.getItem("eventAdditionalGuestPrice")),
                orderAditionalGuest: ((parseInt(sessionStorage.getItem("userGuestCount")) - parseInt(sessionStorage.getItem("guestDefault")) > 0) ? (parseInt(sessionStorage.getItem("userGuestCount")) - parseInt(sessionStorage.getItem("guestDefault"))) : 0),
                waivers: localStorage.getItem('merchant_liability_waiver_enabled') === "Y" ? localStorage.getItem('waivers') : null,
                gatewayType: this.state.gatewayType

            })
                .then(response => {

                    this.onCloseModal();

                    if (response.data.status == 1) {
                        console.log(response.data);
                        if (this.state.gatewayType == "I") {
                            /**/
                            var specificURL = response.data.link;

                            // specificURL='http://localhost:16000/orders/success?responseCode=200&responseMessage=Success&transactionType=1&transactionReferenceId=84534059860236403745&amount=530&customFee=0&transactionNumber=000004&batchNumber=185&cardType=VISA&cardLast4Digit=5655&tips=0&localTax=&stateTax=&responseApprovalCode=TAS239&RRN=334712500685&totalAmount=530&transactionId=35141908467872507920231213173032';

                            this.setState({orderID: response.data.orderId});
                            this.setState({link: specificURL});
                            this.setState({paymentOpen: true});
                        } else {

                            //  sessionStorage.setItem("userFullPayment", this.state.userFullPayment);
                            this.setState({orderID: response.data.orderId});
                            localStorage.setItem("waivers", "[]");
                            this.setState({redirect: true});
                        }


                    } else {
                        this.state.swalButton.fire({
                            title: response.data.message,
                            allowOutsideClick: false
                        });

                    }
                }).catch(error => {
                this.onCloseModal();
                console.log(error);
            });

        }

    }

    moveForward = () => {


        this.props.onComponentUp(2);

    }
    goBack = () => {
        this.props.onComponentDown(2);
    }
    changeComponent = (name) => {
        console.log(name);
        this.props.onComponentChange(name, "details");

    }


    onOpenModal = () => {
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({open: false});
    };


    render() {


        const {open} = this.state;

        if (this.state.redirect) {
            return <Redirect to={"/receipt/" + this.state.orderID}/>;
        }
        const rightPlaceholder = (
            <div className='my-awesome-placeholder'>
                <RectShape color='whitesmoke' className="img-fluid" style={{width: "100%", height: "642px"}}/>

            </div>
        );

        return (<ReactPlaceholder ready={this.state.ready}
                                  customPlaceholder={rightPlaceholder}>
            <Steps componentChange={this.props.onComponentChange}/>
            <SubSteps/>
            <div className="title_div mb-30">
                <h1>Payment Information</h1>
            </div>
            {!this.state.paymentOpen ? <div className="addons_block_main">
                <div className="guest_block time_block clearfix w-80" id="style-1">

                    <p className="roboto-regular font-16">Choose Amount to Pay :</p>
                    <form>
                        {sessionStorage.getItem("eventPaymentType") === "D" ?


                            <div className="radiobtn radiobtn_confirm">
                                <input type="radio" id="deposit"
                                       name="drone" checked={this.state.paymentType === "D"}
                                       onChange={() => this.onChangePaymentType("D")} value="deposit"/>
                                <label htmlFor="deposit">Pay Deposit Amount <span
                                    className="amount">${this.state.depositPrice.toFixed(2)}</span></label>

                            </div> : ''
                        }

                        {(sessionStorage.getItem("eventPaymentType") === "D" && sessionStorage.getItem("eventPayInFullEnabled") === "Y") || (sessionStorage.getItem("eventPaymentType") === "B") ?
                            <div className="radiobtn radiobtn_confirm">
                                <input type="radio" checked={this.state.paymentType === "B"}
                                       onChange={() => this.onChangePaymentType("B")} id="amount"
                                       name="drone" value="amount"/>
                                <label htmlFor="amount">Pay Full Amount <span
                                    className="amount">${(this.state.basePrice).toFixed(2)}</span></label>
                            </div> : ''}


                        {this.state.gatewayType == "W" ? <div>
                            <div className="blue-title bg-blue border" style={{marginTop: "20px"}}>
                                Credit / Debit Card
                            </div>

                            <div className="form-group">
                                <div className="col-md-12">
                                    <label>Card Number</label>
                                    <input type="text" maxLength="19" id="paymentCardNumber" className="form-control"
                                           placeholder="- - - -   - - - -   - - - -   - - - -"/>
                                </div>
                            </div>
                            <div className="form-group w-50" style={{display: "inline-block"}}>
                                <div className="col-md-12">
                                    <label>Expiration Date</label>
                                    <input type="text" maxLength="5" id="paymentExpiry" onChange={this.handleExpiry}
                                           value={this.state.cardDate || ''} className="form-control text-center"
                                           placeholder="MM/DD"/>
                                </div>
                            </div>
                            <div className="form-group w-50" style={{display: "inline-block"}}>
                                <div className="col-md-12">
                                    <label>CVV</label>
                                    <input type="number" id="paymentCVV" className="form-control text-center" max="4"
                                           name="" placeholder="- - -"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label>Name on Card</label>
                                    <input type="text" placeholder="Type Name on Card" className="form-control"
                                           id="paymentCardName"/>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label>Billing Street Address</label>
                                    <input type="text" className="form-control font-robotolightitalic"
                                           id="paymentAddress"
                                           placeholder="Type Street Address"/>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-md-12">
                                    <label>Billing City</label>
                                    <input type="text" className="form-control font-robotolightitalic"
                                           placeholder="Type City Name" id="paymentCity"/>
                                </div>
                            </div>
                            <div className="form-group w-50 float-left">
                                <div className="col-md-12">
                                    <label>Billing State</label>
                                    <input type="text" className="form-control font-robotolightitalic"
                                           placeholder="Type State" id="paymentState"/>
                                </div>
                            </div>
                            <div className="form-group w-50 float-right">
                                <div className="col-md-12">
                                    <label>Billing Zip Code</label>
                                    <input type="text" className="form-control font-robotolightitalic"
                                           placeholder="Type Zipcode" id="paymentZip"/>
                                </div>
                            </div>
                        </div> : ""}

                    </form>


                </div>
            </div> : <div className="addons_block_main">
                <div className="guest_block time_block clearfix w-80" id="style-1" style={{overflow:"visible"}}>

                    <iframe
                        src={this.state.link}
                        title="Payment Gateway"
                        width="650px"
                        height="600px"
                    ></iframe>
                </div>
            </div>}
            {!this.state.paymentOpen ? <div className="btn-green-block clearfix">
                <a onClick={this.goBack} className="go_back_link float-left">Go Back</a>
                <a onClick={this.payment}
                   className="btn-green font-nunito-semibold float-right">Pay {this.state.paymentTotal > 0 ? "$" + this.state.paymentTotal.toFixed(2) : ''} </a>
            </div> : ""}


            <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                   onClose={this.onCloseModal} center>
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;Processing Payment...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                <p style={{textAlign: 'center'}}>
                    <img width={'40'} height={'40'} alt="Loading..." src={'/img/loading1.gif'}/>
                </p>
            </Modal>
        </ReactPlaceholder>)

    }
}

export default withRouter(eventpayment);
