import React, {Component} from 'react';
import {API_ROOT, DOMAIN} from "../api-config";
import {Link, Redirect, withRouter} from "react-router-dom";
import $ from "jquery";
import Topbar from './topbar';
import Footer from './footer';
import 'react-responsive-modal/styles.css';
import Swal from "sweetalert2";
import Modal from "react-responsive-modal";
import axios from "axios";

class login extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            redirect: false,
            redirect404: false,
            load: false,
            paymentredirect: false,
            swalButton: "",
            open: false,
        };

    };

    componentWillMount() {
        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-green',
                    cancelButton: 'btn-green'
                },
                buttonsStyling: false,
            })
        });

        if (localStorage.getItem('user_id')) {
            this.setState({redirect: true});
        }

    }

    componentDidMount() {
        sessionStorage.clear();
        console.log('in did mount');

        if (!localStorage.getItem('merchant_id')) {
            this.fetchMerchant();
        } else {

            document.title = "Login - " + localStorage.getItem("merchant_name");
            this.setState({load: true});
            var user_id = localStorage.getItem('user_id');
            var event_id = sessionStorage.getItem('eventID');

            if (user_id && event_id) {
                this.setState({paymentredirect: true});
            }

        }


    }


    fetchMerchant = () => {

        var url = API_ROOT + '/fetchmerchant';


        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(merchantData => {

                if (merchantData.status == 1 && merchantData.merchant_data.length > 0) {

                    this.setState({load: true});
                    document.title = "Login - " + merchantData.merchant_data[0].merchant_name;

                    localStorage.setItem('merchant_gateway_type', merchantData.merchant_data[0].merchant_gateway_type);
                    localStorage.setItem('merchant_id', merchantData.merchant_data[0].merchant_id);
                    localStorage.setItem('merchant_image', merchantData.merchant_data[0].merchant_image);
                    localStorage.setItem('merchant_phone', merchantData.merchant_data[0].merchant_phone);
                    localStorage.setItem('merchant_name', merchantData.merchant_data[0].merchant_name);
                    localStorage.setItem('merchant_default_page', merchantData.merchant_data[0].merchant_default_page);
                    localStorage.setItem('merchant_liability_waiver_enabled', merchantData.merchant_data[0].merchant_liability_waiver_enabled);
                    localStorage.setItem('merchant_liability_waiver_text', merchantData.merchant_data[0].merchant_liability_waiver_text);

                } else {

                    this.setState({redirect: false});
                    this.setState({redirect404: true});
                }
            });
    };


    onOpenModal = () => {
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({open: false});
    };


    login = e => {
        this.onOpenModal();

        var user_email = $('#user_email').val();

        var user_password = $('#user_password').val();
        var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        if (user_email === "") {
            this.state.swalButton.fire({
                title: 'Please enter email.',
                allowOutsideClick: false
            });

            return;
        } else if (!pattern.test(user_email)) {

            this.state.swalButton.fire({title: 'Please provide a valid email.', allowOutsideClick: false});
            return;

        } else if (user_password === "") {
            this.state.swalButton.fire({
                title: 'Please enter password.',
                allowOutsideClick: false
            });

            return;
        }


        this.onOpenModal();
        axios.post(API_ROOT + '/login', {
            email: user_email,
            password: user_password,
            merchant_id: localStorage.getItem("merchant_id"),

        })
            .then(response => {

                this.onCloseModal();

                if (response.data.status == 1) {


                    localStorage.setItem('user_id', response.data.user_data[0].user_id);
                    localStorage.setItem('user_name', response.data.user_data[0].user_firstname + " " + response.data.user_data[0].user_lastname);
                    localStorage.setItem('user_email', response.data.user_data[0].user_email);
                    var event_id = sessionStorage.getItem('eventID');
                    if (event_id) {
                        this.setState({paymentredirect: true});
                    } else {
                        this.setState({redirect: true});
                    }

                } else {
                    this.state.swalButton.fire({
                        title: response.data.message,
                        allowOutsideClick: false
                    });

                }
            }).catch(error => {
            this.onCloseModal();
            console.log(error);
        });


    }


    render() {

        const {open} = this.state;
        if (this.state.redirect) {

            var redirectMyAccount=localStorage.getItem("redirectMyAccount");

            if(redirectMyAccount){
                return <Redirect to="/myaccount"/>;

            }else {

                return <Redirect to="/"/>;
            }
        }
        if (this.state.redirect404) {
            return <Redirect to="/404"/>;
        }
        if (this.state.load) {
            return (<div>
                    <Topbar/>
                    <div class="clearfix"></div>
                    <div class="page">
                        <div class="container eventContainer bg-grey">

                            <div className="login_main">
                                <div className="login-block bg-white">
                                    <div className="login-inner clearfix">
                                        <form className="loginForm" name="loginForm" method="post" action="#"
                                              encType="multipart/form-data">
                                            <div className="blue-title bg-blue border">
                                                Login Here
                                            </div>
                                            <div className="input-group mt-3 mb-4">
                                                <div className="input-group-append">
                                                    <span className="input-group-text"><img src="/img/email-icon.png"
                                                                                            width="18" height="15"
                                                                                            alt="Email"/> </span>
                                                </div>
                                                <input type="email" id="user_email" className="form-control input_user"
                                                       placeholder="Email"/>
                                            </div>
                                            <div className="input-group mb-4">
                                                <div className="input-group-append">
                                                    <span className="input-group-text"><img src="/img/lock-icon.png"
                                                                                            width="18" height="24"
                                                                                            alt="Password"/></span>
                                                </div>
                                                <input type="password" id="user_password"
                                                       className="form-control input_pswd"
                                                       placeholder="Password"/>
                                            </div>
                                            <div
                                                 className="form-group mb-4 clearfix ml-2 mr-2">
                                                <div style={{display:"none"}} className="custom-control custom-checkbox float-left">
                                                    <input type="checkbox" className="custom-control-input"
                                                           id="customControlInline"/>
                                                    <label className="custom-control-label"
                                                           htmlFor="customControlInline">Remember Me</label>
                                                </div>
                                                <Link to="/forgotpassword" className="text_link float-right">Forgot Password ?</Link>
                                            </div>
                                            <div className="form-group mb-30 mt-1">
                                                <input type="button" className="btn-green m-auto" onClick={this.login}
                                                       value="Login"/>
                                            </div>
                                            <div className="create_account_link text-center mb-2">
                                                <Link to="/signup" className="text_link">No Account ? Sign Up
                                                    Here</Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                           onClose={this.onCloseModal} center>
                        <h2>&nbsp;&nbsp;&nbsp;&nbsp;Signing up...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                        <p style={{textAlign: 'center'}}>
                            <img width={'40'} height={'40'} alt="Loading..." src={'/img/loading1.gif'}/>
                        </p>
                    </Modal>
                    <Footer/>
                </div>
            );
        } else {
            return null;
        }

    }
}

export default withRouter(login);
