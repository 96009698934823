import React, {Component} from 'react';
import {DOMAIN} from "../api-config";
import ReactPlaceholder from "react-placeholder";
import {RectShape} from "react-placeholder/lib/placeholders";
import Modal from "react-responsive-modal";
import Steps from './steps';
import SubSteps from './substeps';
import Swal from "sweetalert2";
import moment from "moment-timezone";
import {withRouter} from "react-router-dom";


class eventconfirm extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            ready: true,
            open: false,
            swalButton: "",
            subTotal: 0,
            show: false,
            taxes: [],
            nca: 0,
            extraGuest: 0,
            tax: 0,

        };


    };

    componentWillMount() {
        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-green',
                    cancelButton: 'btn-green'
                },
                buttonsStyling: false,
            })
        });

    }


    componentDidMount() {


        var products = JSON.parse(sessionStorage.getItem("userAddons"));

        var productPrice = 0;
        for (var i = 0; i < products.length; i++) {

            if (parseInt(products[i].product_quantity) > 0) {

                productPrice += parseInt(products[i].product_quantity) * parseFloat(products[i].product_price);
            }
        }

        console.log("Product Price" + productPrice);

        var extraGuest = 0;

        if (sessionStorage.getItem("eventType") === "E") {
            extraGuest = parseInt(sessionStorage.getItem("userGuestCount")) - parseInt(sessionStorage.getItem("guestDefault"));
        }

        var additionalGuestPrice = 0;

        if (extraGuest > 0) {

            additionalGuestPrice = extraGuest * parseFloat(sessionStorage.getItem("eventAdditionalGuestPrice"));

        }
        this.setState({extraGuest: extraGuest});


        var fullPayEnabled = sessionStorage.getItem("eventPayInFullEnabled");
        var addOnCharge = sessionStorage.getItem("eventAddonChargeEnabled");
        var eventDepositAmountEnabled = sessionStorage.getItem("eventDepositAmountEnabled");
        var paymentType = sessionStorage.getItem("eventPaymentType");
        var subTotal = 0;

        if (paymentType == "D") {
            if (addOnCharge == "Y") {

                if (eventDepositAmountEnabled === "Y") {
                    subTotal = parseFloat(sessionStorage.getItem('eventDeposit'));
                } else {
                    subTotal = ((parseFloat(sessionStorage.getItem("eventBasePrice")) * parseFloat(sessionStorage.getItem('eventDeposit'))) / 100);
                }

            } else {

                if (eventDepositAmountEnabled === "Y") {
                    subTotal = parseFloat(sessionStorage.getItem('eventDeposit'));
                } else {
                    subTotal = ((parseFloat(sessionStorage.getItem("eventBasePrice")) * parseFloat(sessionStorage.getItem('eventDeposit'))) / 100);
                }


            }


        } else {

            if (sessionStorage.getItem("eventType") === "E") {
                subTotal = parseFloat(sessionStorage.getItem('eventBasePrice')) + additionalGuestPrice;
            } else {
                subTotal = parseFloat(sessionStorage.getItem('eventBasePrice')) * parseInt(sessionStorage.getItem("userGuestCount"));
            }
        }


        var productPriceForTax = 0;

        for (var i = 0; i < products.length; i++) {

            if (parseInt(products[i].product_quantity) > 0) {

                if (products[i].product_tax_enabled === "Y") {

                    productPriceForTax += parseInt(products[i].product_quantity) * parseFloat(products[i].product_price);

                    for (var j = 0; j < products[i].taxes.length; j++) {

                        products[i].taxes[j].tax_amt = ((parseInt(products[i].product_quantity) * parseFloat(products[i].product_price)) * products[i].taxes[j].tax_value) / 100;
                    }
                }
            }

        }
        var subTotalForTax = 0;


        subTotalForTax = subTotal;


        var taxes = JSON.parse(sessionStorage.getItem("eventTaxes"));

        var tax = 0


        for (var i = 0; i < taxes.length; i++) {

            taxes[i].tax_amt = (subTotalForTax * parseFloat(taxes[i].tax_value)) / 100;

            tax += (subTotalForTax * parseFloat(taxes[i].tax_value)) / 100;
            console.log("A " + ((subTotalForTax * parseFloat(taxes[i].tax_value)) / 100));

        }


        if (paymentType == "D") {
            if (addOnCharge == "Y") {


                for (var i = 0; i < products.length; i++) {

                    if (parseInt(products[i].product_quantity) > 0) {

                        if (products[i].product_tax_enabled === "Y") {


                            for (var j = 0; j < products[i].taxes.length; j++) {


                                var flag = false;

                                for (var k = 0; k < taxes.length; k++) {

                                    if (taxes[k].tax_id == products[i].taxes[j].tax_id) {

                                        taxes[k].tax_amt = parseFloat(taxes[k].tax_amt) + parseFloat(((parseFloat(products[i].product_price) * parseInt(products[i].product_quantity) * parseFloat(products[i].taxes[j].tax_value)) / 100).toFixed(2));
                                        flag = true;
                                        break;

                                    }
                                }
                                if (!flag) {


                                    taxes.push({
                                        tax_id: products[i].taxes[j].tax_id,
                                        tax_name: products[i].taxes[j].tax_name,
                                        tax_value: products[i].taxes[j].tax_value,
                                        tax_amt: ((parseFloat(products[i].product_price) * parseInt(products[i].product_quantity) * parseFloat(products[i].taxes[j].tax_value)) / 100).toFixed(2)
                                    });
                                }
                            }

                        }
                    }
                }
            }


        } else {
            for (var i = 0; i < products.length; i++) {

                if (parseInt(products[i].product_quantity) > 0) {

                    if (products[i].product_tax_enabled === "Y") {


                        for (var j = 0; j < products[i].taxes.length; j++) {


                            var flag = false;

                            for (var k = 0; k < taxes.length; k++) {

                                if (taxes[k].tax_id == products[i].taxes[j].tax_id) {


                                    taxes[k].tax_amt = parseFloat(taxes[k].tax_amt) + parseFloat(((parseFloat(products[i].product_price) * parseInt(products[i].product_quantity) * parseFloat(products[i].taxes[j].tax_value)) / 100).toFixed(2));
                                    flag = true;
                                    break;

                                }
                            }
                            if (!flag) {


                                taxes.push({
                                    tax_id: products[i].taxes[j].tax_id,
                                    tax_name: products[i].taxes[j].tax_name,
                                    tax_value: products[i].taxes[j].tax_value,
                                    tax_amt: ((parseFloat(products[i].product_price) * parseInt(products[i].product_quantity) * parseFloat(products[i].taxes[j].tax_value)) / 100).toFixed(2)
                                });
                            }
                        }

                    }
                }
            }
        }

        tax = 0;
        for (i = 0; i < taxes.length; i++) {


            tax += parseFloat(taxes[i].tax_amt);


        }



        this.setState({tax: tax});
        this.setState({taxes: taxes});


        if (paymentType === "D") {
            if (addOnCharge === "Y") {

                subTotal = subTotal + productPrice;
            }
        } else {
            subTotal = subTotal + productPrice;
        }


        var nca = 0;


        if (sessionStorage.getItem("merchantNCAEnabled") === 'Y') {


            if (sessionStorage.getItem("merchantNCAAmountEnabled") === 'Y') {

                //grandTotal += parseFloat(success.data.merchant_data[0].merchant_nca);

                nca = parseFloat(sessionStorage.getItem("merchantNCA"));


            } else {


                nca = ((subTotal+tax) * parseFloat(sessionStorage.getItem("merchantNCA"))) / 100;


            }

        }
        this.setState({nca: nca});


        this.setState({subTotal: (subTotal)});
    }


    moveForward = () => {


        this.props.onComponentUp(2);

    }
    goBack = () => {
        this.props.onComponentDown(2);
    }
    changeComponent = (name) => {
        console.log(name);
        this.props.onComponentChange(name, "details");

    }


    onOpenModal = () => {
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({open: false});
    };


    render() {


        const {open} = this.state;
        const rightPlaceholder = (
            <div className='my-awesome-placeholder'>
                <RectShape color='whitesmoke' className="img-fluid" style={{width: "100%", height: "642px"}}/>

            </div>
        );

        return (<ReactPlaceholder ready={this.state.ready}
                                  customPlaceholder={rightPlaceholder}>
            <Steps componentChange={this.props.onComponentChange}/>
            <SubSteps/>
            <div className="title_div mb-30">
                <h1>Confirm Party Details</h1>
            </div>
            <div className="addons_block_main">
                <div className="guest_block addons_block clearfix w-80" id="style-1">
                    <div className="confirmed_detail_block clearfix">
                        <div className="confirmed_title">
                            <div className="d_left">
                                <h3>Items</h3>
                            </div>
                            <div className="d_right">
                                <h3>Price</h3>
                            </div>
                        </div>
                        <div className="confirmed_content_main">
                            <div className="confirmed_content mb-3 clearfix position-relative">
                                <div className="d_left">
                                    <p className="roboto-bold pl-20"> {sessionStorage.getItem("eventName")}</p>
                                </div>
                                <div className="d_right">
                                    {(sessionStorage.getItem("eventType") === "E") ?
                                        <p className="font-16 roboto-bold text-right">{sessionStorage.getItem("eventPaymentType") == "D" ?sessionStorage.getItem("eventDepositAmountEnabled") == "Y" ? "$" + parseFloat(sessionStorage.getItem("eventDeposit")).toFixed(2): "$" + ((parseFloat(sessionStorage.getItem("eventDeposit"))*parseFloat(sessionStorage.getItem("eventBasePrice")))/100).toFixed(2): "$" + parseFloat(sessionStorage.getItem("eventBasePrice")).toFixed(2)}</p> :
                                        <p className="font-16 roboto-bold text-right">${(parseFloat(sessionStorage.getItem("eventBasePrice")) * parseInt(sessionStorage.getItem("userGuestCount"))).toFixed(2)}</p>}
                                </div>
                            </div>
                            <div className="confirmed_content mb-3 clearfix position-relative">
                                <div className="d_left">
                                    <p className="pl-20">{(sessionStorage.getItem("eventType") === "E") ?
                                        <a onClick={() => this.changeComponent("date")}
                                           href="#"> <span
                                            className="pencil-icon"></span></a> : ''}
                                        <span className="roboto-bold">
                                            {moment(sessionStorage.getItem("userEventDate"), "YYYY-MM-DD").format("MMMM, DD,YYYY")}
                                        </span></p>
                                </div>
                                <div className="d_right">
                                    <p className="font-16 roboto-bold text-right"></p>
                                </div>
                            </div>
                            <div className="confirmed_content mb-3 clearfix position-relative">
                                <div className="d_left">
                                    <p className="pl-20">{(sessionStorage.getItem("eventType") === "E") ?
                                        <a onClick={() => this.changeComponent("time")}
                                           href="#"> <span
                                            className="pencil-icon"></span></a> : ''}
                                        <span className="roboto-bold">
                                            {sessionStorage.getItem("userEventTime")}
                                        </span></p>
                                </div>
                                <div className="d_right">
                                    <p className="font-16 roboto-bold text-right"></p>
                                </div>
                            </div>
                            <div className="confirmed_content mb-3 clearfix position-relative">
                                <div className="d_left">
                                    <p className="pl-20"><a onClick={() =>


                                        this.changeComponent("guest")}
                                                            href="#"> <span
                                        className="pencil-icon"></span></a>
                                        <span className="roboto-bold">
                                            {sessionStorage.getItem("userGuestCount")}{(sessionStorage.getItem("eventType") === "E") ? "Guests" : "Tickets"}<br/>
                                            {this.state.extraGuest > 0 ? this.state.extraGuest + " Additional Guest" : ''}


                                        </span></p>
                                </div>
                                <div className="d_right">
                                    <p className="font-16 roboto-bold text-right"> {this.state.extraGuest > 0 ? "$" + (parseFloat(this.state.extraGuest) * (parseFloat(sessionStorage.getItem("eventAdditionalGuestPrice")))).toFixed(2) + "" : ''}</p>
                                </div>
                            </div>

                            {JSON.parse(sessionStorage.getItem("eventIncludes")).map((item, index) => {
                                return (<div className="confirmed_content clearfix">
                                    <div className="d_left">
                                        <p><span className="img"><img
                                            src="/img/check-icon.png" className="mr-1" width="16"
                                            height="14" alt="Confirmed"/></span> <span>{item}</span>
                                        </p>
                                    </div>
                                    <div className="d_right">
                                        <p className="font-14 text-right">Included</p>
                                    </div>
                                </div>)

                            })}
                            {JSON.parse(sessionStorage.getItem("userAddons")).map((item, index) => {

                                if (parseInt(item.product_quantity) > 0) {
                                    return (
                                        <div className="confirmed_content mb-3 clearfix position-relative">
                                            <div className="d_left">
                                                <p className="pl-20"><a
                                                    onClick={() => this.changeComponent("addons")}
                                                    href="#"> <span className="pencil-icon"></span></a>
                                                    <span>{item.product_name}</span>

                                                    {(parseInt(item.product_quantity) > 1) ?
                                                        <span
                                                            className="roboto-bold ml-50">({item.product_quantity}x)</span> : ''}
                                                </p>
                                            </div>
                                            <div className="d_right">
                                                <p className="font-16 roboto-bold text-right">${(parseInt(item.product_quantity) * parseFloat(item.product_price)).toFixed(2)}</p>
                                            </div>
                                        </div>)
                                }
                            })}
                        </div>
                        <div className="confirmed_title subtotal_title mb-2">
                            <div className="d_left">
                                <h3>Subtotal</h3>
                            </div>
                            <div className="d_right">
                                <h3>${this.state.subTotal.toFixed(2)}</h3>
                            </div>
                        </div>
                        {this.state.taxes.map((item, index) => {
                            return (<div className="confirmed_content mb-0 clearfix p-2">
                                <div className="d_left">
                                    <p className="font-14">{item.tax_name} ({item.tax_value}%)</p>
                                </div>
                                <div className="d_right">
                                    <p className="font-16 text-right">${parseFloat(item.tax_amt).toFixed(2)}</p>
                                </div>
                            </div>)
                        })}

                        {sessionStorage.getItem("merchantNCAEnabled") === "Y" ?
                            <div className="confirmed_content mb-0 clearfix p-2">
                                <div className="d_left">
                                    <p className="font-14">{sessionStorage.getItem("merchantNCAName")}</p>
                                </div>
                                <div className="d_right">
                                    <p className="font-16 text-right">${this.state.nca.toFixed(2)}</p>
                                </div>
                            </div> : ''}
                        <div className="confirmed_title subtotal_title bg-yellow">
                            <div className="d_left">
                                <h3>Total</h3>
                            </div>
                            <div className="d_right">
                                <h3>${(this.state.subTotal + this.state.tax + this.state.nca).toFixed(2)}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="btn-green-block clearfix">
                <a onClick={this.goBack} className="go_back_link float-left">Go Back</a>
                <a onClick={this.moveForward}
                   className="btn-green font-nunito-semibold float-right">Continue </a>
            </div>


            <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                   onClose={this.onCloseModal} center>
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;Loading...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                <p style={{textAlign: 'center'}}>
                    <img width={'40'} alt="Loading..." height={'40'} src={'/img/loading1.gif'}/>
                </p>
            </Modal>
        </ReactPlaceholder>)

    }
}

export default withRouter(eventconfirm);