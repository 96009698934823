import React, {Component} from 'react';
import {API_ROOT, DOMAIN, IMAGE_ROOT} from "../api-config";
import {Link, Redirect, withRouter} from "react-router-dom";
import Topbar from './topbar';
import Footer from './footer';
import EventDate from './eventdate';
import GuestCount from './guestcount';
import TicketCount from './ticketcount';
import EventTime from './eventtime';
import EventAddons from './eventaddons';
import EventLogin from './eventlogin';
import EventInfo from './eventinfo';
import EventConfirm from './eventconfirm';
import EventPayment from './eventpayment';
import SignWaiver from './signwaiver';
import 'react-responsive-modal/styles.css';
import Swal from "sweetalert2";
import Modal from "react-responsive-modal";
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import {RectShape, TextBlock} from 'react-placeholder/lib/placeholders';
import moment from "moment-timezone";
import $ from "jquery";
import base64 from 'base-64';

class eventdetails extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            redirect: false,
            redirect404: false,
            notfound: false,
            load: false,
            eventid: "",
            includes: [],
            eventname: "",
            ready: false,
            swalButton: "",
            details: this.props.match.params.details,
            open: false,
            componentList: [],
            from: "",
            currentComponent: 0,

        };

    };

    componentWillMount() {
        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-green',
                    cancelButton: 'btn-green'
                },
                buttonsStyling: false,
            })
        });

        this.setState({
            guidelineDialog: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-green',
                },
                buttonsStyling: false,
            })
        });


        /*if(sessionStorage.getItem("eventQuestions")!=="[]") {
            this.state.steps.push("info");
        }
        if(user_id) {

        }else{
            this.state.steps.push("login");
        }
        this.state.steps.push("details");
        this.state.steps.push("payment");*/

        var details = base64.decode(this.state.details);

        details = details.split("&&");


        if (details.length > 0) {


            sessionStorage.setItem("eventID", details[0]);
            sessionStorage.setItem("eventName", details[1]);
            sessionStorage.setItem("eventType", details[2]);

            if (details[2] !== "E") {
                sessionStorage.setItem("userEventDate", details[3]);
                sessionStorage.setItem("userEventTime", details[4]);
            }


            this.setState({eventid: sessionStorage.getItem("eventID")});
            this.setState({eventname: sessionStorage.getItem("eventName")});
            if (!sessionStorage.getItem("eventID")) {
                this.setState({redirect: true});
            }
            if (sessionStorage.getItem("currentComponent")) {
                this.setState({currentComponent: parseInt(sessionStorage.getItem("currentComponent"))});
            }
            localStorage.removeItem("waivers");
        } else {
            this.setState({notfound: true});
        }

    }

    componentDidMount() {

        if (!localStorage.getItem('merchant_id')) {
            this.fetchMerchant();
        } else {

            this.fetchEventDetails();
        }


    }

    fetchMerchant = () => {

        var url = API_ROOT + '/fetchmerchant';

        this.setState({loading: true});
        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(merchantData => {

                if (merchantData.status == 1 && merchantData.merchant_data.length > 0) {

                    this.setState({load: true});
                    document.title = "Signup - " + merchantData.merchant_data[0].merchant_name;

                    localStorage.setItem('merchant_gateway_type', merchantData.merchant_data[0].merchant_gateway_type);
                    localStorage.setItem('merchant_id', merchantData.merchant_data[0].merchant_id);
                    localStorage.setItem('merchant_image', merchantData.merchant_data[0].merchant_image);
                    localStorage.setItem('merchant_phone', merchantData.merchant_data[0].merchant_phone);
                    localStorage.setItem('merchant_name', merchantData.merchant_data[0].merchant_name);
                    localStorage.setItem('merchant_default_page', merchantData.merchant_data[0].merchant_default_page);
                    localStorage.setItem('merchant_liability_waiver_enabled', merchantData.merchant_data[0].merchant_liability_waiver_enabled);
                    localStorage.setItem('merchant_liability_waiver_text', merchantData.merchant_data[0].merchant_liability_waiver_text);

                    $(".custom-select").each(function () {
                        $(this).wrap("<span class='select-wrapper'></span>");
                        $(this).after("<span class='holder'></span>");
                    });
                    $(".custom-select").change(function () {
                        var selectedOption = $(this).find(":selected").text();
                        $(this).next(".holder").text(selectedOption);
                    }).trigger('change');

                    this.fetchEventDetails();


                } else {

                    this.setState({redirect: false});
                    this.setState({redirect404: true});
                }
            });
    };

    componentUP = (cid) => {

        var dis = this;
        var asyncSessionStorage = {
            setItem: function (key, value) {
                return Promise.resolve().then(function () {
                    sessionStorage.setItem(key, value);
                });
            },
        };

        if (this.state.from === "") {
            var curcomponent = this.state.currentComponent;

            curcomponent = curcomponent + 1;

            asyncSessionStorage.setItem("currentComponent", curcomponent).then(function () {


                dis.setState({currentComponent: curcomponent});


                var maxItem = sessionStorage.getItem("maxTraverseComponent");

                if (maxItem) {

                    if (curcomponent > parseInt(maxItem)) {
                        asyncSessionStorage.setItem("maxTraverseComponent", curcomponent);
                    }
                } else {
                    asyncSessionStorage.setItem("maxTraverseComponent", curcomponent);
                }


            });

        } else {


            var progress = sessionStorage.getItem("progress").split(",");


            var curcomponent = progress.indexOf(this.state.from);
            console.log(curcomponent + " " + this.state.from);

            asyncSessionStorage.setItem("currentComponent", curcomponent).then(function () {


                dis.setState({from: ""});
                dis.setState({currentComponent: curcomponent});

            });

        }

        console.log(cid);

    }


    componentChange = (cmp, from) => {
        var asyncSessionStorage = {
            setItem: function (key, value) {
                return Promise.resolve().then(function () {
                    sessionStorage.setItem(key, value);
                });
            },
        };

        console.log("From " + from);
        this.setState({from: from});

        var progress = sessionStorage.getItem("progress").split(",");


        var curcomponent = progress.indexOf(cmp);
        var dis = this;
        asyncSessionStorage.setItem("currentComponent", curcomponent).then(function () {


            dis.setState({currentComponent: curcomponent});

        });

    };

    componentDown = (cid) => {


        var asyncSessionStorage = {
            setItem: function (key, value) {
                return Promise.resolve().then(function () {
                    sessionStorage.setItem(key, value);
                });
            },
        };
        console.log("TRUE");

        if (this.state.from === "") {
            var curcomponent = this.state.currentComponent;
            curcomponent = curcomponent - 1;
            var dis = this;
            asyncSessionStorage.setItem("currentComponent", curcomponent).then(function () {
                dis.setState({currentComponent: curcomponent});
            });
        } else {

            var progress = sessionStorage.getItem("progress").split(",");


            var curcomponent = progress.indexOf(this.state.from);
            var dis = this;
            asyncSessionStorage.setItem("currentComponent", curcomponent).then(function () {


                dis.setState({from: ""});
                dis.setState({currentComponent: curcomponent});

            });
        }


    }

    fetchEventDetails = () => {
        var url = API_ROOT + '/fetcheventdetails?event_id=' + this.state.eventid + "&merchant_id=" + localStorage.getItem("merchant_id");

        this.onOpenModal();
        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(events => {


                if (events.status == 1) {


                    var eventData = events.event_data[0];


                    document.title = eventData.event_title + " - " + localStorage.getItem("merchant_name");
                    sessionStorage.setItem('eventAddonsEnabled', eventData.event_addons_enabled);

                    sessionStorage.setItem('eventName', eventData.event_title);
                    sessionStorage.setItem('eventBasePrice', eventData.event_base_price);
                    sessionStorage.setItem('eventInfo', eventData.event_info);
                    sessionStorage.setItem('eventAdditionalGuestPrice', eventData.event_additional_guest_fee);
                    sessionStorage.setItem('eventMaxGuest', eventData.event_guest_max);

                    sessionStorage.setItem("merchantNCAEnabled", eventData.merchant_nca_enabled);
                    sessionStorage.setItem("merchantNCAName", eventData.merchant_nca_name);
                    sessionStorage.setItem("merchantNCA", eventData.merchant_nca);
                    sessionStorage.setItem("merchantNCAAmountEnabled", eventData.merchant_nca_amount_enabled);


                    sessionStorage.setItem('eventTaxes', JSON.stringify(eventData.event_taxes));

                    if (eventData.event_products.length == 0) {
                        sessionStorage.setItem('userAddons', "[]");
                    }
                    if (eventData.event_questions.length == 0) {
                        sessionStorage.setItem('userQuestions', "[]");
                    }
                    sessionStorage.setItem('eventProducts', JSON.stringify(eventData.event_products));

                    if (eventData.event_includes_multiple.length > 0) {

                        var includes = [];

                        for (var i = 0; i < eventData.event_includes_multiple.length; i++) {
                            includes.push(eventData.event_includes_multiple[i].event_text);
                        }
                        sessionStorage.setItem('eventIncludes', JSON.stringify(includes));
                        this.setState({includes: includes});
                    } else {

                        if (eventData.event_includes != "") {


                            var includes = [];
                            includes.push(eventData.event_includes);


                            /*for (var i = 0; i < test.length; i++) {
                                console.log("A" + test[i] + "B");
                                if (test[i] !== "") {
                                    includes.push(test[i]);
                                }
                            }*/

                            if (includes.length > 0) {

                                sessionStorage.setItem('eventIncludes', JSON.stringify(includes));
                                this.setState({includes: includes});
                            } else {
                                sessionStorage.setItem('eventIncludes', "[]");
                                this.setState({includes: []});
                            }

                        } else {

                            sessionStorage.setItem('eventIncludes', "[]");
                            this.setState({includes: []});
                        }

                    }

                    sessionStorage.setItem('eventImg', eventData.event_image);
                    sessionStorage.setItem('guestMin', eventData.event_guest_min);
                    sessionStorage.setItem('guestMax', eventData.event_guest_max);
                    sessionStorage.setItem('guestDefault', eventData.event_guest_default);
                    sessionStorage.setItem('eventAllowedParties', eventData.event_allowed_parties);
                    sessionStorage.setItem('eventDepositAmountEnabled', eventData.event_deposit_amount_enabled);
                    sessionStorage.setItem('eventDeposit', eventData.event_deposit);
                    sessionStorage.setItem('eventPaymentType', eventData.event_payment_type);
                    sessionStorage.setItem('eventPayInFullEnabled', eventData.event_pay_in_full_enabled);
                    sessionStorage.setItem('eventQuestions', eventData.event_questions);


                    sessionStorage.setItem('merchant_social_distance_guidelines', eventData.merchant_social_distance_guidelines);

                    if (eventData.event_payment_type == "B") {
                        sessionStorage.setItem('eventAddonChargeEnabled', "Y");
                    } else {
                        sessionStorage.setItem('eventAddonChargeEnabled', eventData.event_addon_charge_enabled);
                    }


                    var steps = [];
                    steps.push("guest");
                    if (sessionStorage.getItem("eventType") === "E") {
                        this.state.componentList.push(<GuestCount onComponentChange={this.componentChange}
                                                                  onComponentUp={this.componentUP}
                                                                  onComponentDown={this.componentDown}/>);
                    } else {
                        this.state.componentList.push(<TicketCount onComponentChange={this.componentChange}
                                                                   onComponentUp={this.componentUP}
                                                                   onComponentDown={this.componentDown}/>);
                    }

                    if (sessionStorage.getItem("eventType") === "E") {
                        steps.push("date");

                        this.state.componentList.push(<EventDate onComponentChange={this.componentChange}
                                                                 onComponentUp={this.componentUP}
                                                                 onComponentDown={this.componentDown}/>);
                        steps.push("time");
                        this.state.componentList.push(<EventTime onComponentChange={this.componentChange}
                                                                 onComponentUp={this.componentUP}
                                                                 onComponentDown={this.componentDown}/>);
                    }

                    if (sessionStorage.getItem("eventAddonsEnabled") === "Y") {
                        steps.push("addons");
                        this.state.componentList.push(<EventAddons onComponentChange={this.componentChange}
                                                                   onComponentUp={this.componentUP}
                                                                   onComponentDown={this.componentDown}/>);
                    }
                    if (sessionStorage.getItem("eventQuestions") !== "[]") {

                        steps.push("info");
                        this.state.componentList.push(<EventInfo onComponentChange={this.componentChange}
                                                                 onComponentUp={this.componentUP}
                                                                 onComponentDown={this.componentDown}/>);
                    }
                    if (!localStorage.getItem("user_id")) {
                        steps.push("login");
                        this.state.componentList.push(<EventLogin onComponentChange={this.componentChange}
                                                                  onComponentUp={this.componentUP}
                                                                  onComponentDown={this.componentDown}/>);
                    }
                    if (localStorage.getItem('merchant_liability_waiver_enabled') === "Y") {
                        steps.push("waiver");
                        this.state.componentList.push(<SignWaiver onComponentChange={this.componentChange}
                                                                  onComponentUp={this.componentUP}
                                                                  onComponentDown={this.componentDown}/>);
                    }
                    steps.push("details");
                    this.state.componentList.push(<EventConfirm onComponentChange={this.componentChange}
                                                                onComponentUp={this.componentUP}
                                                                onComponentDown={this.componentDown}/>);

                    steps.push("payment");

                    this.state.componentList.push(<EventPayment onComponentChange={this.componentChange}
                                                                onComponentUp={this.componentUP}
                                                                onComponentDown={this.componentDown}/>);


                    var currcompo = sessionStorage.getItem("currentComponent");
                    if (currcompo) {


                        var currcomponentname = sessionStorage.getItem("currentComponentName");
                        if (currcomponentname !== "login") {
                            console.log("HARSHIT " + currcomponentname);
                            sessionStorage.setItem("currentComponent", steps.indexOf(currcomponentname));
                            this.setState({currentComponent: steps.indexOf(currcomponentname)});
                        }
                    }


                    sessionStorage.setItem("progress", steps);

                    var dis = this;
                    setTimeout(function () {
                        dis.setState({ready: true});
                    }, 500);
                    this.onCloseModal();

                    var q1 = sessionStorage.getItem('merchant_social_distance_guidelines');

                    if (q1 != "" && !sessionStorage.getItem("guideline")) {

                        this.state.guidelineDialog.fire({
                            title: '<h4 id="guidelineHeading">' + localStorage.getItem("merchant_name") + "</h4>",
                            allowOutsideClick: false,
                            showCloseButton: false,
                            showCancelButton: false,
                            showConfirmButton: false,
                            html: "<h5 id='guidelineSubtext'>Due to COVID-19 we are practicing social distancing." +
                                "Please read our Social Distancing Guidelines below</h5><hr/><div id='guideLine'>" + sessionStorage.getItem("merchant_social_distance_guidelines") + "</div><hr/> <button type='button' " +
                                " id='btnUnderstand' class='guidelineButton font-nunito-semibold'>I Understand </button>",

                        });
                    }

                    $('#btnUnderstand').click(() => {


                        sessionStorage.setItem("guideline", "Y");
                        this.state.guidelineDialog.close();
                    });

                } else {
                    this.setState({notfound: true});
                }

            });

    };


    onOpenModal = () => {
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({open: false});
    };


    render() {

        const {open} = this.state;
        if (this.state.redirect) {
            return <Redirect to="/"/>;
        }

        if (!sessionStorage.getItem("eventID")) {
            return <Redirect to="/"/>;
        }

        if (this.state.redirect404) {
            return <Redirect to="/404"/>
        }
        if (this.state.notfound) {
            return <Redirect to="/notfound"/>;
        }

        const leftPlaceholder = (
            <div className='my-awesome-placeholder'>
                <RectShape color='whitesmoke' className="img-fluid" style={{width: "100%", height: "130px"}}/>
                <TextBlock rows={16} color='whitesmoke' style={{padding: "10px"}}/>
                <RectShape color='whitesmoke' className="img-fluid" style={{width: "100%", height: "96px"}}/>
            </div>
        );
        const admissionPlaceholder = (
            <div className='my-awesome-placeholder'>
                <RectShape color='whitesmoke' className="img-fluid" style={{width: "100%", height: "130px"}}/>
                <TextBlock rows={5} color='whitesmoke' style={{padding: "10px"}}/>
                <RectShape color='whitesmoke' className="img-fluid" style={{width: "100%", height: "43px"}}/>
            </div>
        );


        return (<div>
                <Topbar/>

                <div className="clearfix"></div>
                <div className="page">
                    <div className="container bg-grey">
                        <div className="page-middle clearfix">
                            <div className="events-block-main col-md-12">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="breadcrumb-block d-flex justify-content-between">
                                            <ul className="my-auto">
                                                {(sessionStorage.getItem("eventType") === "E") ?
                                                    <li><Link to="/">Home</Link></li> :
                                                    <li><Link to="/admission">Buy Admission</Link></li>}
                                                <li>/</li>
                                                <li>{this.state.eventname}</li>
                                            </ul>
                                            <Link to="/admission" className="btn btn-red">← Back to Main</Link>
                                        </div>
                                    </div>

                                    {(sessionStorage.getItem("eventType") === "E") ?
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div
                                                className="events-block block-section position-relative sideEventDetails">
                                                <ReactPlaceholder ready={this.state.ready}
                                                                  customPlaceholder={leftPlaceholder}>

                                                    <div className="event-img">
                                                        <figure>
                                                            <div className="imageContainer">
                                                                {sessionStorage.getItem("eventImg") !== "" ?
                                                                    <img
                                                                        src={IMAGE_ROOT + "/images/product/" + sessionStorage.getItem("eventImg")}
                                                                        alt={sessionStorage.getItem("eventName")}
                                                                        className="img-fluid"/> : ''}
                                                            </div>
                                                            <figcaption>{sessionStorage.getItem("eventName")}</figcaption>
                                                        </figure>
                                                    </div>
                                                    <div className="event-body eventItemBottomPadding">

                                                        <div className="event-content">
                                                            <p style={{wordBreak: "break-all"}}>{sessionStorage.getItem("eventInfo")}</p>
                                                            <p>&nbsp;</p>
                                                        </div>
                                                        <p className="font-weight-medium mb-4 font-14">Up
                                                            to {sessionStorage.getItem("guestMax")} people</p>
                                                        <div className="line"></div>

                                                        {(this.state.includes.length > 0) ?
                                                            <p className="font-weight-medium mb-3 mt-3 font-14 text-uppercase">What
                                                                is
                                                                Included ?</p> : ''}
                                                        <ul className="eventIncludes">

                                                            {this.state.includes.map((item, index) => {

                                                                return (<li key={index}>{item}</li>);
                                                            })}
                                                        </ul>
                                                    </div>
                                                    <div className="event-book-now p-15 w-100 clearfix">

                                                        {(sessionStorage.getItem("eventPaymentType") == "D") ?
                                                            (sessionStorage.getItem("eventDepositAmountEnabled") == "Y") ?
                                                                <p className="roboto-bold mb-2 float-left">Deposit
                                                                    Amount
                                                                    : <span
                                                                        className="float-right"> ${sessionStorage.getItem("eventDeposit")}</span>
                                                                </p> :
                                                                <p className="roboto-bold mb-2 float-left">Deposit
                                                                    Amount
                                                                    : <span
                                                                        className="float-right"> ${((parseFloat(sessionStorage.getItem("eventDeposit")) * parseFloat(sessionStorage.getItem("eventBasePrice"))) / 100).toFixed(2)}</span>
                                                                </p> : ''}
                                                        {//(sessionStorage.getItem("eventPaymentType") !== "D") ?
                                                            <p>Base Price : <span
                                                                className="float-right">$ {sessionStorage.getItem("eventBasePrice")}</span>
                                                            </p> /*: ''*/}
                                                    </div>
                                                </ReactPlaceholder>
                                            </div>
                                        </div> :
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div
                                                className="events-block block-section position-relative sideEventDetails">
                                                <ReactPlaceholder ready={this.state.ready}
                                                                  customPlaceholder={admissionPlaceholder}>

                                                    <div className="event-img">
                                                        <figure>
                                                            {sessionStorage.getItem("eventImg") !== "" ?
                                                                <img
                                                                    src={IMAGE_ROOT + "/images/product/" + sessionStorage.getItem("eventImg")}
                                                                    alt={sessionStorage.getItem("eventName")}
                                                                    className="img-fluid"/> : ''}
                                                            <figcaption
                                                                className="text-left min-height-0 eventTitle">{sessionStorage.getItem("eventName")}
                                                            </figcaption>
                                                        </figure>
                                                    </div>
                                                    <div className="event-body  eventItemBottomPadding">
                                                        <div className="event-content">
                                                            <div className="time_info clearfix mb-3">
                                                                <p className="roboto-bold">{moment(sessionStorage.getItem("userEventDate")).format("MMMM DD, YYYY")}</p>
                                                                <br/>
                                                                <p className="roboto-bold">{sessionStorage.getItem("userEventTime")}</p>
                                                            </div>
                                                            <p className="mb-4"> {sessionStorage.getItem("eventInfo")}</p>

                                                            {(parseInt(sessionStorage.getItem("eventAllowedParties")) > 0) ?
                                                                <p className="mb-4 roboto-bold">{sessionStorage.getItem("eventAllowedParties")} persons
                                                                    capacity</p> : ''}

                                                        </div>
                                                        <ul className="admissionIncludes">


                                                        </ul>
                                                    </div>
                                                    <div className="event-book-now p-15 w-100 clearfix">

                                                        <p><i
                                                            className="icon-ticket"></i> 1 Ticket :
                                                            <span
                                                                className="float-right"> ${sessionStorage.getItem("eventBasePrice")}</span>
                                                        </p>


                                                    </div>
                                                </ReactPlaceholder>
                                            </div>
                                        </div>}
                                    <div className="col-md-8 col-sm-12 col-xs-12">

                                        <div
                                            className="events-block wizard mb-0 clearfix position-relative sideEventDetails">

                                            {this.state.ready ?
                                                this.state.componentList[this.state.currentComponent] : ''}
                                        </div>

                                        <div className="help_text text-center">
                                            <p>Need Help ? Call Us
                                                Now <span><a
                                                    href={"tel://" + localStorage.getItem("merchant_phone")}>{localStorage.getItem("merchant_phone")}</a></span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                       onClose={this.onCloseModal} center>
                    <h2>&nbsp;&nbsp;&nbsp;&nbsp;Loading...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                    <p style={{textAlign: 'center'}}>
                        <img width={'40'} height={'40'} alt="Loading..." src={'/img/loading1.gif'}/>
                    </p>
                </Modal>
                <Footer/>
            </div>
        );


    }
}

export default withRouter(eventdetails);
