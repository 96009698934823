import React, {Component} from 'react';
import {API_ROOT, DOMAIN, IMAGE_ROOT} from "../api-config";
import {Link, Redirect, withRouter} from "react-router-dom";
import Topbar from './topbar';
import Footer from './footer';
import 'react-responsive-modal/styles.css';
import Swal from "sweetalert2";
import Modal from "react-responsive-modal";
import "react-placeholder/lib/reactPlaceholder.css";
import $ from "jquery";
import axios from "axios";
import moment from "moment-timezone";
import memoizeOne from 'memoize-one';

import RemainingPayment from './remainingpayment';
import DataTable from 'react-data-table-component';
import BModal from "react-bootstrap/Modal";
import base64 from "base-64";


import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    HatenaIcon,
    HatenaShareButton,
    InstapaperIcon,
    InstapaperShareButton,
    LineIcon,
    LineShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    LivejournalIcon,
    LivejournalShareButton,
    MailruIcon,
    MailruShareButton,
    OKIcon,
    OKShareButton,
    PinterestIcon,
    PinterestShareButton,
    PocketIcon,
    PocketShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TumblrShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    VKIcon,
    VKShareButton,
    WeiboIcon,
    WeiboShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    WorkplaceIcon,
    WorkplaceShareButton,
} from 'react-share';
import Notifications, {notify} from "react-notify-toast";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const columns = memoizeOne(clickHandler => [
    {
        name: 'Payment\nDate',
        selector: 'date',
        sortable: true,
        width: "105",
        maxWidth: "105px",
        minWidth: "105px",
    },
    {
        name: 'Event Title',
        selector: 'event',
        sortable: false,
        minWidth: "60px"

    },
    {
        name: 'Payment\nAmount',
        selector: 'amount',
        width: "100",
        maxWidth: "100px",
        minWidth: "100px",
        sortable: false,

    },
    {
        name: 'Remaining\nBalance',
        selector: 'remaining',
        width: "100",
        maxWidth: "100px",
        minWidth: "100px",
        sortable: false,

    },
    {
        name: 'View\nBooking',
        selector: 'view',
        sortable: false,
        button: true,
        width: "80",
        maxWidth: "80px",
        minWidth: "80px",
        ignoreRowClick: true,
        cell: (row) => <button className="btn icon-external-link icon-2x" onClick={clickHandler} id={row.url}></button>



        /*
          cell: row => <a class="p-2" href="#" className="booking" data-ref={row.url}
                          rel="noopener noreferrer"><i style={{"textDecoration": "none"}}
                                                       className="icon-external-link icon-2x text_link"></i> </a>,

  */
    },
]);

class myaccount extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            redirect: false,
            redirect404: false,
            load: false,
            bookingData: [],
            phone: "",
            data: [],
            msg: "",
            swalButton: "",
            selectedRows: [],
            showReceipt: false,
            includes: [],
            paymentSubtotal: 0,
            paymentNCA: 0,
            exampleImage: '',
            paymentTax: 0,
            addonAmount: 0,
            paymenTotal: 0,
            paymentTime: "",
            specialElementHandlers: "",
            paymentTotalTax: 0,
            order_id: '',
            doc: '',
            shareUrl: '',
            title: '',
            show: false,
            showPayment: false,
            loadPayment: false,
            eventId: '',
            basePrice: 0,
            orderId: '',


        };


    };

    componentWillMount() {
        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-green',
                    cancelButton: 'btn-green'
                },
                buttonsStyling: false,
            })
        });

        this.setState({
            guidelineDialog: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-green',
                },
                buttonsStyling: false,
            })
        });


        if (!localStorage.getItem("user_id")) {
            localStorage.setItem("redirectMyAccount", "Y");
            this.setState({redirect: true});
        } else {
            localStorage.removeItem("redirectMyAccount");
        }


    }

    componentDidMount() {


        if (!localStorage.getItem('merchant_id')) {
            this.fetchMerchant();
        } else {

            this.fetchUserDetails();
        }
        document.title = "My Acccount - " + localStorage.getItem("merchant_name");


    }


    openReceipt = (state) => {
        console.log('clicked');


        var url = '';
        if (state != null) {

            url = API_ROOT + '/fetchbooking?reference=' + state.target.id;
            this.setState({order_id: state.target.id});
        } else {
            url = API_ROOT + '/fetchbooking?reference=' + this.state.order_id;
        }

        this.loadReceipt(url);


    };


    loadReceipt=(url)=>{


        this.onOpenModal("Fetching Booking Information");


        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(data => {

                if (data.status == 1) {
                    this.onCloseModal();
                    this.handleShowReceipt();
                    this.setState({data: data.event});


                    this.setState({load: true});

                    var eventID = data.event[0].event_id;


                    this.setState({orderId: data.event[0].order_id});

                    this.setState({eventId: eventID});


                    this.setState({basePrice: (this.state.data[0].order_event_amount - this.state.data[0].payment_amount)});

                    var eventName = data.event[0].event_title;
                    var eventType = data.event[0].event_type;
                    var userEventDate = moment(this.state.data[0].order_booking_at, "MM/DD/YYYY").format("YYYY-MM-DD");
                    var userEventTime = moment(this.state.data[0].order_booking_at + " " + this.state.data[0].order_duration_from, "MM/DD/YYYY HH:mm:ss").format("hh:mm A") + " - " + moment(this.state.data[0].order_booking_at + " " + this.state.data[0].order_duration_to, "MM/DD/YYYY HH:mm:ss").format("hh:mm A");

                    this.setState({paymentTime: userEventTime});


                    var addon = 0;

                    for (var i = 0; i < data.event[0].order_details.length; i++) {

                        addon += (parseFloat(data.event[0].order_details[i].product_price) * parseInt(data.event[0].order_details[i].product_quantity));
                    }

                    this.setState({addonAmount: addon.toFixed(2)});

                    var url = eventID + "&&" + eventName + "&&" + eventType + "&&" + userEventDate + "&&" + userEventTime;

                    url = base64.encode(url);


                    this.setState({shareUrl: window.location.origin + "/event/" + url});
                    this.setState({exampleImage: data.event[0].event_image});
                    if (data.event[0].includes.length > 0) {

                        var includes = [];

                        for (var i = 0; i < data.event[0].includes.length; i++) {
                            includes.push(data.event[0].includes[i].event_text);
                        }

                        this.setState({includes: includes});
                    } else {
                        if (data.event[0].event_includes != "") {

                            /* data.event[0].event_includes = data.event[0].event_includes.replaceAll("\n", "");
                             var test = data.event[0].event_includes.split(".");

                             var includes = [];
                             for (var i = 0; i < test.length; i++) {
                                 console.log("A" + test[i] + "B");
                                 if (test[i] !== "") {
                                     includes.push(test[i]);
                                 }
                             }*/
                            var includes = [];
                            includes.push(data.event[0].event_includes);

                            if (includes.length > 0) {

                                this.setState({includes: includes});
                            } else {

                                this.setState({includes: []});
                            }

                        } else {

                            this.setState({includes: []});
                        }

                    }

                    //


                } else {


                    this.state.swalButton.fire({
                        title: 'Booking not found.',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {


                        }
                    });

                }
            });

    };

    handleChange1 = (row, event) => {
        console.log(row.url);

        var url= url = API_ROOT + '/fetchbooking?reference=' + row.url;
        this.loadReceipt(url);
    }
    /*handleChange1 = state => {
        console.log('state', state.selectedRows);

        this.setState({selectedRows: state.selectedRows});
    };*/

    fetchMerchant = () => {

        var url = API_ROOT + '/fetchmerchant';

        this.setState({loading: true});
        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(merchantData => {

                if (merchantData.status == 1 && merchantData.merchant_data.length > 0) {

                    this.setState({load: true});
                    document.title = "My Account - " + merchantData.merchant_data[0].merchant_name;

                    localStorage.setItem('merchant_gateway_type', merchantData.merchant_data[0].merchant_gateway_type);
                    localStorage.setItem('merchant_id', merchantData.merchant_data[0].merchant_id);
                    localStorage.setItem('merchant_image', merchantData.merchant_data[0].merchant_image);
                    localStorage.setItem('merchant_phone', merchantData.merchant_data[0].merchant_phone);
                    localStorage.setItem('merchant_name', merchantData.merchant_data[0].merchant_name);
                    localStorage.setItem('merchant_default_page', merchantData.merchant_data[0].merchant_default_page);
                    localStorage.setItem('merchant_liability_waiver_enabled', merchantData.merchant_data[0].merchant_liability_waiver_enabled);
                    localStorage.setItem('merchant_liability_waiver_text', merchantData.merchant_data[0].merchant_liability_waiver_text);

                    this.fetchUserDetails();


                } else {

                    this.setState({redirect: false});
                    this.setState({redirect404: true});
                }
            });
    };

    fetchUserDetails = () => {

        this.onOpenModal("Loading");


        axios.post(API_ROOT + '/fetchuser', {
            user_id: localStorage.getItem("user_id"),
            timezone: moment.tz.guess(),
        })
            .then(response => {

                this.onCloseModal();

                if (response.data.status == 1) {

                    var data = response.data.data[0];
                    console.log(data);

                    if (response.data.data.length > 0) {
                        $('#user_type').val(data.customer_type);
                        $('#user_firstname').val(data.user_firstname);
                        $('#user_lastname').val(data.user_lastname);

                        if (data.user_dob != "" && data.user_dob != "0000-00-00") {
                            var dob = data.user_dob.split("-");
                            $('#user_year').val(dob[0]);
                            $('#user_month').val(dob[1]);
                            $('#user_day').val(dob[2]);
                        }


                        this.setState({phone: this.normalizeInput(data.user_phone, this.state.phone)});


                        var bookings = [];

                        for (var i = 0; i < data.bookings.length; i++) {
                            var book = {};

                            book.date = data.bookings[i].order_created_at;
                            book.event = data.bookings[i].event_title;
                            book.amount = "$" + data.bookings[i].total.toFixed(2);
                            book.remaining = "$" + Math.abs((data.bookings[i].order_remaining_amount + Math.abs(data.bookings[i].requested_total)) - data.bookings[i].total).toFixed(2);
                            book.url = data.bookings[i].payment_reference_number;
                            bookings.push(book);

                        }


                        this.setState({bookingData: bookings});

                    } else {
                        this.setState({redirect: false});
                    }

                    $(".custom-select").each(function () {
                        $(this).wrap("<span class='select-wrapper'></span>");
                        $(this).after("<span class='holder'></span>");
                    });
                    $(".custom-select").change(function () {
                        var selectedOption = $(this).find(":selected").text();
                        $(this).next(".holder").text(selectedOption);
                    }).trigger('change');

                } else {
                    this.state.swalButton.fire({
                        title: response.data.message,
                        allowOutsideClick: false
                    });

                }

                $('.booking').click(function () {


                });
            }).catch(error => {
            this.onCloseModal();
            console.log(error);
        });
    };


    saveUser = e => {


        var user_firstname = $('#user_firstname').val();
        var user_lastname = $('#user_lastname').val();
        var user_type = $('#user_type').val();
        var user_phone = $('#user_phone').val();
        var user_day = $('#user_day').val();
        var user_month = $('#user_month').val();
        var user_year = $('#user_year').val();

        if (user_type === "") {
            this.state.swalButton.fire({
                title: 'Please select customer type.',
                allowOutsideClick: false
            });

            return;
        } else if (user_firstname === "") {
            this.state.swalButton.fire({
                title: 'Please enter first name.',
                allowOutsideClick: false
            });

            return;
        }

        var dob = "";

        if (moment(user_year + "-" + user_month + "-" + user_day, 'YYYY-MM-DD', true).isValid()) {
            dob = user_year + "-" + user_month + "-" + user_day;
        }


        this.onOpenModal("Loading");
        axios.post(API_ROOT + '/saveuser', {
            customer_firstname: user_firstname,
            customer_lastname: user_lastname,
            customer_type: user_type,
            customer_phone: user_phone,
            customer_dob: dob,
            user_id: localStorage.getItem("user_id"),

        })
            .then(response => {

                this.onCloseModal();

                if (response.data.status == 1) {


                    localStorage.setItem("user_name", user_firstname + " " + user_lastname);

                    this.state.swalButton.fire({
                        title: "Account Information Saved Successfully.",
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {

                            this.setState({redirect: true});
                        }
                    });


                } else {
                    this.state.swalButton.fire({
                        title: response.data.message,
                        allowOutsideClick: false
                    });

                }
            }).catch(error => {
            this.onCloseModal();
            console.log(error);
        });


    }

    normalizeInput = (value, previousValue) => {
        if (!value) return value;
        var currentValue = value.replace(/[^\d]/g, '');
        var cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };

    handleChange = e => {

        this.setState({phone: this.normalizeInput($(e.target).val(), this.state.phone)});
    };

    copy = e => {
        let myColor = {background: '#0E1717', text: "#FFFFFF"};
        notify.show("Link copied to clipboard.", "custom", 2000, myColor);
        $('#bookingURL').select();
        navigator.clipboard.writeText(this.state.shareUrl);
        document.execCommand("copy")
    }
    print = (e) => {

        const input = document.getElementById('receipt');
        console.log(input.innerHTML);
        $('.event-img').css('display', 'none');
        $('#removeCount').css('display', 'none');
        $('#btnContainer').css('display', 'none');
        html2canvas(input)
            .then((canvas) => {
                $('.event-img').css('display', 'block');
                $('#removeCount').css('display', 'block');
                $('#btnContainer').css('display', 'block');

                const imgData = canvas.toDataURL('img/png');

                var myWindow = window.open("", "Image");
                myWindow.document.write("<center><img style='height:90vh' src='" + imgData + "''></center>");

                setTimeout(function () {
                    myWindow.print();
                }, 1000);

                this.onCloseModal();
            });
    }
    share = e => {
        this.handleShow();
    }
    handleShow = () => {
        this.setState({show: true});

    };
    handleClose = () => {
        this.setState({show: false});
    };

    downloadPDF = (e) => {


        const input = document.getElementById('receipt');


        this.onOpenModal("Generating PDF");

        $('.event-img').css('display', 'none');
        $('#removeCount').css('display', 'none');
        $('#btnContainer').css('display', 'none');
        html2canvas(input)
            .then((canvas) => {
                $('.event-img').css('display', 'block');
                $('#removeCount').css('display', 'block');
                $('#btnContainer').css('display', 'block');

                /* let imgWidth = 80;
                 let imgHeight = canvas.height * imgWidth / canvas.width;
                 const imgData = canvas.toDataURL('img/png');*/
                const pdf = new jsPDF('p', 'mm', 'a4');

                let imgh = canvas.height;
                let imgw = canvas.width;

                let res = imgw / imgh;

                const pageWidth = pdf.internal.pageSize.getWidth();
                const pageHeight = pdf.internal.pageSize.getHeight();


                let imgHeight = pageHeight - 50;
                let imgWidth = imgHeight * res;

                const imgData = canvas.toDataURL('img/png', .50);

                pdf.addImage(imgData, 'PNG', (pageWidth / 2) - (imgWidth / 2), 10, imgWidth, imgHeight, undefined, 'FAST');
                //  pdf.addImage(imgData, 'PNG', (pageWidth / 2) - (imgWidth / 2), 10, imgWidth, imgHeight);
                // pdf.output('dataurlnewwindow');
                pdf.save("Booking Confirmation.pdf");
                this.onCloseModal();
            })
        ;
    }


    onOpenModal = (msg) => {
        this.setState({msg: msg});
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({msg: ''});
        this.setState({open: false});
    };

    updateState = state => {
        this.setState({selectedRows: state.selectedRows});
    }

    handleShowReceipt = () => {
        this.setState({showReceipt: true});


    };
    handleCloseReceipt = () => {

        this.setState({showReceipt: false});


    };

    handleClosePayment = (type) => {
        this.setState({showPayment: false});


        if (type == "R") {
            this.fetchUserDetails();
        }

        this.openReceipt(null);

    }

    paybalance = () => {

        this.setState({showReceipt: false});
        this.setState({showPayment: true});
    }


    render() {

        const {open} = this.state;
        const {msg} = this.state;
        const {shareUrl} = this.state;
        const {title} = this.state;
        const {exampleImage} = this.state;
        const {showReceipt} = this.state;
        const {showPayment} = this.state;

        if (this.state.redirect) {
            return <Redirect to="/login"/>;
        }
        if (!localStorage.getItem("user_id")) {

            return <Redirect to="/login"/>;
        } else {

        }

        if (this.state.redirect404) {
            return <Redirect to="/404"/>;
        }


        return (<div>
                <Topbar/>

                <div className="clearfix"></div>
                <div className="page">
                    <div className="container bg-grey">
                        <div className="page-middle clearfix">
                            <div className="events-block-main col-md-12">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="breadcrumb-block clearfix">

                                        </div>
                                    </div>


                                    <div className="col-md-12 col-xl-5 col-sm-12 col-xs-12">

                                        {/*<div className="cardTitle"></div>*/}
                                        <div className="events-block wizard mb-5 clearfix position-relative">

                                            <div className="blue-title bg-blue">
                                                My Account Information
                                            </div>
                                            <div className="login-inner clearfix">
                                                <form className="RegisterForm" name="RegisterForm" method="post"
                                                      action="#"
                                                      encType="multipart/form-data">

                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label>Customer Type :</label>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <select id="user_type"
                                                                        className="form-control custom-select">
                                                                    <option value="">Select Type</option>
                                                                    <option value="I">Individual</option>
                                                                    <option value="C">Company</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label>First Name:</label>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <input type="texr" className="form-control"
                                                                       id="user_firstname"
                                                                       name="fname"
                                                                       placeholder="Type First Name Here"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label>Last Name:</label>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <input type="text" className="form-control"
                                                                       id="user_lastname"
                                                                       placeholder="Type Last Name Here"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label>Birthday :</label>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="b_month">

                                                                    <select id="user_month"
                                                                            className="form-control custom-select">
                                                                        <option>Month</option>
                                                                        <option value="01">January</option>
                                                                        <option value="02">Febuary</option>
                                                                        <option value="03">March</option>
                                                                        <option value="04">April</option>
                                                                        <option value="05">May</option>
                                                                        <option value="06">June</option>
                                                                        <option value="07">July</option>
                                                                        <option value="08">August</option>
                                                                        <option value="09">September</option>
                                                                        <option value="10">October</option>
                                                                        <option value="11">November</option>
                                                                        <option value="12">December</option>
                                                                    </select>
                                                                </div>
                                                                <div className="b_day">
                                                                    <select id="user_day"
                                                                            className="form-control custom-select">
                                                                        <option>Day</option>
                                                                        <option value="01">1</option>
                                                                        <option value="02">2</option>
                                                                        <option value="03">3</option>
                                                                        <option value="04">4</option>
                                                                        <option value="05">5</option>
                                                                        <option value="06">6</option>
                                                                        <option value="07">7</option>
                                                                        <option value="08">8</option>
                                                                        <option value="09">9</option>
                                                                        <option value="10">10</option>
                                                                        <option value="11">11</option>
                                                                        <option value="12">12</option>
                                                                        <option value="13">13</option>
                                                                        <option value="14">14</option>
                                                                        <option value="15">15</option>
                                                                        <option value="16">16</option>
                                                                        <option value="17">17</option>
                                                                        <option value="18">18</option>
                                                                        <option value="19">19</option>
                                                                        <option value="20">20</option>
                                                                        <option value="21">21</option>
                                                                        <option value="22">22</option>
                                                                        <option value="23">23</option>
                                                                        <option value="24">24</option>
                                                                        <option value="25">25</option>
                                                                        <option value="26">26</option>
                                                                        <option value="27">27</option>
                                                                        <option value="28">28</option>
                                                                        <option value="29">29</option>
                                                                        <option value="30">30</option>
                                                                        <option value="31">31</option>
                                                                    </select>
                                                                </div>
                                                                <div className="b_year">
                                                                    <select id="user_year"
                                                                            className="form-control custom-select">
                                                                        <option>Year</option>
                                                                        <option value="2018">2018</option>
                                                                        <option value="2017">2017</option>
                                                                        <option value="2016">2016</option>
                                                                        <option value="2015">2015</option>
                                                                        <option value="2014">2014</option>
                                                                        <option value="2013">2013</option>
                                                                        <option value="2012">2012</option>
                                                                        <option value="2011">2011</option>
                                                                        <option value="2010">2010</option>
                                                                        <option value="2009">2009</option>
                                                                        <option value="2008">2008</option>
                                                                        <option value="2007">2007</option>
                                                                        <option value="2006">2006</option>
                                                                        <option value="2005">2005</option>
                                                                        <option value="2004">2004</option>
                                                                        <option value="2003">2003</option>
                                                                        <option value="2002">2002</option>
                                                                        <option value="2001">2001</option>
                                                                        <option value="2000">2000</option>
                                                                        <option value="1999">1999</option>
                                                                        <option value="1998">1998</option>
                                                                        <option value="1997">1997</option>
                                                                        <option value="1996">1996</option>
                                                                        <option value="1995">1995</option>
                                                                        <option value="1994">1994</option>
                                                                        <option value="1993">1993</option>
                                                                        <option value="1992">1992</option>
                                                                        <option value="1991">1991</option>
                                                                        <option value="1990">1990</option>
                                                                        <option value="1989">1989</option>
                                                                        <option value="1988">1988</option>
                                                                        <option value="1987">1987</option>
                                                                        <option value="1986">1986</option>
                                                                        <option value="1985">1985</option>
                                                                        <option value="1984">1984</option>
                                                                        <option value="1983">1983</option>
                                                                        <option value="1982">1982</option>
                                                                        <option value="1981">1981</option>
                                                                        <option value="1980">1980</option>
                                                                        <option value="1979">1979</option>
                                                                        <option value="1978">1978</option>
                                                                        <option value="1977">1977</option>
                                                                        <option value="1976">1976</option>
                                                                        <option value="1975">1975</option>
                                                                        <option value="1974">1974</option>
                                                                        <option value="1973">1973</option>
                                                                        <option value="1972">1972</option>
                                                                        <option value="1971">1971</option>
                                                                        <option value="1970">1970</option>
                                                                        <option value="1969">1969</option>
                                                                        <option value="1968">1968</option>
                                                                        <option value="1967">1967</option>
                                                                        <option value="1966">1966</option>
                                                                        <option value="1965">1965</option>
                                                                        <option value="1964">1964</option>
                                                                        <option value="1963">1963</option>
                                                                        <option value="1962">1962</option>
                                                                        <option value="1961">1961</option>
                                                                        <option value="1960">1960</option>
                                                                        <option value="1959">1959</option>
                                                                        <option value="1958">1958</option>
                                                                        <option value="1957">1957</option>
                                                                        <option value="1956">1956</option>
                                                                        <option value="1955">1955</option>
                                                                        <option value="1954">1954</option>
                                                                        <option value="1953">1953</option>
                                                                        <option value="1952">1952</option>
                                                                        <option value="1951">1951</option>
                                                                        <option value="1950">1950</option>
                                                                        <option value="1949">1949</option>
                                                                        <option value="1948">1948</option>
                                                                        <option value="1947">1947</option>
                                                                        <option value="1946">1946</option>
                                                                        <option value="1945">1945</option>
                                                                        <option value="1944">1944</option>
                                                                        <option value="1943">1943</option>
                                                                        <option value="1942">1942</option>
                                                                        <option value="1941">1941</option>
                                                                        <option value="1940">1940</option>
                                                                        <option value="1939">1939</option>
                                                                        <option value="1938">1938</option>
                                                                        <option value="1937">1937</option>
                                                                        <option value="1936">1936</option>
                                                                        <option value="1935">1935</option>
                                                                        <option value="1934">1934</option>
                                                                        <option value="1933">1933</option>
                                                                        <option value="1932">1932</option>
                                                                        <option value="1931">1931</option>
                                                                        <option value="1930">1930</option>
                                                                        <option value="1929">1929</option>
                                                                        <option value="1928">1928</option>
                                                                        <option value="1927">1927</option>
                                                                        <option value="1926">1926</option>
                                                                        <option value="1925">1925</option>
                                                                        <option value="1924">1924</option>
                                                                        <option value="1923">1923</option>
                                                                        <option value="1922">1922</option>
                                                                        <option value="1921">1921</option>
                                                                        <option value="1920">1920</option>
                                                                        <option value="1919">1919</option>
                                                                        <option value="1918">1918</option>
                                                                        <option value="1917">1917</option>
                                                                        <option value="1916">1916</option>
                                                                        <option value="1915">1915</option>
                                                                        <option value="1914">1914</option>
                                                                        <option value="1913">1913</option>
                                                                        <option value="1912">1912</option>
                                                                        <option value="1911">1911</option>
                                                                        <option value="1910">1910</option>
                                                                        <option value="1909">1909</option>
                                                                        <option value="1908">1908</option>
                                                                        <option value="1907">1907</option>
                                                                        <option value="1906">1906</option>
                                                                        <option value="1905">1905</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label>Phone Number :</label>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <input value={this.state.phone || ''}
                                                                       onChange={this.handleChange} id="user_phone"
                                                                       type="phone"
                                                                       className="form-control"
                                                                       placeholder="(XXX) XXX-XXXX"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*  <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label>Email Address :</label>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <input type="email" id="user_email" className="form-control"
                                                                       placeholder="Type Email Address Here"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                         <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label>Confirm Email :</label>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <input type="email" id="user_confirm_email"
                                                                       className="form-control"
                                                                       placeholder="Type Email Address Again"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label>Password :</label>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <input type="password" id="user_password"
                                                                       className="form-control"
                                                                       placeholder="Type Password Here"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label>Confirm Password:</label>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <input type="password" id="user_confirm_password"
                                                                       className="form-control"
                                                                       placeholder="Type Password Again"/>
                                                            </div>
                                                        </div>
                                                    </div>*/}
                                                    <div className="form-group mb-30 mt-1">
                                                        <input type="button" id="btnSignup"
                                                               className="btn-green float-right"
                                                               value="Save" onClick={this.saveUser}/>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-xl-7 col-sm-12 col-xs-12">


                                        <div className="events-block wizard mb-5 clearfix position-relative">

                                            <div className="blue-title bg-blue" style={{padding: "7px"}}>My Order
                                                History <span
                                                    className="count roboto-medium ml-2">{this.state.bookingData.length}</span>
                                            </div>
                                            <DataTable
                                                responsive={true}
                                                striped={true}
                                                data={this.state.bookingData}
                                                theme="solarized"
                                                onRowClicked={this.handleChange1}
                                                columns={columns(this.openReceipt)}
                                                onRowSelected={this.handleChange1}

                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BModal show={this.state.show} onHide={this.handleClose}>

                    <BModal.Body>
                        <div className="login-inner clearfix">
                            <form className="loginForm" name="loginForm" method="post" action="#"
                                  encType="multipart/form-data">
                                <div className="blue-title bg-blue border">
                                    Share
                                </div>
                                <div className="input-group mb-4">
                                    <div className="Demo__some-network">
                                        <FacebookShareButton
                                            url={shareUrl}
                                            quote={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <FacebookIcon size={32} round/>
                                        </FacebookShareButton>


                                    </div>

                                    <div className="Demo__some-network">
                                        <FacebookMessengerShareButton
                                            url={shareUrl}
                                            appId="521270401588372"
                                            className="Demo__some-network__share-button">
                                            <FacebookMessengerIcon size={32} round/>
                                        </FacebookMessengerShareButton>
                                    </div>

                                    <div className="Demo__some-network">
                                        <TwitterShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <TwitterIcon size={32} round/>
                                        </TwitterShareButton>


                                    </div>

                                    <div className="Demo__some-network">
                                        <TelegramShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <TelegramIcon size={32} round/>
                                        </TelegramShareButton>


                                    </div>

                                    <div className="Demo__some-network">
                                        <WhatsappShareButton
                                            url={shareUrl}
                                            title={title}
                                            separator=":: "
                                            className="Demo__some-network__share-button"
                                        >
                                            <WhatsappIcon size={32} round/>
                                        </WhatsappShareButton>


                                    </div>

                                    <div className="Demo__some-network">
                                        <LinkedinShareButton url={shareUrl}
                                                             className="Demo__some-network__share-button">
                                            <LinkedinIcon size={32} round/>
                                        </LinkedinShareButton>
                                    </div>

                                    <div className="Demo__some-network">
                                        <PinterestShareButton
                                            url={String(window.location)}
                                            media={`${String(window.location)}/${exampleImage}`}
                                            className="Demo__some-network__share-button"
                                        >
                                            <PinterestIcon size={32} round/>
                                        </PinterestShareButton>


                                    </div>

                                    <div className="Demo__some-network">
                                        <VKShareButton
                                            url={shareUrl}
                                            image={`${String(window.location)}/${exampleImage}`}
                                            className="Demo__some-network__share-button"
                                        >
                                            <VKIcon size={32} round/>
                                        </VKShareButton>


                                    </div>

                                    <div className="Demo__some-network">
                                        <OKShareButton
                                            url={shareUrl}
                                            image={`${String(window.location)}/${exampleImage}`}
                                            className="Demo__some-network__share-button"
                                        >
                                            <OKIcon size={32} round/>
                                        </OKShareButton>


                                    </div>

                                    <div className="Demo__some-network">
                                        <RedditShareButton
                                            url={shareUrl}
                                            title={title}
                                            windowWidth={660}
                                            windowHeight={460}
                                            className="Demo__some-network__share-button"
                                        >
                                            <RedditIcon size={32} round/>
                                        </RedditShareButton>


                                    </div>

                                    <div className="Demo__some-network">
                                        <TumblrShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <TumblrIcon size={32} round/>
                                        </TumblrShareButton>


                                    </div>

                                    <div className="Demo__some-network">
                                        <LivejournalShareButton
                                            url={shareUrl}
                                            title={title}
                                            description={shareUrl}
                                            className="Demo__some-network__share-button"
                                        >
                                            <LivejournalIcon size={32} round/>
                                        </LivejournalShareButton>
                                    </div>

                                    <div className="Demo__some-network">
                                        <MailruShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <MailruIcon size={32} round/>
                                        </MailruShareButton>
                                    </div>

                                    <div className="Demo__some-network">
                                        <EmailShareButton
                                            url={shareUrl}
                                            subject={title}
                                            body="body"
                                            className="Demo__some-network__share-button"
                                        >
                                            <EmailIcon size={32} round/>
                                        </EmailShareButton>
                                    </div>
                                    <div className="Demo__some-network">
                                        <ViberShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <ViberIcon size={32} round/>
                                        </ViberShareButton>
                                    </div>

                                    <div className="Demo__some-network">
                                        <WorkplaceShareButton
                                            url={shareUrl}
                                            quote={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <WorkplaceIcon size={32} round/>
                                        </WorkplaceShareButton>
                                    </div>

                                    <div className="Demo__some-network">
                                        <LineShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <LineIcon size={32} round/>
                                        </LineShareButton>
                                    </div>

                                    <div className="Demo__some-network">
                                        <WeiboShareButton
                                            url={shareUrl}
                                            title={title}
                                            image={`${String(window.location)}/${exampleImage}`}
                                            className="Demo__some-network__share-button"
                                        >
                                            <WeiboIcon size={32} round/>
                                        </WeiboShareButton>
                                    </div>

                                    <div className="Demo__some-network">
                                        <PocketShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <PocketIcon size={32} round/>
                                        </PocketShareButton>
                                    </div>

                                    <div className="Demo__some-network">
                                        <InstapaperShareButton
                                            url={shareUrl}
                                            title={title}
                                            className="Demo__some-network__share-button"
                                        >
                                            <InstapaperIcon size={32} round/>
                                        </InstapaperShareButton>
                                    </div>

                                    <div className="Demo__some-network">
                                        <HatenaShareButton
                                            url={shareUrl}
                                            title={title}
                                            windowWidth={660}
                                            windowHeight={460}
                                            className="Demo__some-network__share-button"
                                        >
                                            <HatenaIcon size={32} round/>
                                        </HatenaShareButton>


                                    </div>
                                </div>

                                <div className="w-100">
                                    <label style={{width: "100%"}}>Booking URL</label>
                                    <input id="bookingURL" style={{display: "inline-block", width: "80%"}}
                                           className="form-control"
                                           value={this.state.shareUrl}/>

                                    &nbsp; <a className="btn-link" style={{
                                    textDecoration: "none",
                                    display: "inline-block",
                                    fontSize: "30px"
                                }} href={this.state.shareUrl} target="_blank"><i className="icon-external-link"></i>
                                </a>
                                    &nbsp; <a className="btn-link" style={{
                                    textDecoration: "none",
                                    display: "inline-block",
                                    fontSize: "30px"
                                }} href="#" onClick={this.copy}><i className="icon-copy"></i> </a>
                                </div>

                            </form>
                        </div>
                    </BModal.Body>

                </BModal>

                <Notifications options={{zIndex: 9999, width: '100%', top: '50px'}}/>
                <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                       onClose={this.onCloseModal} center>
                    <h2>&nbsp;&nbsp;&nbsp;&nbsp;{msg}...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                    <p style={{textAlign: 'center'}}>
                        <img width={'40'} height={'40'} alt="Loading..." src={'/img/loading1.gif'}/>
                    </p>
                </Modal>
                <Footer/>
                <BModal size="md" show={showReceipt} onHide={this.handleCloseReceipt}>

                    <BModal.Body>
                        <div className="float-right mb-2"><Link style={{"textDecoration": "none"}}
                                                                onClick={this.handleCloseReceipt}><i
                            className="icon-remove-sign red-text icon-2x"></i> </Link><br/></div>
                        {this.state.load ? <div id="receipt"
                                                className="block-section mt-25 position-relative min-height-auto">

                            <div className="event-body confirmed_content_body mb-0">
                                <div className="booked_confirmed_div_main clearfix">

                                    <div className="btn-confirmed-div" id="btnContainer"
                                         style={{textAlign: "center"}}>


                                        {(this.state.data[0].event_sub_type == "P") ?

                                            (this.state.data[0].order_pay_balance_new == "Y") ?

                                                (parseFloat(this.state.data[0].order_remaining_amount + Math.abs(this.state.data[0].remaining_balance)) > 0) ?

                                                    (Math.abs((this.state.data[0].order_remaining_amount + Math.abs(this.state.data[0].remaining_balance)) - this.state.data[0].total).toFixed(2) > 0) ?
                                                        <a className="btn-blue-green"><span
                                                            className="btn-width"><span
                                                            onClick={this.paybalance}>Pay Balance</span> </span>
                                                        </a> : '' : '' : ''
                                            : ''}


                                        {(this.state.data[0].event_sub_type != "P") ?
                                            <a onClick={this.share} className="btn-blue"><span
                                                className="btn-width"> <span
                                            >Share <img
                                                src="/img/ic_share_24px.png"
                                                alt="Share"/></span></span> </a> : ''}
                                        <a href="#" className="btn-blue ml-2"><span
                                            className="btn-width"><span
                                            onClick={this.print}>Print <img
                                            src="/img/ic_print_24px.png"
                                            alt="Print"/></span> </span> </a>
                                        <a href="#" className="btn-blue ml-2"><span
                                            className="btn-width"><span
                                            onClick={this.downloadPDF}>Download <img
                                            src="/img/ic_file_download_24px.png"
                                            alt="Download"/></span></span>
                                        </a>

                                    </div>
                                </div>
                                <hr/>

                                {(this.state.data[0].event_type === "E") ?
                                    <div>
                                        <div className="event-img position-relative">
                                            <figure>
                                                {this.state.data[0].event_image !== "" ?
                                                    <img crossOrigin={true} id="removeImage"
                                                         src={IMAGE_ROOT + "/images/product/" + this.state.data[0].event_image}
                                                         alt={this.state.data[0].event_title}
                                                         className="img-fluid"/> : ''}


                                            </figure>
                                            {/*<div id="removeCount"
                                                                         className="count_people position-absolute">
                                                                        <p>up
                                                                            to <span>{this.state.data[0].event_guest_max}</span>people
                                                                        </p>
                                                                    </div>*/}
                                        </div>

                                        <div className="event-content">
                                            <p className="roboto-bold mt-2 mb-2 font-22">{this.state.data[0].event_title}</p>
                                            <p>{this.state.data[0].event_info}</p>
                                        </div>
                                        <p className="font-weight-medium mb-4 font-14">Up
                                            to {this.state.data[0].event_guest_default}

                                            people</p>
                                        <div className="line"></div>
                                        {(this.state.includes.length > 0) ?
                                            <p className="font-weight-medium mb-3 mt-3 font-14 text-uppercase">What
                                                is
                                                Included ?</p> : ''}
                                        <ul>

                                            {this.state.includes.map((item, index) => {

                                                return (<li>{item}</li>);
                                            })}
                                        </ul>
                                        <div className="event-book-now p-15 mt-3 clearfix">
                                            {(this.state.data[0].event_payment_type === "D") ?
                                                (this.state.data[0].event_deposit_amount_enabled === "Y") ?
                                                    <p className="roboto-bold mb-2 float-left">Deposit
                                                        Amount
                                                        : <span
                                                            className="float-right"> ${this.state.data[0].event_deposit}</span>
                                                    </p> : <p className="roboto-bold mb-2 float-left">Deposit
                                                        Amount
                                                        : <span
                                                            className="float-right"> ${((parseFloat(this.state.data[0].event_deposit) * parseFloat(this.state.data[0].event_base_price)) / 100).toFixed(2)}</span>
                                                    </p> : ''}
                                            {//(this.state.data[0].order_payment_type !== "D") ?
                                                <p>Base Price : <span
                                                    className="float-right">${this.state.data[0].event_base_price}</span>
                                                </p> /*: ''*/}
                                        </div>
                                    </div> :

                                    <div>
                                        <div className="events-block"
                                             style={{minHeight: "0px"}}>

                                            <div className="event-img">
                                                <figure>

                                                    {this.state.data[0].event_image !== "" ?
                                                        <img crossOrigin id="removeImage"
                                                             src={IMAGE_ROOT + "/images/product/" + this.state.data[0].event_image}
                                                             alt={this.state.data[0].event_title}
                                                             className="img-fluid"/> : ''}

                                                </figure>


                                            </div>
                                            <div className="event-body">

                                                <div className="event-content">

                                                    <div className="time_info clearfix mb-3">
                                                        <p className="roboto-bold mt-2 mb-2 font-22">{this.state.data[0].event_title}</p>
                                                        <p className="roboto-bold">{moment(this.state.data[0].order_booking_at, "MM/DD/YYYY").format("dddd, MMMM, DD,YYYY")}</p>
                                                        <br/>
                                                        <p className="roboto-bold">{this.state.paymentTime}</p>

                                                    </div>
                                                    <p className="mb-4"> {this.state.data[0].event_info}</p>

                                                </div>
                                            </div>
                                            <div className="event-book-now" style={{
                                                display: "flex",
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                                fontWeight: "bold"
                                            }}>
                                                <p className="roboto-regular"><i
                                                    className="icon-ticket"></i> {this.state.data[0].order_adults} Tickets
                                                    :</p>
                                                <p className="roboto-regular"
                                                   style={{textAlign: "right"}}>${(this.state.data[0].payment_amount - this.state.addonAmount).toFixed(2)}</p>

                                            </div>

                                        </div>
                                    </div>
                                }
                            </div>


                            <div className="PartyQuestionaire">
                                <div className="title_div mb-2">
                                    <h1 className="font_26">Your {(this.state.data[0].event_type === "E") ? "Party" : "Admission"} Details</h1>
                                </div>
                                <div className="addons_block_main party-info-height">
                                    <div className="guest_block addons_block clearfix w-100">
                                        <form method="post" action="#"
                                              encType="multipart/form-data">
                                            {(this.state.data[0].event_type === "E") ?
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <input type="text"
                                                                   className="form-control"
                                                                   name="" placeholder=""
                                                                   value={moment(this.state.data[0].order_booking_at, "MM/DD/YYYY").format("dddd, MMMM, DD,YYYY")}
                                                                   disabled/>
                                                        </div>
                                                    </div>
                                                </div> : ''}

                                            {(this.state.data[0].event_type === "E") ?
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <input type="text"
                                                                   className="form-control"
                                                                   name="" placeholder=""
                                                                   value={moment(this.state.data[0].order_booking_at + " " + this.state.data[0].order_duration_from, "MM/DD/YYYY HH:mm:ss").format("h:mm A") + " - " + moment(this.state.data[0].order_booking_at + " " + this.state.data[0].order_duration_to, "MM/DD/YYYY HH:mm:ss").format("h:mm A")}
                                                                   disabled/>
                                                        </div>
                                                    </div>
                                                </div> : ''}
                                            {(this.state.data[0].event_type === "E") ?
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <input type="text"
                                                                   className="form-control"
                                                                   name="" placeholder=""
                                                                   value={this.state.data[0].order_adults + " Guests"}
                                                                   disabled/>
                                                        </div>
                                                    </div>
                                                </div> : ''}


                                            {this.state.data[0].answers.map((item, index) => {


                                                return (<div className="form-group">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <label
                                                                className="font-16">{item.question_name}</label>
                                                            <input type="text"
                                                                   className="form-control"
                                                                   name="" placeholder=""
                                                                   value={item.order_answer}
                                                                   disabled/>
                                                        </div>
                                                    </div>
                                                </div>)

                                            })}

                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="confirmed_booked_detail mb-2">

                                <div className="addons_block_main party-info-height">
                                    <div className="guest_block addons_block m-10 clearfix w-100">
                                        <div className="confirmed_detail_block clearfix mb-15">
                                            <div className="confirmed_title">
                                                <div className="d_left">
                                                    <h3>Items</h3>
                                                </div>
                                                <div className="d_right">
                                                    <h3>Price</h3>
                                                </div>
                                            </div>
                                            <div className="confirmed_content_main">
                                                <div
                                                    className="confirmed_content mb-3 clearfix position-relative">
                                                    {(this.state.data[0].event_type === "E") ?
                                                        <div className="d_left">
                                                            <p className="font-weight-medium"> {this.state.data[0].event_title}</p>
                                                        </div>
                                                        : <div className="d_left">
                                                            <p>
                                                                <span>{this.state.data[0].event_title}</span>

                                                                {(parseInt(this.state.data[0].order_adults) > 1) ?
                                                                    <span
                                                                        className="roboto-bold ml-50">({this.state.data[0].order_adults}x)</span> : ''}
                                                            </p>
                                                        </div>}
                                                    {(this.state.data[0].event_type === "E") ?
                                                        <div className="d_right">
                                                            <p className="font-16 roboto-bold text-right">${(parseFloat(this.state.data[0].order_event_amount) > 0) ? parseFloat(this.state.data[0].order_event_amount).toFixed(2) : parseFloat(this.state.data[0].event_base_price).toFixed(2)}</p>
                                                        </div> : <div className="d_right">
                                                            <p className="font-16 roboto-bold text-right">${(this.state.data[0].payment_amount - this.state.addonAmount).toFixed(2)}</p>
                                                        </div>}
                                                </div>

                                                {this.state.includes.map((item, index) => {
                                                    return (
                                                        <div
                                                            className="confirmed_content clearfix">
                                                            <div className="d_left">
                                                                <p><span className="img"><img
                                                                    src="/img/check-icon.png"
                                                                    className="mr-1" width="16"
                                                                    height="14"
                                                                    alt="Confirmed"/></span>
                                                                    <span>{item}</span>
                                                                </p>
                                                            </div>
                                                            <div className="d_right">
                                                                <p className="font-14 text-right">Included</p>
                                                            </div>
                                                        </div>)

                                                })}


                                                <div
                                                    className="confirmed_content mb-3 clearfix position-relative">
                                                    <div className="d_left">
                                                        <p>
                                                            <span>{moment(this.state.data[0].order_booking_at, "MM/DD/YYYY").format("MMMM, DD,YYYY")}</span>

                                                        </p>
                                                    </div>
                                                    <div className="d_right">
                                                        <p className="font-16 roboto-bold text-right"></p>
                                                    </div>
                                                </div>

                                                <div
                                                    className="confirmed_content mb-3 clearfix position-relative">
                                                    <div className="d_left">
                                                        <p>
                                                            <span>{moment(this.state.data[0].order_booking_at + ' ' + this.state.data[0].order_duration_from, 'MM/DD/YYYY HH:mm:ss').format("h:mm A") + " - " + moment(this.state.data[0].order_booking_at + ' ' + this.state.data[0].order_duration_to, 'MM/DD/YYYY HH:mm:ss').format("h:mm A")}</span>

                                                        </p>
                                                    </div>
                                                    <div className="d_right">
                                                        <p className="font-16 roboto-bold text-right"></p>
                                                    </div>
                                                </div>
                                                {(this.state.data[0].event_type === "E") ?
                                                    <div
                                                        className="confirmed_content mb-3 clearfix position-relative">
                                                        <div className="d_left">
                                                            <p>
                                                                <span>                           {parseInt(this.state.data[0].order_adults) + parseInt(this.state.data[0].order_children)} Guests</span>

                                                            </p>
                                                        </div>
                                                        <div className="d_right">
                                                            <p className="font-16 roboto-bold text-right"></p>
                                                        </div>
                                                    </div> : ''}

                                                {(parseInt(this.state.data[0].order_aditional_guest)) > 0 ?

                                                    <div
                                                        className="confirmed_content mb-3 clearfix position-relative">
                                                        <div className="d_left">
                                                            <p>
                                                                <span>Additional Guests</span>

                                                            </p>
                                                        </div>
                                                        <div className="d_right">
                                                            <p className="font-16 roboto-bold text-right">${parseInt(this.state.data[0].order_aditional_guest) * parseFloat(this.state.data[0].order_aditional_amount).toFixed(2)}</p>
                                                        </div>
                                                    </div>
                                                    : ''}


                                                {this.state.data[0].order_details.map((item, index) => {

                                                    if (parseInt(item.product_quantity) > 0) {
                                                        return (
                                                            <div
                                                                className="confirmed_content mb-3 clearfix position-relative">
                                                                <div className="d_left">
                                                                    <p>
                                                                        <span>{item.product_name}</span>

                                                                        {(parseInt(item.product_quantity) > 1) ?
                                                                            <span
                                                                                className="roboto-bold ml-50">({item.product_quantity}x)</span> : ''}
                                                                    </p>
                                                                </div>
                                                                <div className="d_right">
                                                                    <p className="font-16 roboto-bold text-right">${(parseInt(item.product_quantity) * parseFloat(item.product_price)).toFixed(2)}</p>
                                                                </div>
                                                            </div>)
                                                    }
                                                })}


                                            </div>
                                            {this.state.data.map((item, index) => {

                                                if (index == 0) {
                                                    if (parseFloat(item.payment_total) > 0) {
                                                        return (<div>
                                                            {/*<div
                                                                className="confirmed_title subtotal_title mb-2">
                                                                <div className="d_left">
                                                                    <h3>Transaction
                                                                        #{this.state.data[index].payment_reference_number}</h3>
                                                                </div>

                                                            </div>*/}
                                                            <div
                                                                className="confirmed_title subtotal_title mb-2">
                                                                <div className="d_left">
                                                                    <h3>Subtotal</h3>
                                                                </div>
                                                                <div className="d_right text-right">
                                                                    <h3>${this.state.data[index].payment_amount.toFixed(2)}</h3>
                                                                </div>
                                                            </div>

                                                            {this.state.data[index].taxes.map((item1, index1) => {
                                                                return (<div
                                                                    className="confirmed_content mb-0 clearfix p-2">
                                                                    <div className="d_left">
                                                                        <p className="font-14">{item1.tax_name} ({item1.tax_percentage}%)</p>
                                                                    </div>
                                                                    <div className="d_right">
                                                                        <p className="font-16 text-right">${parseFloat(item1.tax_value).toFixed(2)}</p>
                                                                    </div>
                                                                </div>)
                                                            })}
                                                            {(parseFloat(this.state.data[index].payment_nca) > 0) ?
                                                                <div
                                                                    className="confirmed_content mb-0 clearfix p-2">
                                                                    <div className="d_left">
                                                                        <p className="font-14">{this.state.data[index].merchant_nca_name}</p>
                                                                    </div>
                                                                    <div className="d_right">
                                                                        <p className="font-16 text-right">${this.state.data[index].payment_nca.toFixed(2)}</p>
                                                                    </div>
                                                                </div> : ''}

                                                            {(this.state.data[index].payment_type != "C") ?


                                                                <div
                                                                    className="confirmed_content mb-0 clearfix p-2">
                                                                    <div className="d_left">
                                                                        <p className="font-14">{(this.state.data[index].payment_type == "K") ?
                                                                            <span>Cash Payment</span> :
                                                                            (this.state.data[index].payment_type == "D") ?
                                                                                <span>Card Payment</span> :
                                                                                (this.state.data[index].payment_type == "Q") ?
                                                                                    <span>Check Payment</span> : ''}</p>
                                                                    </div>
                                                                    <div className="d_right">
                                                                        <p className="font-16 text-right">${this.state.data[index].payment_total.toFixed(2)}</p>
                                                                    </div>
                                                                </div> : ''}
                                                            <div
                                                                className="confirmed_title subtotal_title bg-yellow">
                                                                <div className="d_left">
                                                                    <h3>Total</h3>
                                                                </div>
                                                                <div className="d_right text-right">
                                                                    <h3>${this.state.data[index].payment_total.toFixed(2)}</h3>
                                                                </div>
                                                            </div>
                                                        </div>);
                                                    }
                                                }
                                            })}
                                        </div>
                                        {/*<div className="btn-blue roboto-light cursor-default font-22">Paid
                                            ${this.state.data[0].total.toFixed(2)} </div>*/}
                                    </div>
                                </div>
                            </div>

                            <div className="transaction-block d-inline-block mb-3 w-94">

                                <div className="title_div mb-2">
                                    <h1 className="font_26">Transactions</h1>
                                </div>

                                {this.state.data.map((item, index) => {
                                    var type = this.state.data[index].payment_type;
                                    var notes = "";
                                    if (type == "C") {
                                        type = "Card";


                                    } else if (type == "K") {
                                        type = "Cash";

                                    } else if (type == "Q") {
                                        type = "Check";

                                    } else {
                                        type = "----";

                                    }


                                    if (parseFloat(this.state.data[index].payment_amount) > 0) {
                                        if (this.state.data[index].payment_remaining == "Y") {

                                            if (this.state.data[index].payment_note != "") {
                                                notes = this.state.data[index].payment_note;
                                            } else {
                                                notes = "Paid Remaining Balance";
                                            }


                                        } else {

                                            if (this.state.data[index].payment_amount == this.state.data[index].order_event_amount) {
                                                notes = "Paid Full Amount";
                                            } else {


                                                notes = "Paid Deposit"

                                            }
                                        }
                                    } else {

                                        if (this.state.data[index].payment_note != "") {
                                            notes = this.state.data[index].payment_note;
                                        } else {
                                            notes = "Requesting Payment";
                                        }
                                    }


                                    return (<div>
                                        <div
                                            className="blue-title bg-blue text-uppercase mt-2 float-left w-100 mb-0 rounded-0 text-left">
                                            Transaction
                                            #{this.state.data[index].payment_reference_number}
                                        </div>
                                        <div className="transaction-block-inner p-0">

                                            <div id="table_wrapper"
                                                 className="dataTables_wrapper form-inline dt-bootstrap">


                                                <table id="table" className="table-info dataTable" role="grid">
                                                    <thead>
                                                    <tr role="row">
                                                        <th scope="col" className="eview_12 sorting_disabled"
                                                            rowSpan="1"
                                                            colSpan="1" aria-label=" Date"> Date
                                                        </th>
                                                        <th scope="col" className="eview_6 sorting_disabled" rowSpan="1"
                                                            colSpan="1" aria-label="Type">Type
                                                        </th>
                                                        <th scope="col" className="event_time sorting_disabled"
                                                            rowSpan="1"
                                                            colSpan="1" aria-label="Notes">Notes
                                                        </th>
                                                        <th scope="col" className="date sorting_disabled" rowSpan="1"
                                                            colSpan="1" aria-label="Amount">Amount
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    <tr role="row" className="odd">
                                                        <td>{moment(this.state.data[index].payment_datetime).format("MM/DD/YYYY")}</td>
                                                        <td>{type}</td>
                                                        <td>{notes}</td>

                                                        <td>{(this.state.data[index].payment_total > 0) ? "$" + this.state.data[index].payment_total.toFixed(2) :
                                                            "-$" + Math.abs(this.state.data[index].payment_total).toFixed(2)}</td>
                                                    </tr>


                                                    </tbody>
                                                    <tfoot>
                                                    <tr>
                                                        <th className="text-right" scope="col" colSpan="3"
                                                            rowSpan="1"> Remaining Balance
                                                        </th>
                                                        <th scope="col" className="date text-right" rowSpan="1"
                                                            colSpan="1">
                                                            {(this.state.data[index].payment_total > 0) ? "$" + this.state.data[index].payment_total.toFixed(2) :
                                                                "-$" + Math.abs(this.state.data[index].payment_total).toFixed(2)}
                                                        </th>
                                                    </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>)
                                })}

                            </div>
                        </div> : ''}
                    </BModal.Body>

                </BModal>

                <BModal size="md" show={showPayment}>

                    <BModal.Body>
                        <div className="float-right mb-2"><Link style={{"textDecoration": "none"}}
                                                                onClick={() => {
                                                                    this.handleClosePayment("C")
                                                                }}><i
                            className="icon-remove-sign red-text icon-2x"></i> </Link><br/></div>

                        <RemainingPayment orderId={this.state.orderId}
                                          handleClosePayment={() => {
                                              this.handleClosePayment("R")
                                          }}/>
                    </BModal.Body>

                </BModal>
            </div>
        );


    }
}

export default withRouter(myaccount);
